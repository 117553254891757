import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryTooltip,
	VictoryTheme,
} from "victory";
import Modal from "../../../shared/Modal";

// GraphQL Query
const GET_THREAT_COUNT = gql`
  {
    control_domain_threat_count {
      control_domain
      threat_count
      threat_list
    }
  }
`;

const DomainThreatChart = () => {
	const { loading, error, data } = useQuery(GET_THREAT_COUNT);
	const [selectedDomain, setSelectedDomain] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;

	const chartData = data?.control_domain_threat_count.map((item) => ({
		domain: item.control_domain,
		threatsCount: item.threat_count,
		threatList: item.threat_list.join(", "), // Convert array to string
	}));

	const handleDomainClick = (domainData) => {
		setSelectedDomain(domainData);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		setSelectedDomain(null);
	};

	return (
		<>
			<VictoryChart theme={VictoryTheme.material} domainPadding={30} height={375} width={700}>
				<VictoryAxis
					tickValues={chartData?.map((_, index) => index + 1)}
					tickFormat={chartData?.map((item) => item.domain)}
					style={{
						axis: { stroke: "#756f6a" },
						ticks: { stroke: "#756f6a" },
						tickLabels: { fontSize: 10, padding: 5, angle: 15, textAnchor: "start", fill: "#4a4a4a" },
					}}
				/>
				<VictoryAxis
					dependentAxis
					style={{
						axis: { stroke: "#756f6a" },
						ticks: { stroke: "#756f6a" },
						tickLabels: { fontSize: 12, padding: 5, fill: "#4a4a4a" },
					}}
				/>
				<VictoryBar
					data={chartData}
					x="domain"
					y="threatsCount"
					barWidth={50}
					labels={({ datum }) => `${datum.threatsCount} threats\n${datum.threatList}`}
					labelComponent={<VictoryTooltip flyoutStyle={{ stroke: "#375e87", strokeWidth: 2 }} />}
					style={{
						data: { fill: "var(--color-primary)" },
						labels: { fontSize: 12, fill: "#000" },
					}}
					events={[
						{
							target: "data",
							eventHandlers: {
								onClick: (event, props) => {
									handleDomainClick(chartData[props.index]);
								},
							},
						},
					]}
				/>
			</VictoryChart>

			{modalIsOpen && (
				<Modal isOpen={modalIsOpen} onClose={closeModal} size="sm">
					<Modal.Header>
						<Modal.Title>{`Possible Threats for ${selectedDomain?.domain}`}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ul className="list-disc list-inside">
							{selectedDomain?.threatList ? (
								selectedDomain.threatList.split(", ").map((threat, index) => <li key={index}>{threat}</li>)
							) : (
								<li>No threats</li>
							)}
						</ul>
					</Modal.Body>
					<div className="flex p-4 justify-end">
						<button className="btn-cancel p-2" onClick={closeModal}>X</button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default DomainThreatChart;
