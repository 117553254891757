import React, { useEffect, useState } from "react";
import http from "../../../resources/http";
import { Button, Form } from "react-bootstrap";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import UploadATFile from "./UploadATFile";
import { ASSET_LIST, RISK_AT_MAPPING_LIST } from "../../../api/excelUpload";
import { THREAT_VECTORS_LIST } from "../../../api/threatCatalog";
import MultipleSelectCheckmarks from "../../../shared/MultiSelect/MultiSelect";
import Modal from "../../../shared/Modal";
import { GET_AT_MAPPING, GET_THREATS } from "../../../graphql/superAdmin/query/query";
import { useQuery } from "@apollo/client";

const ATmapping = () => {
  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [error, setError] = useState(null);

  // search
  const [searchValue, setSearchValue] = useState("");
  //adding modal handler
  const [showAdd, setShowAdd] = useState(false);
  const handleShowAdd = () => setShowAdd(!showAdd);

  //editing modal handler
  const [showHideEdit, setShowHideEdit] = useState(false);
  const handleModalShowHideEdit = () => setShowHideEdit(!showHideEdit);

  const [selectedItems, setSelectedItems] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  //multiple filed data
  const [selected, setSelected] = useState([]);
  const assetList = selected?.map((item) => item.value);

  const assetOptions = assetData?.map(({ name }) => name);
  const [assetsList, setAssetsList] = useState([]);
  const [preSelectedAssets, setPreSelectedAssets] = useState([]);
  const handleAssetOptionsChange = (selected) => {
    setAssetsList(selected);
  };
  useEffect(() => {
    const selectedOptions =
      assetsList && Array.isArray(assetsList)
        ? assetOptions.filter((option) => assetsList.includes(option.value))
        : [];
    setSelected(selectedOptions);
  }, [assetsList]);

  useEffect(() => {
    const initialSelected = assetOptions.filter((option) =>
      selectedItems.some((selectedItem) => selectedItem.value === option.value)
    );
    setSelected(initialSelected);
  }, [selectedItems]);

  const [threatVectors, setThreatVectors] = useState([]);
  // Update the filtering and display logic

  // page pagination
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 6;
  const pagesVisited = pageNumber * usersPerPage;
  // Use filteredData instead of data for page count
  const pageCount = Math.ceil(filteredData.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const { data: threat } = useQuery(GET_THREATS, {
    variables: { limit: 10 },
  });
  // for file upload
  const [showFileUploader, setShowFileUploader] = useState(false);
  const toggleFileUploader = () => setShowFileUploader(!showFileUploader);

  //db data
  async function fetchData() {
    setFetchLoading(true);
    try {
      const listOfATData = await http.get(RISK_AT_MAPPING_LIST);
      setData(listOfATData.data);
      setFetchLoading(false);
    } catch (error) {
      setError("error occurred while fetching at mapping data ");
      setFetchLoading(false);
    }
  }

  async function fetchFormOptionData() {
    try {
      const listOfAssets = await http.get(ASSET_LIST);
      const listOfThreatVectors = await http.get(THREAT_VECTORS_LIST);
      setAssetData(listOfAssets.data);
      setThreatVectors(listOfThreatVectors.data);
      setFetchLoading(false);
    } catch (error) {
      setError("error occurred while fetching form dropdown options.");
      setFetchLoading(false);
    }
  }
  // useEffect(() => {
  //             // fetchData();
  //   fetchFormOptionData();
  // }, []);

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
    setPageNumber(0); // Reset to the first page when search value changes
  };
  // delete single filed
  const deleteFiled = (e, asset_id) => {
    e.preventDefault();
    http
      .delete(`${RISK_AT_MAPPING_LIST}/${asset_id}`)

      .then((res) => {
        // fetchData();
        swal("Success", "It has been deleted successfully", "success");
      })
      .catch(() => {
        swal("Error", "Something went wrong, please try again.", "error");
      });
  };
  //new post
  const submit = (e) => {
    e.preventDefault();
    const riskATErrors = validateAsset();

    http

      .post(`${RISK_AT_MAPPING_LIST}`, {
        asset: assetList,
        threat: form.threat,
      })
      .then((response) => {
        handleShowAdd();
        // fetchData();
        swal("Success", "It has been add successfully.", "success");
      })
      .catch((err) => {
        swal("Error", "Something went wrong, please try again.", "error");
      });
  };

  //edit data
  const update = (e) => {
    e.preventDefault();
    const riskATErrors = validateAsset();
    if (Object.keys(riskATErrors).length > 0) {
      setErrors(riskATErrors);
    } else {
      http
        .patch(`${RISK_AT_MAPPING_LIST}/${id}`, {
          asset: assetList,
          threat: form.threat,
        })
        .then((res) => {
          handleModalShowHideEdit();
          // fetchData();
          swal("Success!", "It has been updated successfully", "success");
        })
        .catch((err) => {
          swal("Error", "Something went wrong, please try again", "error");
        });
    }
  };

  //form data and error handler
  const [form, setForm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateAsset = () => {
    //declare the variables and assign the values from the asset object
    const { asset = "", threat = "" } = form;
    const newErrors = {};

    if (selected == "") newErrors.asset = "Asset is require.";

    if (!threat || threat === "" || threat === "Select...")
      newErrors.threat = "Threat is require.";
    return newErrors;
  };
  const clearAll = () => {
    form.threat = "";
  };
  const [page, setPage] = useState(0);
  const pageSize = 50; // Number of items per page

  const { data, loading, refetch } = useQuery(GET_AT_MAPPING, {
    variables: { limit: pageSize, offset: page * pageSize },
  });
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.asset_threat_mapping?.count || 0;

  const filteredData = data?.filter(
    (row) =>
      row?.asset?.name?.match(new RegExp(searchValue, "i")) ||
      row?.threat?.name?.match(new RegExp(searchValue, "i"))
  );
  // const [update] = useMutation(UPDATE_CONTROL_TYPE);
  // const [create] = useMutation(ADD_CONTROLTYPE);
  // const [del] = useMutation(DELETE_CONTROL_TYPE);

  const DisplayData = filteredData
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((asset) => {
      return (
        <tr key={asset.id}>
          <td className="">
            {asset?.asset?.name}
          </td>
          <td className="">{asset?.threat?.name}</td>
          <td className="flex justify-center items-center">
            <IconButton
              onClick={() => {
                setId(asset.id);
                setPreSelectedAssets(asset.asset?.name);
                form.threat = asset?.threat?.name;
                handleModalShowHideEdit();
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                deleteFiled(e, asset.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </td>
        </tr>
      );
    });

  return (
    <div className="p-10 z-100">
      <div className="flex flex-col text-black pb-10 pt-3">
        <div className="w-full table-title text-white p-3 flex justify-between items-center">
          <div>
            <span className="font-bold ">Assets Threat Mapping</span>
          </div>
          <div className="flex   justify-center items-center border border-white">
            <img
              alt="icon"
              className="w-10 h-10"
              src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
            />
            <div class="flex space-x-2 justify-center">
              <button
                onClick={() => toggleFileUploader()}
                type="button"
                class="inline-block cmbutton font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Upload File
              </button>
            </div>
          </div>

          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            <div className="flex justify-between items-center">
              <div className="flex justify-center space-x-4 items-center">
                <div className="flex justify-end items-end ">
                  <Button
                    onClick={() => {
                      fetchFormOptionData();
                      clearAll();
                      setSelected([]);
                      handleShowAdd();
                    }}
                    variant="outline-warning"
                    className="flex justify-end items-end cmbutton"
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {fetchLoading ? (
          <LoadingSpinner />
        ) : Array.isArray(data) && data.length === 0 ? (
          <div className="alert alert-warning" role="alert">
            Assets Threat Mapping doesn't have data at the moment, consider
            uploading new excel file or add single data.
          </div>
        ) : (
          <div>
            <div className="rounded-md shadow overflow-auto ">
              <table class="tables rounded-md overflow-hidden">
                <thead className="sticky-header">
                  <tr>
                    <th className="">Assets</th>
                    <th className="">Threats</th>
                    <th className="">Manage</th>
                  </tr>
                </thead>
                <tbody>{DisplayData}</tbody>
              </table>
            </div>
            <br />
            <div className="flex flex-col justify-center items-center">
              {filteredData.length > 7 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  activeClassName={"paginationActive"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
      {/* for adding new riskATC */}
      <Modal onClose={() => handleShowAdd()} isOpen={showAdd} size="md">
        <Modal.Header>
          <Modal.Title>Add Assets Threat Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body className="container-modal flex  space-y-2 ">
          <Form.Group class="mb-6">
            <Form.Label
              for="asset"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Assets
            </Form.Label>
            <MultipleSelectCheckmarks
              data={assetOptions}
              onChange={handleAssetOptionsChange}
              tag="Select Assets"
            />
            {/* <MultiSelect
              name="asset"
              id="asset"
              options={assetOptions}
              value={selected}
              onChange={setSelected}
            /> */}
          </Form.Group>
          <Form.Group class="mb-6">
            <Form.Label
              for="threat"
              class=" mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Threat
            </Form.Label>
            <Form.Control
              name="threat"
              id="threat"
              as="select"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={form.threat}
              isInvalid={!!errors.threat}
              required
              onChange={(e) => setField("threat", e.target.value)}
            >
              <option>Select...</option>
              {threatVectors?.map((x, y) => (
                <option value={x.name}>{x.name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.threat}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => {
              setErrors("");
              handleShowAdd();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-add-new"
            onClick={(e) => {
              submit(e);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/*editing model */}
      <Modal
        onClose={() => handleModalShowHideEdit()}
        isOpen={showHideEdit}
        size="sm"
      >
        <Modal.Header>
          <Modal.Title>Update Assets Threat Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" flex flex-col space-y-2 ">
            <Form.Group class="mb-6">
              <Form.Label
                for="assetLabel"
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                Assets
              </Form.Label>

              <MultipleSelectCheckmarks
                data={assetOptions}
                onChange={handleAssetOptionsChange}
                initialSelectedValues={preSelectedAssets}
                tag="Select Assets"
              />
            </Form.Group>
            <Form.Group class="mb-6">
              <Form.Label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threat
              </Form.Label>
              <Form.Control
                as="select"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={form.threat}
                onChange={(e) => setField("threat", e.target.value)}
                isInvalid={!!errors.threat}
                required
              >
                <option>Select...</option>
                {threatVectors?.map((x, y) => (
                  <option value={x.name}>{x.name}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.threat}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            onClick={() => {
              setErrors("");
              handleModalShowHideEdit();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-add-new"
            onClick={(e) => {
              update(e);
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for uploading new file */}
      <Modal show={showFileUploader} className="p-14 mt-24" size="md">
        <Modal.Body>
          <Form>
            <Form.Label
              for="text"
              class="block mb-4 text-lg font-small text-gray-900 dark:text-gray-300"
            >
              Choose a file to replace or append to existing file.
            </Form.Label>
          </Form>

          <UploadATFile
            toggleFileUploader={toggleFileUploader}
            fetchData={fetchData}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ATmapping;
