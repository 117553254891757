import React, { useState, useEffect, useRef } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";

const SearchInput = ({
	searchValue,
	setSearchValue,
	placeholder = "Search...",
	className = "",
	autoFocus = false,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [progress, setProgress] = useState(0);
	const searchRef = useRef(null); // Reference to the search container

	// Handle progress bar animation
	useEffect(() => {
		if (searchValue.length > 0) {
			let progressInterval = setInterval(() => {
				setProgress((prev) => (prev < 100 ? prev + 10 : 100));
			}, 30);
			return () => clearInterval(progressInterval);
		} else {
			setProgress(0);
		}
	}, [searchValue]);

	// Collapse search when clicking outside
	useEffect(() => {
		function handleClickOutside(event) {
			if (searchRef.current && !searchRef.current.contains(event.target)) {
				setIsExpanded(false);
				setSearchValue(""); // Reset search field when collapsed
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setSearchValue]);

	// Handle Enter key press
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			setIsExpanded(false);
		}
	};

	return (
		<div
			ref={searchRef}
			className={`search-container ${
				isExpanded ? "expanded" : ""
			} ${className}`}
		>
			{/* Search Icon - Click to Expand */}
			{!isExpanded && (
				<button
					onClick={() => setIsExpanded(true)}
					className="tooltip search-icon "
					aria-label="Open search"
				>
					<FaSearch className="icon" />
					<span className="tooltip-text">Search by key words</span>
				</button>
			)}

			{/* Search Input - Expands When Clicked */}
			{isExpanded && (
				<div className="relative flex items-center w-full">
					<FaSearch className="search-icon absolute left-3" />
					<input
						type="text"
						id="searchBox"
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						autoFocus
						onKeyDown={handleKeyDown} // Detect Enter key press
						className="search-input"
						placeholder={placeholder}
					/>

					{/* Progress Bar Animation */}
					<div className="progress-bar" style={{ width: `${progress}%` }}></div>

					{/* Clear Button */}
					{searchValue.length > 0 && (
						<button
							className="clear-icon"
							onClick={() => setSearchValue("")}
							aria-label="Clear search"
						>
							<FaTimes />
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default SearchInput;
