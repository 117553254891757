import { gql } from "@apollo/client";
export const GET_THREATS = gql`
	query GetThreat {
		threat(limit: 40) {
			id
			threat_severity {
				low
				medium
				high
			}
			name
			description
			event_frequency
			cost_of_incident
			percent_of_breach
			lost_business_cost

			discovery_level {
				id
				value
				difficulty_level
			}
			exploitable_level {
				id
				value
				difficulty_level
			}
			reproducibility_level {
				id
				value
				difficulty_level
			}
			confidentiality_level {
				id
				value
				difficulty_level
			}
			integrity_level {
				id
				value
				difficulty_level
			}
			availability_level {
				id
				value
				difficulty_level
			}
			reproducibility_level {
				id
				value
				difficulty_level
			}
		}
	}
`;

export const GET_CLIENT_THREATS = gql`
	query GET_CLIENT_THREATS($clientId: ID) {
		client_threats( client_id: $clientId) {
			id
			threat_severity {
				low
				medium
				high
			}
			name
			description
			event_frequency
			cost_of_incident
			percent_of_breach
			lost_business_cost

			discovery_level {
				id
				value
				difficulty_level
			}
			exploitable_level {
				id
				value
				difficulty_level
			}
			reproducibility_level {
				id
				value
				difficulty_level
			}
			confidentiality_level {
				id
				value
				difficulty_level
			}
			integrity_level {
				id
				value
				difficulty_level
			}
			availability_level {
				id
				value
				difficulty_level
			}
		}
	}
`;
export const GET_COSTS = gql`
	query GET_COSTS($limit: Int!) {
		cost(limit: $limit) {
			id
			cost_category
			threat{
				name
			}
			cost_of_breach
			cost_per_incident
			percent_of_total_breach
			threat_event_frequency
			lost_business_cost
	}
}
`;

export const EXPORT_COSTS = gql`
  mutation EXPORT_COSTS(
    $inputs: ExportInputType!
  ) {
    export_cost(inputs: $inputs) {
      data{
       file_content
      }
    }
  }
`;

export const IMPORT_COSTS = gql`
  mutation IMPORT_COSTS(
    $inputs: ImportInputType!
  ) {
    import_cost(inputs: $inputs) {
      data{
       inserted
       updated
       deleted
       total
       skip
       messages
      }
    }
  }
`;


export const GET_CONSENSUS_ASSESSMENT_QUESTIONNAIRE = gql`
	query GET_CONSENSUS_ASSESSMENT_QUESTIONNAIRE($limit: Int!) {
		consensus_assessment(limit: $limit) {
			id
			question_id
			consensus_assessment_question
			control_id
			control_title
			control_specification
	}
}
`;
export const GET_INDUSTRY_DATA = gql`
	query GET_INDUSTRY_SECTOR($limit: Int!) {
		industry_data(limit: $limit) {
			id
			industry_sector{
				name
			}
			total_breaches
			total_incidents
			total_cost_of_data_breach
			year
	}
}
`;
export const GET_USERS = gql`
	query GETUSERS($limit: Int!) {
		user(limit: $limit) {
			id
			email
			username
			is_superuser
			is_active
			roles {
				id
				role_name
			}
			client {
				name
			}
		}
		client(limit: $limit) {
			id
			name
			currency {
				name
				symbol
				code
			}
			sensitivity_level
		}
		user_role {
			id
			role_name
		}
	}
`;
export const GET_EXPLOITABILITY_LEVELS = gql`
	query GetExploitableLevels($limit: Int!, $offset: Int) {
		exploitable_level(limit: $limit, offset: $offset) {
			id
			value
			difficulty_level
			description
		}
		exploitable_level_aggregate {
			count
		}
	}
`;
export const GET_AVAILABILITY_LEVELS = gql`
	query GetAvailabilityLevels($limit: Int!, $offset: Int) {
		availability_level(limit: $limit, offset: $offset) {
			id
			value
			difficulty_level
			description
		}
		availability_level_aggregate {
			count
		}
	}
`;
export const GET_CONFIDENTIALITY_LEVELS = gql`
	query GetConfidentialityLevels($limit: Int!, $offset: Int) {
		confidentiality_level(limit: $limit, offset: $offset) {
			id
			value
			difficulty_level
			description
		}
		confidentiality_level_aggregate {
			count
		}
	}
`;
export const GET_DISCOVERABILITY_LEVELS = gql`
	query GetDiscoveryLevels($limit: Int!, $offset: Int) {
		discovery_level(limit: $limit, offset: $offset) {
			id
			value
			difficulty_level
			description
		}
		discovery_level_aggregate {
			count
		}
	}
`;

export const GET_INTEGRITY_LEVELS = gql`
	query GetIntegrityLevels($limit: Int!) {
		integrity_level(limit: $limit) {
			id
			value
			difficulty_level
			description
		}
		integrity_level_aggregate {
			count
		}
	}
`;
export const GET_REPRODUCIBILITIES = gql`
	query GetReproducibilityLevels($limit: Int!) {
		reproducibility_level(limit: $limit) {
			id
			value
			difficulty_level
			description
		}
		reproducibility_level_aggregate {
			count
		}
	}
`;
export const GET_THREAT_CATEGORIES = gql`
	query GET_THREAT_CATEGORIES($limit: Int!) {
		threat_category(limit: $limit) {
			id
			category
		}
	}
`;

export const ADD_THREAT_VECTOR = gql`
	mutation ADD_THREAT_VECTOR($inputs: [threatInputType]!) {
		create_threat(inputs: $inputs) {
			affected_rows
			data {
				name
			}
		}
	}
`;
export const ADD_THREAT_VECTOR_CLIENT = gql`
	mutation ADD_THREAT_VECTOR_CLIENT($inputs: [client_threatInputType]!) {
		create_client_threat(inputs: $inputs) {
			affected_rows
			data {
				name
			}
		}
	}
`;
export const UPDATE_THREAT_VECTOR_CLIENT = gql`
	mutation UPDATE_THREAT_VECTOR_CLIENT($id: Int!, $input: client_threatInputType!) {
		update_client_threat(where: { id: { exact: $id } }, input: $input) {
			affected_rows
		}
	}
`;
export const UPDATE_THREAT_VECTOR = gql`
	mutation UPDATE_THREAT_VECTOR($id: Int!, $input: threatInputType!) {
		update_threat(where: { id: { exact: $id } }, input: $input) {
			affected_rows
		}
	}
`;
export const DELETE_THREAT_VECTOR = gql`
	mutation DELETE_THREAT_VECTOR($id: Int!) {
		delete_threat(where: { id: { exact: $id } }) {
			affected_rows
		}
	}
`;
export const DELETE_THREAT_VECTOR_CLIENT = gql`
	mutation DELETE_THREAT_VECTOR_CLIENT($id: Int!) {
		delete_client_threat(where: { id: { exact: $id } }) {
			affected_rows
		}
	}
`;

export const GET_CLIENT_LIST = gql`
	query GetClientList($limit: Int!, $offset: Int) {
		client(limit: $limit, offset: $offset) {
			id
			name
			currency {
				name
				symbol
				code
			}
			sensitivity_level
			industry_sector {
				name
			}
		}
	}
`;

export const GET_CLIENT = gql`
	query GET_CLIENT($limit: Int!) {
		client(limit: $limit) {
			id
			name
			currency {
				name
				symbol
				code
			}
			sensitivity_level
			industry_sector {
				name
			}
		}
		user_role {
			id
			role_name
		}
		enums_client_sensitivity_level
		currency(limit: $limit) {
			id
			name
			code
			symbol
		}
		industry_sector(limit: $limit) {
			id
			name
		}
	}
`;
export const GET_CLIENT_EXPLOITABLE_PATHS = gql`
	query GET_CLIENT_EXPLOITABLE_PATHS($limit: Int!, $offset: Int) {
		client_exploitable_path(limit: $limit, offset: $offset) {
			 exploitable_path{
				id
				entry {
					name
				} 
				middle{
					name
				}
				end{
					name
				}
				path_type
				}
				business_process{
				id
				name
				}
				client{
				id
				name
				}
		}
		client_exploitable_path_aggregate {
			count
		}
	}
`;
export const GET_EXPLOITABLE_PATHS = gql`
	query GET_EXPLOITABLE_PATHS($limit: Int!, $offset: Int) {
		exploitable_path(limit: $limit, offset: $offset) {
			id
			path_type
			entry {
				name
				stage
			}
			middle {
				name
				stage
			}
			end {
				name
				stage
			}
		}
		exploitable_path_aggregate {
			count
		}
	}
`;
export const GET_CURRENCY = gql`
	query GETCURRENCY($limit: Int!, $offset: Int) {
		currency(limit: $limit, offset: $offset) {
			id
			name
			code
			symbol
		}
		currency_aggregate {
			count
		}
	}
`;
export const GET_RISK_SENSETIVITY_LEVEL = gql`
	query GETRISKSENSETIVITYLEVEL($limit: Int!, $offset: Int) {
		risk_sensitivity_level(limit: $limit, offset: $offset) {
			id
			level
			severity
			min_value
			max_value
		}
		risk_sensitivity_level_aggregate {
			count
		}
		enums_risk_sensitivity_level_level
	}
`;
export const GET_BIA_SUPER_ADMIN = gql`
	query GETBIASUPERADMIN($limit: Int!, $offset: Int) {
		asset(limit: $limit, offset: $offset) {
			id
			name
			vendor
			risk
			cpe
			version
		}
		asset_aggregate {
			count
		}
	}
`;
export const GET_CONTROL_EFFECTIVENESS = gql`
	query GETCONTROLEFFECTIVENESS($limit: Int!, $offset: Int) {
		control_effectiveness(limit: $limit, offset: $offset) {
			id
			control_type {
				label
			}
			control_domain {
				label
			}
			control_category {
				label
			}
			effectiveness_in_percent
		}
		control_type(limit: $limit, offset: $offset) {
			id
			label
		}
		control_domain(limit: $limit, offset: $offset) {
			id
			label
		}
		control_category(limit: $limit, offset: $offset) {
			id
			label
		}
		control_effectiveness_aggregate {
			count
		}
	}
`;
export const GET_CONTROL_CATALOG = gql`
	query GET_CONTROL_CATALOG($limit: Int!, $offset: Int) {
		control(limit: $limit, offset: $offset) {
			id
			name
			industry_usecase
			primary_threat {
				name
			}
			secondary_threats
			control_applicability {
				label
			}
			control_impact {
				label
			}
			control_type {
				label
			}
			control_domain {
				label
			}
			control_category {
				label
			}
		}
		control_aggregate {
			count
		}
	}
`;
export const GET_CONTROLS = gql`
	query GET_CONTROLS($limit: Int!, $offset: Int) {
		control(limit: $limit, offset: $offset) {
			id
			name
	}
}
`;
export const GET_RESPONSE_DISTRIBUTION = gql`
	query GET_RESPONSE_DISTRIBUTION($limit: Int!, $offset: Int) {
		response_distribution(limit: $limit, offset: $offset) {
			id

			lost_business_cost
			detection_and_escalation
			ex_post_response
			notification
			attack_type
	}
}
`;
export const GET_NIST = gql`
	query GET_NIST($limit: Int!, $offset: Int) {
		nist(limit: $limit, offset: $offset) {
			id
			control_name {
				name
			}
			control_identifier
			related_controls
			discussion
	}
}
`;
export const GET_TC_MAPPING = gql`
	query GETTCMAPPING($limit: Int!, $offset: Int) {
		threat_control_mapping(limit: $limit, offset: $offset) {
			id
			threat {
				name
			}
			control {
				name
			}
		}
		threat_control_mapping_aggregate {
			count
		}
	}
`;
export const GET_INVESTMENT_REQUIREMENTS = gql`
  query GETINVESTMENTREQUIREMENTS($limit: Int!, $offset: Int) {
    control_investment_requirement(limit: $limit, offset: $offset) {
      id
      control_domain{
      label
      }
      control_category{
      label
      }
      investment_requirement
    }
    control_investment_requirement_aggregate {
      count
    }
  }
`;
export const GET_INDUSTRY_SECTOR = gql`
	query GET_INDUSTRY_SECTOR($limit: Int!, $offset: Int) {
		industry_sector(limit: $limit, offset: $offset) {
			id
			name
			description
		}
		industry_sector_aggregate {
			count
		}
	}
`;
export const GET_AT_MAPPING = gql`
	query GET_AT_MAPPING($limit: Int!, $offset: Int) {
		asset_threat_mapping(limit: 50, offset: $offset) {
			id
			asset {
				name
			}
			threat {
				name
			}
		}
		asset(limit: $limit, offset: $offset) {
			id
			name
			vendor
			risk
			cpe
			version
		}
		threat(limit: $limit, offset: $offset) {
			id
			name
			event_frequency
			cost_of_incident
			percent_of_breach
			lost_business_cost
		}
		asset_threat_mapping_aggregate {
			count
		}
	}
`;
export const GET_BUSINESS_IMPACT_ANALYSIS_SUPER_ADMIN = gql`
	query GetBusinessImpactAnalysis($client_id: ID!, $business_process_id: ID!) {
		super_admin_client_business_impact_analysis(
			client_id: $client_id
			business_process_id: $business_process_id
		) {
			id
			name
			cpe
			version
			product
			vendor
			risk
			hierarchy
			business_asset
			asset_type
			asset_categorization
			regulations
    }
      
  }
`;
export const GET_THREAT_RISK_MAPPING = gql`
  query GET_THREAT_RISK_MAPPING($limit: Int!, $offset: Int) {
    threat_risk_mapping(limit: $limit, offset: $offset) {
        id
        threat_category
        threats{
        name
        }
        cost_category
        operational
        financial
        health_and_safety
        environment
        comment
    }
    threat_risk_mapping_aggregate {
      count
    }
  }
`;

export const EXPORT_THREAT_RISK_MAPPING = gql`
  mutation EXPORT_THREAT_RISK_MAPPING(
    $inputs: ExportInputType!
  ) {
    export_threat_risk_mapping(inputs: $inputs) {
      data{
       file_content
      }
    }
  }
`;

export const IMPORT_THREAT_RISK_MAPPING = gql`
  mutation IMPORT_THREAT_RISK_MAPPING(
    $inputs: ImportInputType!
  ) {
    import_threat_control_mapping(inputs: $inputs) {
      data{
        inserted
        updated
        deleted
        total
        skip
        messages
      }
    }
  }
`;

export const GET_BUSINESS_PROCESSES_BY_CLIENT_ID = gql`
	query GetBusinessProcesses($client_id: ID!) {
		business_processes_by_client(client_id: $client_id) {
			id
			name
		}
	}
`;
export const SEARCH_PRODUCTS_BY_VENDOR = gql`
  query SearchProducts($vendor: String!) {
    search_products(vendor: $vendor) {
      product
      version
      cpe
      title
    }
  }
`;
export const UPDATE_CLIENT_BIA = gql`
	mutation UPDATE_CLIENT_BIA($id: Int!, $input: client_assetInputType!) {
		update_client_asset(where: { id: { exact: $id } }, input: $input) {
			affected_rows
		}
	}
`;
export const UPDATE_CONTROL_CATALOG = gql`
	mutation UPDATE_CONTROL_CATALOG($id: Int!, $input: controlInputType!) {
		update_control(where: { id: { exact: $id } }, input: $input) {
			affected_rows
		}
	}
`;
export const DELETE_CLIENT_BIA = gql`
	mutation DELETE_CLIENT_BIA($id: Int!) {
		delete_client_asset(where: { id: { exact: $id } }) {
			affected_rows
		}
	}
`;

export const DELETE_THREAT_RISK_MAPPING = gql`
  mutation DELETE_THREAT_RISK_MAPPING($id: Int!) {
    delete_threat_risk_mapping(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_EXPLOITABLE_PATH = gql`
	mutation UPDATE_EXPLOITABLE_PATH($id: Int!, $input: exploitable_pathInputType!) {
		update_exploitable_path(where: { id: { exact: $id } }, input: $input) {
			affected_rows
		}
	}
`;
export const DELETE_EXPLOITABLE_PATH = gql`
	mutation DELETE_EXPLOITABLE_PATH($id: Int!) {
		delete_exploitable_path(where: { id: { exact: $id } }) {
			affected_rows
		}
	}
`;

export const ADD_EXPLOITABLE_PATH = gql`
  mutation ADD_EXPLOITABLE_PATH($inputs:  [exploitable_pathInputType]!) {
    create_exploitable_path(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const UPDATE_CLIENT_EXPLOITABLE_PATH = gql`
	mutation UPDATE_CLIENT_EXPLOITABLE_PATH($id: Int!, $input: client_exploitable_pathInputType!) {
		update_exploitable_path(where: { id: { exact: $id } }, input: $input) {
			affected_rows
		}
	}
`;
export const DELETE_CLIENT_EXPLOITABLE_PATH = gql`
	mutation DELETE_CLIENT_EXPLOITABLE_PATH($id: Int!) {
		delete_client_exploitable_path(where: { id: { exact: $id } }) {
			affected_rows
		}
	}
`;

export const ADD_CLIENT_EXPLOITABLE_PATH = gql`
  mutation ADD_CLIENT_EXPLOITABLE_PATH($inputs:  [client_exploitable_pathInputType]!) {
    create_client_exploitable_path(inputs: $inputs) {
      affected_rows
    }
  }
`;

