import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { ADD_CONTROL_CATALOG } from '../../../graphql/superAdmin/mutation/mutation';
import {
	GET_CONTROL_TYPE,
	GET_CONTROL_DOMAIN,
	GET_CONTROL_CATEGORY,
	GET_CONTROL_APPLICABILITY,
	GET_CONTROL_IMPACT,
	GET_CONTROL_SCOPE,
} from '../../../graphql/superAdmin/query/controlConfiguration/query';
import { GET_THREATS, UPDATE_CONTROL_CATALOG } from '../../../graphql/superAdmin/query/query';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import swal from 'sweetalert';
import { ADD_INVESTMENT_REQUIREMENT, UPDATE_INVESTMENT_REQUIREMENT } from '../../../graphql/superAdmin/mutation/controlConfiguration/mutation';
import toast from 'react-hot-toast';

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: 'red' }}>*</span>}
		</Form.Label>
		<select className="form-control" value={value} onChange={onChange}>
			<option value="">Select {label.toLowerCase()}...</option>
			{options?.map((item) => (
				<option key={item.id} value={item.id}>
					{item.label || item.name}
				</option>
			))}
		</select>
		{error && <span style={{ color: 'red' }}>{error}</span>}
	</Form.Group>
);

// Reusable Input Component
const Input = ({ label, type, value, onChange, error, required, placeholder }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: 'red' }}>*</span>}
		</Form.Label>
		<input
			className="form-control"
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
		/>
		{error && <span style={{ color: 'red' }}>{error}</span>}
	</Form.Group>
);

const ModalHandler = ({ isOpen, mode, onClose, header, size, fetchData, selectedItem }) => {
	const [formData, setFormData] = useState({
		investment_requirement: '',
		control_domain: '',
		control_category: '',
	});

	const [errors, setErrors] = useState({});

	// Fetch data for dropdowns
	const { data: controlTypeData } = useQuery(GET_CONTROL_TYPE, { variables: { limit: 100, offset: 0 } });
	const { data: controlDomainData } = useQuery(GET_CONTROL_DOMAIN, { variables: { limit: 100, offset: 0 } });
	const { data: controlCategoryData } = useQuery(GET_CONTROL_CATEGORY, { variables: { limit: 100, offset: 0 } });
	const { data: controlApplicabilityData } = useQuery(GET_CONTROL_APPLICABILITY, { variables: { limit: 100, offset: 0 } });
	const { data: controlImpactData } = useQuery(GET_CONTROL_IMPACT, { variables: { limit: 100, offset: 0 } });
	const { data: controlScopeData } = useQuery(GET_CONTROL_SCOPE, { variables: { limit: 100, offset: 0 } });
	const { data: threatData } = useQuery(GET_THREATS, { variables: { limit: 100, offset: 0 } });

	const handleChange = (key, value) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
		if (errors[key]) setErrors((prev) => ({ ...prev, [key]: '' }));
	};

	const [addControl] = useMutation(ADD_INVESTMENT_REQUIREMENT);
	const [updateControl] = useMutation(UPDATE_INVESTMENT_REQUIREMENT);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const formatPostgresError = (errorMessage) => {
		const match = errorMessage.match(/\((.*?)\)=\((.*?)\)/);

		if (match) {
			const fields = match[1].split(", ");
			const values = match[2].split(", ");

			let formattedMessage = "A record with the same values already exists for: ";

			const details = fields.map((field, index) => `${field.replace(/_/g, " ")}: "${values[index]}"`).join(", ");

			return formattedMessage + details;
		}

		return "A duplicate entry error occurred. Please check your input and try again.";
	};
	const handleSubmit = () => {

		const newErrors = {
			investment_requirement: !formData.investment_requirement ? 'This field is required.' : '',
			control_domain: !formData.control_domain ? 'This field is required.' : '',
			control_category: !formData.control_category ? 'This field is required.' : '',
		};

		setErrors(newErrors);
		if (Object.values(newErrors).every((error) => !error)) {
			setIsSubmitting(true); // Disable further submissions

			const inputs = {
				created_at: new Date().toISOString(),
				control_domain_pk: parseInt(formData.control_domain, 10),
				control_category_pk: parseInt(formData.control_category, 10),
				investment_requirement: parseInt(formData.investment_requirement, 10)
			};

			if (mode === "add") {
				addControl({ variables: { inputs } })
					.then(() => {
						setIsSubmitting(false); // Re-enable submissions
						onClose();
						toast.success('Control has been added successfully!');
						fetchData();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage = err?.graphQLErrors?.[0]?.message || 'Something went wrong, please try again.';

						toast.error(formatPostgresError(errorMessage));
						console.error('GraphQL Error:', err);
					});
			} else {
				updateControl({ variables: { id: parseInt(selectedItem?.id), input: inputs } })
					.then(() => {
						setIsSubmitting(false);
						onClose();
						toast.success('Record has been updated successfully!');
						fetchData();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage = err?.graphQLErrors?.[0]?.message || 'Something went wrong, please try again.';
						console.error('GraphQL Error:', err);
						toast.error(formatPostgresError(errorMessage));
					});
			}
		}
	};
	console.log(controlDomainData, "controlDomainData")
	useEffect(() => {
		if (selectedItem) {
			const updatedFormData = {
				investment_requirement: selectedItem.investment_requirement || '',
				control_domain: controlDomainData?.control_domain.find(
					(domain) => domain.label === selectedItem.control_domain
				)?.id || '',
				control_category: controlCategoryData?.control_category.find(
					(category) => category.label === selectedItem.control_category
				)?.id || '',
			};

			setFormData(updatedFormData);
		} else {
			setFormData({
				investment_requirement: '',
				control_domain: '',
				control_category: ''
			});
		}
	}, [selectedItem, controlDomainData, controlCategoryData]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>{mode === 'edit' ? `Update ${header}` : `Add New Control Investment Requirement`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-three-columns">
					<Form className="modal-three-columns">
						{/* Control Details */}
						<Input
							label="Investment Requirement"
							type="text"
							value={formData.investment_requirement}
							onChange={(e) => handleChange('investment_requirement', e.target.value)}
							error={errors.investment_requirement}
							required
						/>
						<Dropdown
							label="Control Domain"
							value={formData.control_domain}
							onChange={(e) => handleChange('control_domain', e.target.value)}
							options={controlDomainData?.control_domain}
							error={errors.control_domain}
							required
						/>
						<Dropdown
							label="Control Category"
							value={formData.control_category}
							onChange={(e) => handleChange('control_category', e.target.value)}
							options={controlCategoryData?.control_category}
							error={errors.control_category}
							required
						/>
					</Form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn-cancel" onClick={onClose}>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === 'edit' ? 'Save Changes' : 'Add'}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;