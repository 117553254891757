// import { useHandleTheme } from "./useHandleTheme";

export const usePathOptions = (url) => {
	const pathOptions = {
		sankey: {
			node: {
				interactivity: true, // Enable interactivity for nodes
				colors: ["#101f3a", "#FFC93C", "#144272"],
				width: 15,
				nodePadding: 50,
				labelPadding: 5,
				nodeShape: "ellipse",
				strokeOpacity: 1,
				label: {
					fontName: "ui-monospace",
					fontSize: 16,
					textStyle: {
						wrapText: true, // Enable text wrapping
					},
					color: "dark:#9ca3af #000",
				},
			},
			link: {
				colorMode: "gradient",
				opacity: 0.7,
				width: 0.5, // Adjust path line thickness here
				colors: ["#101f3a", "#FFC93C", "#144272"],
				hoveredLinkColor: "#000", // Make hover color bolder
				css: `
          .google-visualization-sankey-link a:hover {
            stroke: black;
            stroke-opacity: 1;
          }
        `,
			},
			tooltip: {
				trigger: "selection",
			},
		},
	};

	return { pathOptions };
};
