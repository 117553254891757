import React, { useState } from "react";
import { useSelector } from "react-redux";
import ResponseDistribution from "./ResponseDistribution";
import FinancialImpact from "./FinancialImpact";
import ExploitablePaths from "../ExploitablePaths";
import { ReactComponent as CronusGearinC } from "../../assets/imgs/CronusGearinC.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import { Toast } from "react-bootstrap";
import RiskScore from "../../Charts/ThreatProfiling/RiskScore";
import { GET_THREAT_PROFILING } from "../../graphql/client/query";
import { useQuery } from "@apollo/client";
import ControlAnalysis from "./ControlAnalysis/ControlAnalysis";
const ThreatsProfilingTest = () => {
	const [isMoreDetails, setIsMoreDetails] = useState(false);
	const [isPostImpl, setIsPostImpl] = useState(false);
	const [isPreImpl, setIsPreImpl] = useState(true);

	const [path, setPath] = useState([]);
	const [risk, setRisk] = useState({});

	const processId = useSelector((state) => state.pid);
	const businessProcessId = processId ? parseInt(processId) : 0;

	const { data, loading, error, refetch } = useQuery(GET_THREAT_PROFILING, {
		variables: { business_process_id: businessProcessId },
		// Avoid running the query with an invalid ID
		skip: businessProcessId === 0,
		fetchPolicy: "network-only",
	});

	// Handle loading and error states
	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;

	//threat profiling data
	const profilingData = data?.threat_profiling_analysis;

	console.log(processId, "this is BP id");
	console.log(profilingData, "threat profiling");

	var initialScore = risk?.initial_average_risk_score?.value;
	var currentScore = risk?.average_risk_score?.value;
	var reductionScore = initialScore - currentScore;
	var percentageReductionScore = (
		(reductionScore / initialScore) *
		100
	)?.toFixed(1);

	return (
		<div
			className="
		p-10 md:p-4"
		>
			<div className="p-4 space-y-4 md:space-y-6">
				<div className="h-full rounded-xl shadow-lg  bg-white dark:bg-gray-900">
					<div className="flex w-full justify-start border-b-2 p-2">
						<h2 className="text-xl font-semibold pr-2">
							{isPostImpl ? "Post " : "Pre "}
							Control Implementation Profile
						</h2>
						<button
							onClick={() => {
								isPostImpl ? setIsPostImpl(false) : setIsPostImpl(true);
							}}
							className="text-xl font-semibold justify-center opacity-50 hover:opacity-100"
						>
							Click Here for {isPostImpl ? " Pre " : " Post "}
							<CronusGearinC className="h-7 w-7 inline-block" />
						</button>
					</div>
					<div className="divide-gray-600/25  grid place-items-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  2xl:grid-cols-2  gap-4 rounded-xl shadow-l ">
						<div className="flex items-center justify-center space-x-4 h-[200px]">
							<RiskScore data={data?.threat_profiling_analysis?.application_risk_profile} />
							{isPostImpl && <span className="text-7xl font-bold text-gray-500">→</span>}
							{isPostImpl && <RiskScore data={data?.threat_profiling_analysis?.residual_risk_profile} />}
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								flex: 1,
								maxHeight: "100%",
								width: "1/3",
								height: "200px",
							}}
							className="flex flex-grow justify-start items-center h-full flex-col text-center"
						>
							<span className="p-2 text-xl font-semibold border-b-2">
								Frequency of Occurrence
							</span>
							<div className="flex items-center justify-center space-x-4 h-[200px] mt-4">
								<span className="p-2 font-bold  text-xl">
									{data?.threat_profiling_analysis?.frequency_of_occurrence}
								</span>
								{isPostImpl && <span className="text-3xl font-bold text-gray-500">→</span>}
								{isPostImpl && <span className="p-2 font-bold  text-xl">
									{data?.threat_profiling_analysis?.post_frequency_of_occurrence}
								</span>}
							</div>

							{isPostImpl ? (
								<span className="flex mt-4">
									<p className="text-lg">
										{"Reduction Score: " + data?.threat_profiling_analysis?.over_all_risk_reduction + "%"}
									</p>
									<ArrowDropDownIcon className="inline-block h-10 text-green-500 w-10" />
								</span>
							) : (
								""
							)}
						</div>
					</div>
					<div className=" flex justify-center items-center border-t">
						<button
							onClick={() => {
								isMoreDetails
									? setIsMoreDetails(false)
									: setIsMoreDetails(true);
							}}
							className="justify-center opacity-80 hover:opacity-100"
						>
							More details...
						</button>
					</div>
				</div>

				{isMoreDetails ? (
					<div
						className="h-full flex  divide-x divide-gray-600/25  rounded-xl shadow-lg "
						style={{
							minHeight: "380px",
						}}
					>
						<ControlAnalysis refetch={refetch} />
					</div>
				) : null}

				<div
					className="h-full flex  divide-x divide-gray-600/25  rounded-xl shadow-lg  bg-white dark:bg-gray-900"
					style={{
						height: "380px",
						minHeight: "380px",
						opacity: "10",
					}}
				>
					<div
						style={{
							flex: 1,
							height: "100%",
							width: "80%",
						}}
						className="w-4/5 h-full  justify-center align-center  flex-grow"
					>
						<ExploitablePaths />
					</div>

					<div
						style={{
							display: "flex",
							height: "100%",
							width: "20%",
						}}
						className="flex flex-col justify-between  divide-y divide-gray-600/25  "
					>
						<div
							style={{
								flex: 1,
								borderTopRightRadius: "1rem",
								width: "100%",
								height: "50%",
								// opacity: "0.8",
							}}
							className="p-2 flex flex-col justify-between saturate-100  space-y-2"
						>
							<div className="flex justify-end">
								<span className="p-2 text-xl font-semibold">Breach</span>
							</div>

							<div
								style={{
									background: "var(--color-chart-one)",
									borderRadius: "9999px",
									width: "7rem",
									height: "7rem",
								}}
								className="  flex justify-center items-center shadow-lg  "
							>
								<h1 className=" text-2xl font-semibold">
									{data?.threat_profiling_analysis?.breach_count}
								</h1>
							</div>
						</div>

						<div
							style={{
								flex: 1,
								borderBottomRightRadius: "1rem",
								width: "100%",
								height: "50%",
								// opacity: "0.8",
							}}
							className="p-2 flex flex-col justify-between space-y-2"
						>
							<div className="flex justify-end    font-semibold text-4xl">
								<span className="p-2 text-xl font-semibold ">Incident</span>
							</div>

							<div
								style={{
									background: "var(--color-chart-two)",
									borderRadius: "9999px",
									width: "7rem",
									height: "7rem",
								}}
								className="  flex justify-center items-center shadow-lg  "
							>
								<h1 className=" text-2xl font-semibold">
									{/* {path?.incidents_count} */}
									{data?.threat_profiling_analysis?.incident_count}
								</h1>
							</div>
						</div>
					</div>
				</div>

				<div className="h-full pt-2 pb-4 flex rounded-xl shadow-lg bg-white dark:bg-gray-900 ">
					<div className="divide-gray-600/25  grid place-items-center sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2  2xl:grid-cols-2  gap-4 rounded-xl shadow-l ">
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								flex: 1,
								maxHeight: "100%",
								width: "1/4",
								height: "400px",
								minHeight: "400px",
							}}
						>
							<span className="p-2 text-xl font-semibold border-b-2">
								Financial Impact
							</span>
							<FinancialImpact data={data?.threat_profiling_analysis} />
						</div>

						<div
							style={{
								display: "flex",
								padding: "0.75rem",
								flexDirection: "column",
								flex: 1,
								maxHeight: "100%",
								width: "3/4",
								height: "400px",
								minHeight: "400px",
							}}
							className="flex flex-col justify-between "
						>
							<span className="p-2 text-xl font-semibold border-b-2">
								Response Distribution
							</span>
							<ResponseDistribution data={data} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThreatsProfilingTest;
