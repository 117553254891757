import { gql } from '@apollo/client';

export const ADD_CONTROLTYPE = gql`
  mutation ADD_CONTROLTYPE($inputs: [control_typeInputType]!) {
    create_control_type(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_TYPE = gql`
  mutation DELETE_CONTROL_TYPE($id: Int!) {
    delete_control_type(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONTROL_TYPE = gql`
  mutation UPDATE_CONTROL_TYPE(
    $id: Int!
    $input: control_typeInputType!
  ) {
    update_control_type(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;


//domain
export const ADD_CONTROL_DOMAIN = gql`
  mutation ADD_CONTROL_DOMAIN($inputs: [control_domainInputType]!) {
    create_control_domain(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_DOMAIN = gql`
  mutation DELETE_CONTROL_DOMAIN($id: Int!) {
    delete_control_domain(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONTROL_DOMAIN = gql`
  mutation UPDATE_CONTROL_DOMAIN(
    $id: Int!
    $input: control_domainInputType!
  ) {
    update_control_domain(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
//category
export const ADD_CONTROL_CATEGORY = gql`
  mutation ADD_CONTROL_CATEGORY($inputs: [control_categoryInputType]!) {
    create_control_category(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_CATEGORY = gql`
  mutation DELETE_CONTROL_CATEGORY($id: Int!) {
    delete_control_category(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONTROL_CATEGORY = gql`
  mutation UPDATE_CONTROL_CATEGORY(
    $id: Int!
    $input: control_categoryInputType!
  ) {
    update_control_category(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const UPDATE_CONTROL_EFFECTIVENESS = gql`
  mutation UPDATE_CONTROL_EFFECTIVENESS(
    $id: Int!
    $input: control_effectivenessInputType!
  ) {
    update_control_effectiveness(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_EFFECTIVENESS = gql`
  mutation ADD_CONTROL_EFFECTIVENESS($inputs: [control_effectivenessInputType]!) {
    create_control_effectiveness(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const ADD_CURRENCY = gql`
  mutation ADD_CURRENCY($inputs: [currencyInputType]!) {
    create_currency(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const UPDATE_CURRENCY = gql`
  mutation UPDATE_CURRENCY( $id: Int!
    $input: currencyInputType!) {
    update_currency(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;

export const DELETE_CURRENCY = gql`
  mutation DELETE_CURRENCY($id: Int!) {
    delete_currency(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const DELETE_COST_CATEGORY = gql`
  mutation DELETE_COST_CATEGORY($id: Int!) {
    delete_control_category(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_COST_CATEGORY = gql`
  mutation UPDATE_COST_CATEGORY( $id: Int!
    $input:  control_categoryInputType!) {
    update_control_category(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_COST_CATEGORY = gql`
  mutation ADD_COST_CATEGORY($inputs:  [control_categoryInputType]!) {
    create_control_category(inputs: $inputs) {
      affected_rows
    }
  }
`;


export const DELETE_CONTROL_APPLICABILITY = gql`
  mutation DELETE_CONTROL_APPLICABILITY($id: Int!) {
    delete_control_applicability(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_CONTROL_APPLICABILITY = gql`
  mutation UPDATE_CONTROL_APPLICABILITY( $id: Int!
    $input:  control_applicabilityInputType!) {
    update_control_applicability(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_APPLICABILITY = gql`
  mutation ADD_CONTROL_APPLICABILITY($inputs:   [control_applicabilityInputType]!) {
    create_control_applicability(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_CONTROL_USECASE = gql`
  mutation DELETE_CONTROL_USECASE($id: Int!) {
    delete_control_use_case(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_CONTROL_USECASE = gql`
  mutation UPDATE_CONTROL_USECASE( $id: Int!
    $input:  control_use_caseInputType!) {
    update_control_use_case(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_USECASE = gql`
  mutation ADD_CONTROL_USECASE($inputs:   [control_use_caseInputType]!) {
    create_control_use_case(inputs: $inputs) {
      affected_rows
    }
  }
`;




export const DELETE_CONTROL_IMPACT = gql`
  mutation DELETE_CONTROL_IMPACT($id: Int!) {
    delete_control_impact(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_CONTROL_IMPACT = gql`
  mutation UPDATE_CONTROL_IMPACT( $id: Int!
    $input:  control_impactInputType!) {
    update_control_impact(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_IMPACT = gql`
  mutation ADD_CONTROL_IMPACT($inputs:   [control_impactInputType]!) {
    create_control_impact(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_SCOPE = gql`
  mutation DELETE_CONTROL_SCOPE($id: Int!) {
    delete_control_scope(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_CONTROL_SCOPE = gql`
  mutation UPDATE_CONTROL_SCOPE( $id: Int!
    $input:  control_scopeInputType!) {
    update_control_scope(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_SCOPE = gql`
  mutation ADD_CONTROL_SCOPE($inputs:   [control_scopeInputType]!) {
    create_control_scope(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONTROL_STANDARD = gql`
  mutation DELETE_CONTROL_STANDARD($id: Int!) {
    delete_control_standard(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_CONTROL_STANDARD = gql`
  mutation UPDATE_CONTROL_STANDARD( $id: Int!
    $input:  control_standardInputType!) {
    update_control_standard(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_STANDARD = gql`
  mutation ADD_CONTROL_STANDARD($inputs:   [control_standardInputType]!) {
    create_control_standard(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_INVESTMENT_REQUIREMENT = gql`
  mutation DELETE_INVESTMENT_REQUIREMENT($id: Int!) {
    delete_control_investment_requirement(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_INVESTMENT_REQUIREMENT = gql`
  mutation UPDATE_INVESTMENT_REQUIREMENT( $id: Int!
    $input:  control_investment_requirementInputType!) {
    update_control_investment_requirement(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_INVESTMENT_REQUIREMENT = gql`
  mutation ADD_INVESTMENT_REQUIREMENT($inputs:   [control_investment_requirementInputType]!) {
    create_control_investment_requirement(inputs: $inputs) {
      affected_rows
    }
  }
`;