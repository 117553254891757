import { useState, useCallback } from "react";
import http from "../resources/http";
import axios from "axios";
import { toast } from "react-hot-toast";
import apiErrorHandler from "../services/apiErrorHandler";

const useFileUploadToServer = (
	file,
	postUrl,
	onClose,
	fetchData,
	selectedClientId,
	selectedBusinessProcessId,
	importMutation,
	reload
) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleUploadFile = useCallback(async () => {
		// Create a cancellation token source
		try {
			// Execute the mutation
			console.log("file = ", file);
			const response = await importMutation({
				variables: {
					inputs: {
						file_content: file,
					},
				},
			});

	
			// Check if there are any errors in the response
			if (response?.errors && response.errors.length > 0) {

				// Display the first error message
			} else {

				toast.success(`imported successfully!`);
				window.location.reload();
		
				
			}
		} catch (error) {
			// Catch and display any unexpected errors
			console.error('Error during import:', error);
			toast.error('Error importing file, please try again!');
			// window.location.reload();
		}

		return () => {
			console.log('Component unmounted: Upload canceled');
		};
	}, [file, postUrl, fetchData, onClose]);


	const handleUploadFileErase = useCallback(async () => {
		// Create a cancellation token source
		const source = axios.CancelToken.source();
		try {
			setLoading(true);
			setError(null);
			let formData = new FormData();
			formData.append("file", file);
			formData.append("erase", true);
			if (selectedBusinessProcessId && selectedClientId) {
				formData.append("client_id", selectedClientId);
				formData.append("business_process", selectedBusinessProcessId);
			}

			const response = await http.post(postUrl, formData, {
				headers: { "Content-Type": "multipart/form-data" },
				cancelToken: source.token,
			});

			toast.success("File successfully replaced");
			fetchData();
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				apiErrorHandler(error);
			}
		} finally {
			setLoading(false);
			onClose();
		}
		return () => {
			source.cancel("Component unmounted: Upload canceled");
		};
	}, [file, postUrl, fetchData, onClose]);

	return { loading, error, handleUploadFile, handleUploadFileErase };
};
export default useFileUploadToServer;
