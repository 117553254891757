import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import swal from "sweetalert";
import { ADD_EXPLOITABLE_PATH, GET_THREATS, UPDATE_EXPLOITABLE_PATH } from "../../../graphql/superAdmin/query/query";

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
    <Form.Group>
        <Form.Label>
            {label} {required && <span style={{ color: "red" }}>*</span>}
        </Form.Label>
        <select className="form-control" value={value} onChange={onChange}>
            <option value="">Select {label.toLowerCase()}...</option>
            {options?.map((item) => (
                <option key={item.id || item} value={item.id || item}>
                    {item.name || item.label || item}
                </option>
            ))}
        </select>
        {error && <span style={{ color: "red" }}>{error}</span>}
    </Form.Group>
);

const ModalHandler = ({
    isOpen,
    mode,
    onClose,
    header,
    selectedItem,
    selectedItemId,
    size,
    fetchData,
}) => {
    const [formData, setFormData] = useState({
        entry: "",
        middle: "",
        end: "",
        path_type: "", // New field for path type
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Fetch threats using GraphQL query
    const { data: threatsData, loading: threatsLoading } = useQuery(GET_THREATS, {
        variables: { limit: 100, offset: 0 },
    });

    // GraphQL Mutations
    const [addExploitablePath] = useMutation(ADD_EXPLOITABLE_PATH);
    const [updateExploitablePath] = useMutation(UPDATE_EXPLOITABLE_PATH);

    // Function to reset the form
    const resetForm = () => {
        setFormData({
            entry: "",
            middle: "",
            end: "",
            path_type: "",
        });
        setErrors({});
    };

    const handleChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
        if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
    };

    const handleSubmit = () => {
        if (isSubmitting) return; // Prevent re-submission

        // Dynamic validation based on path_type
        const newErrors = {
            entry: !formData.entry ? "This field is required." : "",
            middle: formData.path_type === "breach" && !formData.middle ? "This field is required." : "",
            end: formData.path_type === "breach" && !formData.end ? "This field is required." : "",
            path_type: !formData.path_type ? "This field is required." : "",
        };

        setErrors(newErrors);

        if (Object.values(newErrors).every((error) => !error)) {
            setIsSubmitting(true); // Disable further submissions

            const inputs = {
                created_at: new Date().toISOString(),
                entry_pk: parseInt(formData.entry),
                middle_pk: formData.middle ? parseInt(formData.middle) : null, // Middle is optional for incident
                end_pk: formData.path_type === "breach" ? parseInt(formData.end) : null,
                path_type: formData.path_type,
            };

            if (mode === "add") {
                addExploitablePath({ variables: { inputs } })
                    .then(() => {
                        setIsSubmitting(false); // Re-enable submissions
                        onClose();
                        resetForm(); // Clear the form
                        swal("Success!", "Exploitable path has been added successfully!", "success");
                        fetchData();
                    })
                    .catch((err) => {
                        setIsSubmitting(false);
                        const errorMessage =
                            err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
                        swal("Error", errorMessage, "error");
                        console.error("GraphQL Error:", err);
                    });
            } else {
                updateExploitablePath({ variables: { id: parseInt(selectedItemId), input: inputs } })
                    .then(() => {
                        setIsSubmitting(false);
                        onClose();
                        resetForm(); // Clear the form
                        swal("Success!", "Exploitable path has been updated successfully!", "success");
                        fetchData();
                    })
                    .catch((err) => {
                        setIsSubmitting(false);
                        const errorMessage =
                            err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
                        swal("Error", errorMessage, "error");
                        console.error("GraphQL Error:", err);
                    });
            }
        }
    };

    useEffect(() => {
        if (selectedItem) {
            // Populate form data based on selectedItem
            setFormData({
                entry: selectedItem.entry === "-" ? "" : selectedItem.entry, // Handle "-" as empty
                middle: selectedItem.middle === "-" ? "" : selectedItem.middle, // Handle "-" as empty
                end: selectedItem.end === "-" ? "" : selectedItem.end, // Handle "-" as empty
                path_type: selectedItem.type || "", // Use the `type` field for `path_type`
            });
        } else {
            resetForm(); // Reset the form when adding a new item
        }
    }, [selectedItem]);

    // Options for path_type dropdown
    const pathTypeOptions = ["incident", "breach"];

    return (
        <Modal isOpen={isOpen} onClose={() => { onClose(); resetForm(); }} size={size}>
            <Modal.Header>
                <Modal.Title>
                    {mode === "edit" ? `Update ${header}` : `Add New ${header}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-two-columns">
                    <Form>
                        <Dropdown
                            label="Path Type"
                            value={formData.path_type}
                            onChange={(e) => handleChange("path_type", e.target.value)}
                            options={pathTypeOptions} // Use predefined options
                            error={errors.path_type}
                            required
                        />
                        <Dropdown
                            label="Entry Threat"
                            value={formData.entry}
                            onChange={(e) => handleChange("entry", e.target.value)}
                            options={threatsData?.threat}
                            error={errors.entry}
                            required
                        />
                        {formData.path_type === "incident" && (
                            <Dropdown
                                label="Middle Threat"
                                value={formData.middle}
                                onChange={(e) => handleChange("middle", e.target.value)}
                                options={threatsData?.threat}
                                error={errors.middle}
                            />
                        )}
                        {formData.path_type === "breach" && (
                            <>
                                <Dropdown
                                    label="Middle Threat"
                                    value={formData.middle}
                                    onChange={(e) => handleChange("middle", e.target.value)}
                                    options={threatsData?.threat}
                                    error={errors.middle}
                                    required
                                />
                                <Dropdown
                                    label="End Threat"
                                    value={formData.end}
                                    onChange={(e) => handleChange("end", e.target.value)}
                                    options={threatsData?.threat}
                                    error={errors.end}
                                    required
                                />
                            </>
                        )}
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-cancel" onClick={() => { onClose(); resetForm(); }}>
                    Cancel
                </button>
                <button className="btn-add-new" onClick={handleSubmit}>
                    {mode === "edit" ? "Save Changes" : "Add"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalHandler;