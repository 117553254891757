import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import http from "../../../resources/http";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import AccountsSideNav from "../AccountsSideNav";
import {
	CLIENTS,
	CREATE_CLIENT_ACCOUNTS,
	UPDATE_CLIENT_ACCOUNT,
	INDUSTRY_SECTOR,
	CURRENCY_LIST,
	DELETE_CLIENT_USERS,
} from "../../../routes/accounts";
import { RISK_SENSITIVITY_LEVELS } from "../../../api/risk";
import { DeleteModal } from "../../../shared/DeleteModal";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CLIENT } from "../../../graphql/superAdmin/query/query";
import {
	ADD_CLIENT,
	DELETE_CLIENT,
	UPDATE_CLIENT,
} from "../../../graphql/superAdmin/mutation/users/mutation";
import toast from "react-hot-toast";
const ManageClients = () => {
	const [id, setId] = useState();
	const [clients, setClients] = useState([]);
	const [level, setLevel] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [sectors, setSectors] = useState([]);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const handleDeleteConfirmation = () =>
		setDeleteConfirmation(!deleteConfirmation);
	const [showHide, setSetShowHide] = useState(false);
	const handleModalShowHide = () => setSetShowHide(!showHide);

	const [showHideMe, setSetShowHideMe] = useState(false);
	const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);

	const [deleteModal, setDeleteModal] = useState(false);
	const toggleDeleteModal = () => setDeleteModal(!deleteModal);

	const [showHideDelete, setSetShowHideDelete] = useState(false);
	const handleModalShowHideDelete = () => setSetShowHideDelete(!showHideDelete);

	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 5;
	const pagesVisited = pageNumber * usersPerPage;
	const pageCount = Math.ceil(clients.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	const [form, setForm] = useState({}); //form
	const [errors, setErrors] = useState({}); //errors
	const [clientsNameList, setClientsNameList] = useState([]);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});

		if (!!errors[field])
			setErrors({
				...errors,
				[field]: null,
			});
	};
	const [action, setAction] = useState("");
	const validateForm = () => {
		const {
			companyName = "",
			industrySector = "",
			currency = "",
			riskSensitivity = "",
		} = form;
		const newErrors = {};
		if (action === "add" && clientsNameList.includes(form.companyName.trim()))
			newErrors.companyName = `Client name ${form.companyName} already exists,`;
		if (!companyName || companyName === "" || companyName === "Select...")
			newErrors.companyName = "Company Name is required.";
		// if (
		//   !industrySector ||
		//   industrySector === '' ||
		//   industrySector === 'Select...'
		// )
		//   newErrors.industrySector = 'Industry sector is required.';
		if (!currency || currency === "" || currency === "Select...")
			newErrors.currency = "currency is required.";
		if (!riskSensitivity || riskSensitivity === "")
			newErrors.riskSensitivity = "Risk sensitivity is required.";
		return newErrors;
	};
	const clearAll = () => {
		form.companyName = "";
		form.industrySector = "";
		form.currency = "";
		form.riskSensitivity = "";
	};

	// useEffect(() => {
	//             // fetchData();
	// }, []);

	const { data: client, refetch } = useQuery(GET_CLIENT, {
		variables: { limit: 10 },
	});

	const [del] = useMutation(DELETE_CLIENT);
	const deleteClient = async (e) => {
		try {
			const { data } = await del({
				variables: { id: parseInt(id) },
			});
			if (data?.delete_client?.affected_rows > 0) {
				refetch();
				toggleDeleteModal();
				toast.success("It has been deleted successfully");
			} else {
			}
		} catch (error) {
			toast.error("Failed to delete item:");
		}
	};

	// Define a state variable to hold the selected IDs
	const [selectedIds, setSelectedIds] = useState({
		industrySectorId: "",
		riskSensitivityId: "",
		currencyId: "",
	});
	console.log(form.riskSensitivity, "form.riskSensitivity");

	useEffect(() => {
		const updatedIndustrySectorId = sectors?.find(
			(item) => item.name === form.industrySector
		)?.id;
		const updatedRiskSensitivityId = level?.find(
			(item) => item.name === form.riskSensitivity
		)?.id;
		const updatedCurrencyId = currencies?.find(
			(item) => item.name === form.currency
		)?.id;

		setSelectedIds((prevIds) => ({
			...prevIds,
			industrySectorId: updatedIndustrySectorId || prevIds.industrySectorId,
			riskSensitivityId: updatedRiskSensitivityId || prevIds.riskSensitivityId,
			currencyId: updatedCurrencyId || prevIds.currencyId,
		}));
	}, [
		form.industrySector,
		form.riskSensitivity,
		form.currency,
		sectors,
		level,
		currencies,
	]);
	const [createMutation] = useMutation(ADD_CLIENT);
	const [updateMutation] = useMutation(UPDATE_CLIENT);
	const updateClient = async (e) => {
		e.preventDefault();
		const formErrors = validateForm();

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {
			try {
				const industrySectorId = selectedIds.industrySectorId;
				const riskSensitivityId = selectedIds.riskSensitivityId;
				const currencyId = selectedIds.currencyId;
				console.log(riskSensitivityId, riskSensitivityId, "ids");

				const response = await updateMutation({
					variables: {
						id: parseInt(id),
						input: {
							created_at: new Date().toISOString().split("T")[0],
							name: form.companyName,
							currency_pk: parseInt(currencyId),
							industry_sector_pk: parseInt(industrySectorId),
							sensitivity_level: "low",
							is_active: true,
							// industry_sector: form.industrySector,
						},
					},
				});

				if (response && response.data && response.data.update_client) {
					toast.success("Client updated successfully!");
					refetch();
					handleModalShowHide();
				} else if (response && response.errors && response.errors.length > 0) {
					// Extract the first error message
					const errorMessage =
						response.errors[0].message ||
						"An error occurred while updating the client.";
					toast.error(errorMessage);
				}
			} catch (error) {
				toast.error("An unexpected error occurred. Please try again.");
				console.error(error);
			}
		}
	};

	const submit = async (e) => {
		e.preventDefault();
		const formErrors = validateForm();
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
		} else {
			let response = await createMutation({
				variables: {
					inputs: {
						created_at: new Date().toISOString().split("T")[0],
						name: form.companyName,
						currency_pk: parseInt(form.currency),
						industry_sector_pk: parseInt(form.industrySector),
						sensitivity_level: form.riskSensitivity,
						is_active: true,
						// industry_sector: form.industrySector,
					},
				},
			});

			if (response && response.data) {
				toast.success("Client created successfully!");
				refetch();
				handleModalShowHide();
			}
			console.log(response, "response");
		}
	};
	console.log(selectedIds, "selectedIds");
	const DisplayData = client?.client
		?.slice(pagesVisited, pagesVisited + usersPerPage)
		?.map((client) => {
			return (
				<>
					<tr key={id}>
						<td data-label="Company Name">{client.name} </td>
						<td data-label="Industry Sector">
							{client?.industry_sector?.name ?? "N/A"}
						</td>
						<td className="" data-label="No. of Admin">
							{client?.currency?.code}
						</td>
						<td data-label="No. of User">{client.sensitivity_level}</td>

						<td className="flex text-center" data-label="Manage">
							<IconButton
								onClick={() => {
									console.log(client, "client");
									setId(client.id);
									setAction("edit");
									form.companyName = client.name;
									form.industrySector = client.industry_sector.name;
									form.currency = client.currency.code;
									form.riskSensitivity = client.sensitivity_level;
									handleModalShowHide();
								}}
							>
								<EditIcon />
							</IconButton>
							<IconButton
								onClick={() => {
									setId(client.id);
									toggleDeleteModal();
								}}
							>
								<DeleteIcon />
							</IconButton>
						</td>
					</tr>
				</>
			);
		});
	return (
		<div>
			{deleteModal ? (
				<DeleteModal
					handleDelete={deleteClient}
					handleModal={toggleDeleteModal}
				/>
			) : null}
			<div className="main-container">
				<div className="table-title ">
					<div className="table-title-text">
						<span>Clients </span>
						<button className="tooltip">
							<FaInfoCircle className="btn-info icon" />
							<span className="tooltip-text">This table contains Clients.</span>
						</button>
					</div>

					<div className="flex justify-between items-center space-x-4">
						{/* Download Button */}
						<button
							// onClick={handleExport}
							className="btn_file_download tooltip"
						>
							<FaDownload className="icon" />
							<span className="tooltip-text">Download File</span>
						</button>

						{/* Add New Button */}
						<button
							onClick={(e) => {
								clearAll();
								setAction("add");
								handleModalShowHide();
							}}
							className="btn-add-new tooltip"
						>
							<FaPlus className="icon" />
							<span className="tooltip-text">Add New Threat</span>
						</button>
					</div>
				</div>

				<table class="tables ">
					<thead className="sticky-header">
						<tr>
							<th scope="col">Company Name</th>
							<th scope="col">Industry Sector</th>
							<th scope="col">Currency</th>
							<th scope="col">Risk Sensitivity</th>
							<th className="w-28 text-center" scope="col">
								Manage
							</th>
						</tr>
					</thead>
					<tbody>{DisplayData}</tbody>
				</table>
				<div className="">
					{clients.length > 5 ? (
						<ReactPaginate
							previousLabel={"<"}
							nextLabel={">"}
							activeClassName={"paginationActive"}
							pageCount={pageCount}
							onPageChange={changePage}
							containerClassName={"paginationBttns"}
							previousLinkClassName={"previousBttn"}
							nextLinkClassName={"nextBttn"}
							disabledClassName={"paginationDisabled"}
						/>
					) : null}
				</div>
			</div>

			<Modal show={showHide} className="">
				<Modal.Header
					onClick={() => {
						handleModalShowHide();
					}}
				>
					<Modal.Title>
						{action === "add" ? "Add New" : "Edit"} Client
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-medium">
					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Client Name
						</Form.Label>
						<Form.Control
							type="text"
							id="companyName"
							value={form.companyName}
							onChange={(e) => {
								setField("companyName", e.target.value);
							}}
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							isInvalid={!!errors.companyName}
							required
						></Form.Control>
						<Form.Control.Feedback type="invalid">
							{errors.companyName}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group class="mb-6">
						<Form.Label
							for="password"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Industry Sector
						</Form.Label>
						<Form.Control
							as="select"
							type="text"
							id="password"
							value={form.industrySector}
							onChange={(e) => {
								const selectedId = e.target.value;
								const selectedName = sectors.find(
									(sector) => sector.id === selectedId
								)?.name;
								setField("industrySector", selectedId);
								setSelectedIds({
									...selectedIds,
									riskSensitivityId: e.target.value,
								});
							}}
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							isInvalid={!!errors.industrySector}
							required
						>
							<option>
								{action === "add"
									? sectors.find((sector) => sector.id === form.industrySector)
											?.name
									: form.industrySector}
							</option>
							{client?.industry_sector
								?.filter((item) => item.name !== form.industrySector)
								?.map((x, y) => (
									<option value={x.id}>{x.name}</option>
								))}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							{errors.industrySector}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group class="mb-6">
						<Form.Label
							for="password"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Currency
						</Form.Label>
						<Form.Control
							as="select"
							type="text"
							id="currency"
							value={form.currency}
							onChange={(e) => {
								setField("currency", e.target.value);
								setSelectedIds({
									...selectedIds,
									currencyId: e.target.value,
								});
							}}
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							isInvalid={!!errors.currency}
							required
						>
							<option>
								{action === "add"
									? currencies.find((sector) => sector.id === form.currency)
											?.name
									: form.currency}
							</option>
							{client?.currency
								?.filter((item) => item.name !== form.currency)
								?.map((x, y) => (
									<option value={x.id}>{x.name}</option>
								))}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							{errors.currency}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Risk Sensitivity
						</Form.Label>
						<Form.Control
							as="select"
							type="text"
							id="riskSensitivity"
							value={form.riskSensitivity}
							onChange={(e) => {
								setField("riskSensitivity", e.target.value);
								setSelectedIds({
									...selectedIds,
									riskSensitivityId: e.target.value,
								});
							}}
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="Enter Risk Sensitivity"
							isInvalid={!!errors.riskSensitivity}
							required
						>
							<option value="">{form.riskSensitivity}</option>
							{client?.enums_client_sensitivity_level?.map((x, y) => (
								<option value={x}>{x}</option>
							))}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							{errors.riskSensitivity}
						</Form.Control.Feedback>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn-cancel"
						onClick={() => {
							setErrors("");
							handleModalShowHide();
						}}
					>
						Cancel
					</Button>
					{action === "add" ? (
						<Button
							className="btn-add-new"
							onClick={(e) => {
								submit(e);
							}}
						>
							Add
						</Button>
					) : (
						<Button
							className="btn-add-new"
							onClick={(e) => {
								updateClient(e);
							}}
						>
							Save Changes
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ManageClients;
