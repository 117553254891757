import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import http from "../../../resources/http";
import {
	AVAILABILITY_LEVELS,
	AVAILABILITY_LEVEL_BY_PK,
} from "../../../api/threatCatalog";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import ModalHandler from "./ModalHandler";
import { GET_AVAILABILITY_LEVELS } from "../../../graphql/superAdmin/query/query";
import { useMutation, useQuery } from "@apollo/client";
import {
	ADD_AVAILABILITY,
	DELETE_AVAILABILITY,
	UPDATE_AVAILABILITY,
} from "../../../graphql/superAdmin/mutation/mutation";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";
const Availability = () => {
	const url_create = AVAILABILITY_LEVELS;
	const url_by_pk = AVAILABILITY_LEVEL_BY_PK;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const [id, setId] = useState();
	const [data, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["label", "value", "description"];

	const [updateAvailability] = useMutation(UPDATE_AVAILABILITY);
	const [addAvailability] = useMutation(ADD_AVAILABILITY);

	const [page, setPage] = useState(0);
	const pageSize = 10; // Number of items per page

	const {
		data: availableData,
		loading,
		error,
		refetch,
	} = useQuery(GET_AVAILABILITY_LEVELS, {
		variables: { limit: pageSize, offset: page * pageSize },
	});
	const handlePageChange = ({ selected }) => {
		setPage(selected);
		refetch({ limit: pageSize, offset: selected * pageSize });
	};

	const totalItems = availableData?.availability_level_aggregate?.count || 0;

	// useEffect(() => {
	// 	          // fetchData();
	// }, []);
	console.log(availableData, "edited");
	const filteredData = SearchHandler(
		availableData?.availability_level ?? [],
		searchValue,
		fieldsToFilterBy
	);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};
	const [deleteAvailability] = useMutation(DELETE_AVAILABILITY);

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			const { data } = await deleteAvailability({
				variables: { id: parseInt(id) },
			});
			if (data?.delete_availability_level?.affected_rows > 0) {
				setFetchLoading(false);
				refetch();
				swal("Success", "It has been deleted successfully", "success");
			} else {
			}
		} catch (error) {
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	return (
		<div className="main-container">
			<div className="table-container">
				<div className="table-title ">
					<div className="table-title-text">
						<span>Availability </span>
						<button className="tooltip">
							<FaInfoCircle className="btn-info icon" />
							<span className="tooltip-text">
								This table contains Availability.
							</span>
						</button>
					</div>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
							className="btn-add-new tooltip"
						>
							<FaPlus className="icon" />
							<span className="tooltip-text">Add New Data</span>
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
						page={page}
						pageSize={pageSize}
						totalItems={totalItems}
						onPageChange={handlePageChange}
						passedExcludeColumns={["__typename"]}
					/>
				)}{" "}
			</div>
			{/* add and edit modal */}
			<ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="xl"
				selectedItemId={id}
				selectedItem={selectedItem}
				header="Availability"
				createMutation={addAvailability}
				updateMutation={updateAvailability}
				create_object={"create_availability_level"}
				update_object={"update_availability_level"}
				fetchData={refetch}
			/>
		</div>
	);
};

export default Availability;
