import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import { CLIENTS } from "../../../routes/accounts";
import {
    GET_BUSINESS_PROCESS_BY_ID,
    GET_BUSINESS_IMPACT_BY_SUPERUSER,
} from "../../../api/businessProcess";
import {
    BUSINESS_IMPACT_LIST,
    BUSINESS_IMPACT_BY_PK,
    UPLOAD_BIA_EXCEL_BY_SUPERUSER,
} from "../../../api/excelUpload";
import {
    THREAT_CATEGORIES,
    THREAT_VECTORS_LIST,
} from "../../../api/threatCatalog";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";
import toast from "react-hot-toast";
import { UPDATE_INVESTMENT_ANALYSIS } from "../../../graphql/client/query";
import { useMutation } from "@apollo/client";
import { getBackgroundColor } from "../../../utils/getBackgroundColor";
import ColorIndicator from "../../../shared/ColorIndicator/ColorIndicator";

const ModalHandler = ({
    isOpen,
    onClose,
    mode,
    size,
    header,
    selectedItemId,
    selectedItem,
    url_create,
    url_get,
    url_by_pk,
    fetchData,
}) => {
    const [fetchLoading, setFetchLoading] = useState(false);

    const initialState = {
        control_domain: "",
        control_category: "",
        control_name: "",
        relevance: "",
        reduction_percentage: "",
        investment: "",
    };

    const [formData, setFormData] = useState({ initialState });
    const [errors, setErrors] = useState({});

    const validations = {
        investment: (value) => {
            if (!value) {
                return "This field is required.";
            } else if (value < 0) {
                return "Please enter a value higher than zero ";
            } else {
                return "";
            }
        },
    };

    const handleChange = (key, value) => {
        setFormData((prevState) => ({ ...prevState, [key]: value }));
        if (validations[key]) {
            const error = validations[key](value);
            setErrors((prevState) => ({ ...prevState, [key]: error }));
        }
    };

    const resetForm = () => {
        setFormData(initialState);
        setErrors({});
    };

    useEffect(() => {
        if (mode === "edit" && selectedItem) {
            console.log(selectedItem, mode, "selectedItem");
            setFetchLoading(true);
            setFormData(selectedItem);
            setFetchLoading(false);
        } else {
            resetForm();
        }
    }, [mode, selectedItem]);

    const formFieldsConfig = {
        control_category: {
            label: "Control Category",
            type: "text",
            disabled: "disabled",
            value: formData?.control_category,
            onChange: (e) => handleChange("control_category", e.target.value),
            error: errors.control_category,
        },
        investment: {
            label: " Investment",
            type: "number",
            min: 0,
            value: formData?.investment,
            onChange: (e) => handleChange("investment", e.target.value),
            error: errors.investment,
        },
    };
    const [update] = useMutation(UPDATE_INVESTMENT_ANALYSIS);

    const handleSubmit = async () => {
        let newErrors = {};
        Object.entries(formFieldsConfig).forEach(([key, config]) => {
            if (validations[key]) newErrors[key] = validations[key](formData[key]);
        });
        setErrors(newErrors);

        // Proceed if there are no validation errors
        if (Object.values(newErrors).every((error) => !error)) {
            try {
                let response;

                // Perform the mutation if in "edit" mode
                if (mode === "edit") {
                    response = await update({
                        variables: {
                            id: parseInt(selectedItemId),
                            input: {
                                investment: parseInt(formData.investment),
                            },
                        },
                    });
                }

                // Extract the dynamic key from the response
                const dynamicKey = Object.keys(response.data)[0];

                // Check if the mutation was successful
                if (response.data[dynamicKey].affected_rows > 0) {
                    toast.success(
                        mode === "edit"
                            ? "It has been updated successfully"
                            : "It has been added successfully"
                    );
                    resetForm();
                    fetchData();
                    onClose();
                } else {
                    toast.error("No rows were affected. Please check your input.");
                }
            } catch (error) {
                // Handle GraphQL errors or network issues
                toast.error("An error occurred. Please try again.");
                console.error("Mutation error:", error);
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={size}>
            <Modal.Header>
                <Modal.Title>
                    Risk Reduction Overview
                </Modal.Title>
                <ColorIndicator/>
            </Modal.Header>
            {fetchLoading ? (
                <LoadingComponent />
            ) : (
                <Modal.Body >
                    <div className="flex jusdtify-around items-center w-full">
                        <div className="modal-two-columns mr-44">
                            <Form>
                                <Form.Group>
                                    <Form.Label>Reduction Percentage</Form.Label>
                                </Form.Group>
                            </Form>
                            <Form>
                                <Form.Group>
                                    <Form.Label>{selectedItem?.control?.effectiveness}%</Form.Label>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="modal-two-columns mr-44">
                            <Form>
                                <Form.Group>
                                    <Form.Label>Pre Implementation</Form.Label>
                                </Form.Group>
                            </Form>
                            <Form>
                                <Form.Group>
                                    <Form.Label>
                                        <p
                                            className="p-1.5 text-center rounded-xl"
                                            style={{
                                                backgroundColor: getBackgroundColor(
                                                    selectedItem?.control?.primary_threat?.threat_severity_original?.low
                                                ),
                                                color: "black",
                                            }}
                                        >
                                            {/* {controls?.threat_severity?.low} */}
                                        </p></Form.Label>
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="modal-two-columns">
                            <Form>
                                <Form.Group>
                                    <Form.Label>Post Implementation</Form.Label>
                                </Form.Group>
                            </Form>
                            <Form>
                                <Form.Group>
                                    <Form.Label> <p
                                        className="p-1.5 text-center rounded-xl"
                                        style={{
                                            backgroundColor: getBackgroundColor(
                                                selectedItem?.control?.primary_threat?.threat_severity?.low
                                            ),
                                            color: "black",
                                        }}
                                    >
                                        {/* {controls?.threat_severity?.low} */}
                                    </p></Form.Label>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                    <Form>
                        <div className="italic mb-4">By applying {selectedItem?.control?.name}, the risk level of {selectedItem?.control?.primary_threat?.name} has been reduced by {selectedItem?.control?.effectiveness}%, mitigating the threat more effectively.</div>
                        <div className="italic">*Post-Implementation value shows the adjusted risk after mitigation measures.</div>
                        <div className="italic">*The Pre-Implementation value represents the original threat level before controls were applied</div>
                    </Form>
                </Modal.Body>
            )}
            <Modal.Footer>
                <button
                    className="btn-cancel"
                    onClick={() => {
                        onClose();
                        resetForm();
                    }}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalHandler;
