import React, { useState, useEffect } from 'react';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import http from '../../../resources/http';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import { CURRENCY, CURRENCY_LIST } from '../../../routes/accounts';
import swal from 'sweetalert';
import { ADD_CURRENCY, UPDATE_CURRENCY } from '../../../graphql/superAdmin/mutation/controlConfiguration/mutation';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

const ModalHandler = ({
  isOpen,
  onClose,
  mode,
  size,
  selectedItemId,
  selectedItem,
  header,
  url,
  fetchData,
}) => {
  const initialState = {
    name: '',
    symbol: '',
    code: '',
  };
  const [fetchLoading, setFetchLoading] = useState(false);
  const [formData, setFormData] = useState({ initialState });
  const [errors, setErrors] = useState({});

  const validations = {
    name: (value) => (value ? '' : 'This field is required.'),
    symbol: (value) => (value ? '' : 'This field is required.'),
  };

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }
  };

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };

  useEffect(() => {
    if (mode === 'edit' && selectedItem) {
      setFormData(selectedItem);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const formFieldsConfig = {
    name: {
      label: 'Currency Name',
      type: 'text',
      placeholder: 'Text...',
      value: formData?.name,
      onChange: (e) => handleChange('name', e.target.value),
      error: errors.name,
    },
    symbol: {
      label: 'Currency Symbol',
      type: 'text',
      placeholder: 'Symbol...',
      value: formData?.symbol,
      onChange: (e) => handleChange('symbol', e.target.value),
      error: errors.symbol,
    },
    code: {
      label: 'Currency Code',
      type: 'text',
      placeholder: 'Code...',
      value: formData?.code,
      onChange: (e) => handleChange('code', e.target.value),
      error: errors.code,
    },
  };
  const [addCurrency] = useMutation(ADD_CURRENCY);
  const [updateCurrency] = useMutation(UPDATE_CURRENCY);

  const handleSubmit = async () => {
    let newErrors = {};
    Object.entries(formFieldsConfig).forEach(([key, config]) => {
      if (validations[key]) newErrors[key] = validations[key](formData[key]);
    });
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      setFetchLoading(true);
      let response;

      if (mode === 'edit') {
        response = await updateCurrency({
          variables: {
            id: parseInt(selectedItemId),
            input: {
              created_at: "2025-09-01",
              name: formData.name,
              symbol: formData.symbol,
              code: formData.code,
            },
          },
        });
      } else {
        response = await addCurrency({
          variables: {
            inputs: {
              created_at: "2025-09-01",
              name: formData.name,
              symbol: formData.symbol,
              code: formData.code,
            },
          },
        });
      }

      const dynamicKey = Object.keys(response.data)[0]; // Get the dynamic key

      if (response.data[dynamicKey].affected_rows > 0) {
        toast.success(
          mode === 'edit'
            ? 'It has been updated successfully'
            : 'It has been added successfully'
        );
        resetForm();
        fetchData();
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === 'edit' ? `Update  ${header}` : `Add New  ${header}`}
        </Modal.Title>
      </Modal.Header>

      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body className="modal-body-two-columns">
          <Form>
            {Object.entries(formFieldsConfig || {}).map(
              ([key, fieldConfig]) => (
                <Form.Group key={key}>
                  <Form.Label>{fieldConfig.label}</Form.Label>
                  <Form.Control {...fieldConfig} />
                  {errors[key] && (
                    <Form.Control.Feedback type="invalid">
                      {errors[key]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )
            )}
          </Form>
        </Modal.Body>
      )}
      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
