import React, { useContext } from "react";
import { ThemeContext } from "./ThemeProvider";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

function ThemeController() {
	const { theme, setTheme } = useContext(ThemeContext);

	const toggleTheme = () => {
		setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
	};

	return (
		<span onClick={toggleTheme} style={{ cursor: "pointer" }}>
			{theme === "light" ? (
				<>
					<DarkModeIcon className="inline-block" /> Switch to Dark
				</>
			) : (
				<>
					<LightModeIcon className="inline-block" /> Switch to Light
				</>
			)}
		</span>
	);
}

export default ThemeController;
