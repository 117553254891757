import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import "./index.css";
import authStore from "./authentication/store";
import { ThemeProvider } from "./context/ThemeProvider";
import { setContext } from "@apollo/client/link/context";

// Create the HTTP link for Apollo Client
const httpLink = createHttpLink({
  uri: "https://api-new.cronusgrx.io/graphql" // Replace with your GraphQL endpoint
  // uri: "http://127.0.0.1:8000/graphql" // Replace with your GraphQL endpoint
});


// Middleware r njsdnjcs nto set the Authorization header if the token exists
const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  // const token = localStorage.getItem('authToken');
  var tokenInfo = JSON.parse(localStorage.getItem("cyber-minds"));

  const token = tokenInfo?.token;

  // Return the headers to the context, with the token if it exists
  return {
    headers: {
      ...headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
});

// Create the Apollo Client
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// Create the React Query Client
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={authStore}>
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </Provider>,
  document.querySelector("#root")
);
