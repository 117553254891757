import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import http from '../../../resources/http';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { baseURL } from '../../../resources/apiClient';
import swal from 'sweetalert';
import { Button, Modal, Form } from 'react-bootstrap';
import { downloadExcelDataIndustryData } from '../../../utils/ExportExcelUtility';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import { GET_INDUSTRY_DATA } from '../../../graphql/superAdmin/query/query';
import SearchHandler from '../../../shared/SearchHandler';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_CONSENSUS_ASSESSMENT, DELETE_INDUSTRY_DATA, IMPORT_INDUSTRY_DATA,EXPORT_INDUSTRY_DATA } from '../../../graphql/superAdmin/mutation/mutation';
import toast from 'react-hot-toast';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import CustomTable from '../../../shared/CustomTable';
import ModalHandler from './ModalHandler';
import ModalFileUpload from '../../../shared/ModalFileUpload';
import { saveAs } from 'file-saver';

import { useNavigate } from 'react-router-dom';


const ViewIndustryData = () => {
  const [searchValue, setSearchValue] = useState('');
  const { data, loading, error, refetch: fetchData } = useQuery(GET_INDUSTRY_DATA, {
    variables: { limit: 100 },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState("add"); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [id, setId] = useState();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const toggleFileUploader = () => setShowFileUploader(!showFileUploader);
  const [showHideAdd, setShowHideAdd] = useState(false);
  const handleModalShowHideAdd = () => setShowHideAdd(!showHideAdd);
  const formatted = data?.industry_data?.map(items => ({
    id: items?.id,
    industry_sector: items?.industry_sector?.name,
    total_breaches: items?.total_breaches,
    total_incidents: items?.total_incidents,
    total_cost_of_data_breach: items?.total_cost_of_data_breach,
    year: items?.year
  }))
  const filteredData = SearchHandler(formatted ?? [], searchValue, ['']);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pageCount = Math.ceil(filteredData.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };

  const handleFileUploader = () => {
    setShowFileUploader(!showFileUploader);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode("edit");
    setIsModalOpen(true);
  };

  const handleExport = async () => {
    const { data } = await exportMutation({
      variables: {
        inputs: {
          extension: "xlsx"
        } 
      }
    });

    const fileContent = data?.export_industry_data?.data[0]?.file_content;
    if (fileContent) {
      const binaryData = atob(fileContent);
      
      // Create a Uint8Array from the binary data
      const bytes = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        bytes[i] = binaryData.charCodeAt(i);
      }
      
      // Create blob from the bytes
      const blob = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      
      // Create download link and trigger download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'industry_data_export.xlsx';
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
    setFetchLoading(false);
  };
  
  

  const [del] = useMutation(DELETE_INDUSTRY_DATA);
  const [importMutation] = useMutation(IMPORT_INDUSTRY_DATA);
  const [exportMutation] = useMutation(EXPORT_INDUSTRY_DATA);
  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });

      if (data?.delete_industry_data?.affected_rows > 0) {
        setFetchLoading(false);
        fetchData();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };

  return (
    <div className="p-10">
      <div className="table-title ">
        <span className="">Industry</span>

       

        <div className="flex justify-between items-center  space-x-4">
          <SearchInput
            searchValue={searchValue}
            setSearchValue={handleInputChange}
          />

        {/* <button
                  onClick={() => handleFileUploader()}
                  className="btn_file_upload"
                >
                  <img
                    className="w-7 h-7"
                    alt="industry_data"
                    src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
                  />
                  Upload File
          </button> */}


        <button
						onClick={() => {
							handleFileUploader(true);
						}}
						className="btn_file_download"
					>
						<img
							alt="upload-avatar"
							className="w-7 h-7"
							src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
						/>
					</button>


          <button className="btn_file_download">
            <svg
              onClick={() =>
                handleExport()
               
              }

      
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fill-rule="evenodd"
              fill="white"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>

          <div className="btn-add-new">
            <button
              onClick={(e) => {
                setSelectedItem(null);
                setMode("add");
                setIsModalOpen(true);
              }}
            >
              <AddIcon className="bold" />
            </button>
          </div>
        </div>
      </div>


      {fetchLoading ? (
        <LoadingComponent />
      ) : searchValue.length > 0 && filteredData.length === 0 ? (
        <div className="alert-no-data">
          No data available for
          <span className="search-value">{searchValue}</span>
          at the moment.
        </div>
      ) : (
        <CustomTable
          filteredData={filteredData}
          onEditClick={handleEdit}
          onDeleteClick={handleDelete}
          passedExcludeColumns={['__typename']}
        />
      )}
      <ModalHandler
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode={mode}
        size="xl"
        selectedItemId={id}
        selectedItem={selectedItem}
        header="Industry Data"
        fetchData={fetchData}
      />
      {showFileUploader ? (
        <ModalFileUpload
          isOpen={showFileUploader}
          onClose={() => setShowFileUploader(false)}
          header="Industry Data"
          fetchData={fetchData}
          importMutation={importMutation}
        />
      ) : null} 
    </div>
  );
};

export default ViewIndustryData;
