import { gql } from '@apollo/client';
export const GET_CONTROL_TYPE = gql`
  query GET_CONTROL_TYPE($limit: Int!, $offset: Int) {
    control_type(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_type_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_DOMAIN = gql`
  query GET_CONTROL_DOMAIN($limit: Int!, $offset: Int) {
    control_domain(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_domain_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_CATEGORY = gql`
  query GET_CONTROL_DOMAIN($limit: Int!, $offset: Int) {
    control_category(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_category_aggregate {
      count
    }
  }
`;

export const GET_CONTROL_APPLICABILITY = gql`
  query GET_CONTROL_APPLICABILITY($limit: Int!, $offset: Int) {
    control_applicability(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_applicability_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_IMPACT = gql`
  query GET_CONTROL_IMPACT($limit: Int!, $offset: Int) {
    control_impact(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_impact_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_USE_CASE = gql`
  query GET_CONTROL_IMPACT($limit: Int!, $offset: Int) {
    control_use_case(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_use_case_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_SCOPE = gql`
  query GET_CONTROL_SCOPE($limit: Int!, $offset: Int) {
    control_scope(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_scope_aggregate {
      count
    }
  }
`;
export const GET_COST_CATEGORY = gql`
  query GET_COST_CATEGORY($limit: Int!, $offset: Int) {
    cost_category(limit: $limit, offset: $offset) {
      id
      category
    }
  }
`;
export const GET_ASSET_LIST = gql`
  query GET_ASSET_LIST($limit: Int!, $offset: Int) {
    assets(limit: $limit, offset: $offset) {
      id
      name
      description
    }
    assets_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_STANDARD = gql`
  query GET_ASSET_LIST($limit: Int!, $offset: Int) {
    control_standard(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_standard_aggregate {
      count
    }
  }
`;