import React, { useState } from 'react';
import http from '../../../resources/http';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Button, Form } from 'react-bootstrap';
import { baseURL } from '../../../resources/apiClient';
import swal from 'sweetalert';
import { GET_RISK_SENSETIVITY_LEVEL } from '../../../graphql/superAdmin/query/query';
import { useMutation, useQuery } from '@apollo/client';
import Modal from '../../../shared/Modal';
import { ADD_SENSITIVITY_LEVEL, UPDATE_SENSITIVITY_LEVEL } from '../../../graphql/superAdmin/mutation/mutation';
import toast from 'react-hot-toast';

// Reusable Table Component
const RiskTable = ({ title, data, onEdit , onDelete}) => {
  return (
    <div className="border rounded-lg shadow-lg overflow-hidden bg-white">
      <div className="bg-gray-600 text-white p-4 font-semibold text-lg text-center">
        {title}
      </div>
      <div className="p-4">
        <table className="w-full text-sm">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 text-left">Min Value</th>
              <th className="p-2 text-left">Max Value</th>
              <th className="p-2 text-left">Severity</th>
              <th className="p-2 text-center">Manage</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50 transition-colors">
                <td className="p-2 text-left">{item.min_value}</td>
                <td className="p-2 text-left">{item.max_value}</td>
                <td className="p-2 text-left">{item.severity}</td>
                <td className="p-2 text-center h-8 w-8">
                  <IconButton onClick={() => onEdit(item)}>
                    <EditIcon className="" />
                  </IconButton>
                </td>

               
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Reusable Modal Component
const RiskModal = ({ show, onClose, onSubmit, data, mode }) => {
  const [minValue, setMinValue] = useState(data?.min_value || '');
  const [maxValue, setMaxValue] = useState(data?.max_value || '');
 

  // Reset form fields when data changes (e.g., when editing a new item)
  React.useEffect(() => {
    setMinValue(data?.min_value || '');
    setMaxValue(data?.max_value || '');

  }, [data]);

  return (
    <Modal isOpen={show} onClose={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === 'add' ? 'Add New Risk Sensitivity' : 'Update Risk Sensitivity'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-two-columns">
          <Form.Group className="mb-4">
            <Form.Label>Minimum Value</Form.Label>
            <Form.Control
              type="text"
              value={minValue}
              onChange={(e) => setMinValue(e.target.value)}
              placeholder="Enter Minimum Value"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Maximum Value</Form.Label>
            <Form.Control
              type="text"
              value={maxValue}
              onChange={(e) => setMaxValue(e.target.value)}
              placeholder="Enter Maximum Value"
            />
          </Form.Group>
      
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-cancel" onClick={onClose}>
          Close
        </button>
        <button
          className="btn-add-new"
          onClick={() => onSubmit({ minValue, maxValue})}
        >
          {mode === 'add' ? 'Add Record' : 'Save Changes'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const RiskSensetivity = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const { data, loading, refetch } = useQuery(GET_RISK_SENSETIVITY_LEVEL, {
    variables: { limit: 100, offset: 0 },
  });
  // const [addSensitivity] = useMutation(ADD_SENSITIVITY_LEVEL)
  const [updateSensitivity] = useMutation(UPDATE_SENSITIVITY_LEVEL)

  const handleEdit = (item) => {
    console.log("item = ",item)
    setSelectedItem(item); // Set the selected item
    setMode('edit'); // Set mode to 'edit'
    setShowModal(true); // Open the modal
  };



  const handleAdd = () => {
    setSelectedItem(null); // Clear selected item
    setMode('add'); // Set mode to 'add'
    setShowModal(true); // Open the modal
  };

  const handleSave = async ({ minValue, maxValue, severity, sensetivity }) => {
    try {
      var response;  
      if(parseFloat(minValue) > parseFloat(maxValue)){
        toast.error("Minimum value cannot be greater than maximum value");
        return;
      }



  
      
      if(mode === 'edit'){

        const id = parseInt(selectedItem.id);
        response = await updateSensitivity({
          variables: {
            id: id,
            input: {
              min_value: parseFloat(minValue),
              max_value: parseFloat(maxValue),
              updated_at: new Date().toISOString().split('T')[0]
            },
          },
        });

      }

      console.log("response = ",response)

      const dynamicKey = Object.keys(response.data)[0]; // Get the dynamic key
      if (response.data[dynamicKey].affected_rows > 0) {
        toast.success(
          mode === 'edit'
            ? 'It has been updated successfully'
            : 'It has been added successfully'
        );
       
        refetch();
      } else {
        // Update existing record

        await http.patch(`${baseURL}/risk/risk-sensitivity/${selectedItem.id}/`, {
          min_value: parseFloat(minValue),
          max_value: parseFloat(maxValue),
          updated_at: new Date().toISOString().split('T')[0]
       
        });
      
        swal('Success', 'Risk sensitivity updated successfully', 'success');
      }
      setShowModal(false);
      setSelectedItem(null); // Reset selected item after saving
    } catch (error) {
      const errorMessage = error.message;
      console.log("errorMessage = ",error)
      swal('Error',"please check the data" , 'error');

    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null); // Reset selected item when modal is closed
  };

  const filteredDataLow = data?.risk_sensitivity_level?.filter(
    (item) => item.level.toUpperCase() === 'LOW'
  );
  const filteredDataMedium = data?.risk_sensitivity_level?.filter(
    (item) => item.level.toUpperCase() === 'MEDIUM'
  );
  const filteredDataHigh = data?.risk_sensitivity_level?.filter(
    (item) => item.level.toUpperCase() === 'HIGH'
  );

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Risk Sensitivity</h1>
        {/* <button
          className="btn-add-new"
          onClick={handleAdd}
        >
          Add New
        </button> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <RiskTable title="Low" data={filteredDataLow} onEdit={handleEdit} />
        <RiskTable title="Medium" data={filteredDataMedium} onEdit={handleEdit} />
        <RiskTable title="High" data={filteredDataHigh} onEdit={handleEdit} />
      </div>

      <RiskModal
        show={showModal}
        onClose={handleCloseModal}
        onSubmit={handleSave}
        data={selectedItem} // Pass selected item data to the modal
        mode={mode} // Pass mode to the modal
      />
    </div>
  );
};

export default RiskSensetivity;