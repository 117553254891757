import React, { useState, useEffect } from "react";
import Modal from "../../../shared/Modal";
import swal from "sweetalert";
import toast from "react-hot-toast";
import { GET_THREATS } from "../../../graphql/superAdmin/query/query";
import { useQuery } from "@apollo/client";

const ATAddEditHandler = ({
  data,
  isOpen,
  mode,
  onClose,
  selectedItemId,
  selectedItem,
  updateMutation,
  createMutation,
  fetchData,
}) => {
  const [formData, setFormData] = useState({
    asset: "",
    threat: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { data: threat } = useQuery(GET_THREATS, {
    variables: { limit: 100 },
  });

  // Handle input change
  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (errors[key]) {
      setErrors((prevState) => ({ ...prevState, [key]: "" }));
    }
  };

  // Validate the form before submission
  const validateForm = () => {
    const newErrors = {};
    if (!formData.asset) newErrors.asset = "Please select an asset.";
    if (!formData.threat) newErrors.threat = "Please select a threat.";
    return newErrors;
  };

  // Reset form data when needed
  const resetForm = () => {
    setFormData({
      asset: "",
      threat: "",
    });
    setErrors({});
  };

  // Prefill the form if we're in edit mode
  useEffect(() => {
    if (mode === "edit" && selectedItem) {
      // Find the asset ID based on the asset name
      const selectedAsset = data?.assets?.find(
        (asset) => asset.name === selectedItem.asset
      );
      // Find the threat ID based on the threat name
      const selectedThreat = threat?.threat?.find(
        (threat) => threat.name === selectedItem.threat
      );

      setFormData({
        asset: selectedAsset?.id || "",
        threat: selectedThreat?.id || "",
      });
    } else {
      resetForm();
    }
  }, [mode, selectedItem, data?.assets, threat?.threat]);

  // Handle form submission
  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      if (mode === "edit") {
        await updateMutation({
          variables: {
            id: parseInt(selectedItemId),
            input: {
              asset_pk: parseInt(formData.asset),
              threat_pk: parseInt(formData.threat),
            },
          },
        });
      } else {
        await createMutation({
          variables: {
            inputs: {
              created_at: new Date().toISOString(),
              asset_pk: parseInt(formData.asset),
              threat_pk: parseInt(formData.threat),
            },
          },
        });
      }

      toast.success("Operation successful!");
      fetchData(); // Refresh data after mutation
      onClose(); // Close modal after success
    } catch (error) {
      toast.error("Error occurred while processing.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-6">
          {mode === "edit" ? "Edit Mapping" : "Add Mapping"}
        </h3>

        {/* Asset Dropdown */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Asset
          </label>
          <select
            value={formData.asset}
            onChange={(e) => handleChange("asset", e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Select Asset</option>
            {data?.assets?.map((asset) => (
              <option key={asset.id} value={asset.id}>
                {asset?.name}
              </option>
            ))}
          </select>
          {errors.asset && (
            <p className="text-sm text-red-500 mt-1">{errors.asset}</p>
          )}
        </div>

        {/* Threat Dropdown */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Threat
          </label>
          <select
            value={formData.threat}
            onChange={(e) => handleChange("threat", e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Select Threat</option>
            {threat?.threat?.map((threat) => (
              <option key={threat.id} value={threat.id}>
                {threat?.name}
              </option>
            ))}
          </select>
          {errors.threat && (
            <p className="text-sm text-red-500 mt-1">{errors.threat}</p>
          )}
        </div>

        {/* Submit and Cancel Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? "Saving..." : mode === "edit" ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ATAddEditHandler;