import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { sensetivities } from "../../../data/data";
import { useApi } from "../../../hooks/useApis";
import useControlFilters from "../../../hooks/useControlsFilter";
// import { useHandleTheme } from "../../../hooks/useHandleTheme";
import { baseURL } from "../../../resources/apiClient";
import http from "../../../resources/http";
import ColorIndicator from "../../../shared/ColorIndicator/ColorIndicator";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import TableLoadingAnimation from "../../../shared/TableLoading/TableLoadingAnimation";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent.js";
import {
	getApplicationRiskProfilColor,
	getBackgroundColor,
} from "../../../utils/getBackgroundColor";
import {
	capitalizeFirstLetter,
	formatListWithComma,
} from "../../../utils/helpers";
import { usePropertyTransformation } from "../../../utils/usePropertyTransform";
import { useSortableData } from "../../../utils/useSortableData";
import { CLIENT_CONTROLS_CATALOG_ENDPOINT } from "./../../../api/threatCataloModels";
import VulnerabilityHeatMap from "./../../../Charts/ControlAnalysis/VulnerabilityHeatMap";
import ScatterChart from "./../../../Charts/ControlAnalysis/ScatteredChart";
import ThreatRisudualRisk from "../../ThreatRisudualRisk";
// import { GET_MY_BUSINESS_PROCESS } from ".././api/businessProcess";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import DomainThreatChart from "./DomainThreatChart";
import RiskReductionChart from "./RiskReductionChart";
import { useMutation, useQuery } from "@apollo/client";
import {
	GET_CONTROL_ANALYSIS,
	UPDATE_CONTROL_ANALYSIS_MUTATION,
} from "../../../graphql/client/query.js";
import ModalHandler from "./ModalHandler.js";

const ControlAnalysis = ({ refetch }) => {
	const ref = useRef(null);

	const [showFilters, setShowFilters] = useState(false);
	const toggleFilters = () => setShowFilters(!showFilters);

	const [showSorts, setShowSorts] = useState(false);
	// useCallback to memoize the toggleSorts function and prevent re-creation
	const toggleSorts = useCallback(() => {
		setShowSorts((prevShowSorts) => !prevShowSorts);
	}, []);

	const [primaryThreats, setPrimaryThreats] = useState("");
	const [filterValue, setFilterValue] = useState("");
	const [sensFilter, setRadioFilter] = useState("low");
	const [searchValue, setSearchValue] = useState("");
	const [updateLoading, setUpdateLoading] = useState(false);
	const queryClient = useQueryClient();

	const processId = useSelector((state) => state.pid);
	// const { data, loading, error, fetchData } = useApi(
	//   `${baseURL}/threat-catalog/control-analysis?businessProcessId=${processId}`
	// );
	const [page, setPage] = useState(0);
	const pageSize = 10; // Number of items per page

	const {
		data,
		loading,
		error,
		refetch: fetchData,
	} = useQuery(GET_CONTROL_ANALYSIS, {
		variables: { limit: pageSize, offset: page * pageSize },
	});

	const handlePageChange = ({ selected }) => {
		setPage(selected);
		fetchData({ limit: pageSize, offset: selected * pageSize });
	};

	const totalItems = data?.client_control_analysis_final?.count || 0;

	// const { data, loading, fetchData, isError } = useApi(
	//   `${baseURL}/${CLIENT_CONTROLS_CATALOG_ENDPOINT}`
	// );

	// useEffect(() => {
	// 	fetchDatas();
	// }, []);
	// Handle dropdown selection
	// const handleDropdownSelect = (eventKey) => {
	//   setSelectedItem(eventKey);
	//   fetchData(eventKey);
	//   // fetchData(eventKey);
	// };

	// useEffect(() => {
	//             // fetchData();
	// }, [processId]);

	const [pageNumber, setPageNumber] = useState(0);

	const controlTypes = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.control_type
			)
		),
	];

	const controlDomains = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.control_domain
			)
		),
	];
	const controlCategory = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.control_category
			)
		),
	];
	const primaryThreat = [
		...new Set(
			data?.control_analysis_data?.map(
				(row) => row.client_control_catalog?.primary_threat
			)
		),
	];
	// console.log(data, "data");
	const transformedData = usePropertyTransformation(
		data?.control_analysis_data
	);

	const {
		selectedControlDomain,
		selectedControlCategory,
		selectedControlTypes,
		selectedControlApplicability,
		handleControlDomainChange,
		handleControlCategoryChange,
		handleControlTypeChange,
		handleClearSelections,
		filteredControls,
	} = useControlFilters(
		transformedData,
		controlDomains,
		controlCategory,
		controlTypes
	);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
		setPageNumber(0); // Reset to the first page when search value changes
	};

	// const filteredData = data?.control_analysis?.filter((row) =>
	// 	row?.control?.name?.match(
	// 		new RegExp(`\\b${primaryThreats}\\b`)
	// 	)
	// );
	// Handle filtering based on the selected severity
	const filteredData = data?.control_analysis_final?.filter((row) => {
		return Object.keys(row?.threat_risk_profile || {}).some(
			(key) => key === sensFilter // Check if the selected severity exists
		);
	});

	//sort table
	const { items, requestSort, sortConfig } = useSortableData(filteredControls);
	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const usersPerPage = 5;
	const pagesVisited = pageNumber * usersPerPage;
	const pageCount = Math.ceil(filteredData?.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	const [checkboxState, setCheckboxState] = useState({}); // Use an object instead of an array
	const [checkboxStateImplemented, setImplementedCheckboxState] = useState({});
	const [selectedColor, setSelectedColor] = useState(null);

	const handleColorClick = (color) => {
		setSearchValue(color);
	};

	useEffect(() => {
		if (data?.client_control_analysis_final) {
			const applicabilityState = {};
			const implementationState = {};

			// Update the state based on control id
			data?.client_control_analysis_final?.forEach((control) => {
				applicabilityState[control.id] = control.is_applicable;
				implementationState[control.id] = control.is_implemented;
			});

			setCheckboxState(applicabilityState);
			setImplementedCheckboxState(implementationState);
		}
	}, [data?.client_control_analysis_final]);

	useEffect(() => {
		if (data?.client_control_analysis_final?.threat_risk_profile) {
			setRadioFilter(
				data?.control_analysis_final.threat_risk_profile.sensFilter
			);
		}
	}, [data?.client_control_analysis_final]);

	console.log(checkboxState, "checkboxes");
	console.log(JSON.stringify(data?.client_control_analysis_final), "test");

	const refreshCalculate = async () => {
		// Display a success message
		try {
			// Fetch the data from the API and wait for the response
			// fetchData();
			// Display a success message or update the UI as needed
		} catch (error) {
			console.error("Error fetching data or setting checkbox state: ", error);
			// Handle the error, e.g., show an error message to the user
		} finally {
			// After everything is done, clear the loading indicator
		}
	};
	const [update] = useMutation(UPDATE_CONTROL_ANALYSIS_MUTATION);

	// Function to send a PATCH-like GraphQL mutation
	const updateControlProperty = async (controlId, property, updatedValue) => {
		console.log(controlId, property, updatedValue, "check");

		// Define mutation variables and conditionally add the appropriate field to the input
		const variables = { id: parseInt(controlId) };

		// Handle which field to update
		if (property === "is_applicable") {
			variables.isApplicable = updatedValue;
		} else if (property === "is_implemented") {
			variables.isImplemented = updatedValue;
		} else {
			throw new Error("Unsupported property"); // Handle unsupported properties
		}

		try {
			await update({
				variables: variables,
			});

			// console.log('Affected rows:', response..update_control_analysis_final.affected_rows);
		} catch (error) {
			console.error("Error updating control analysis:", error);
			throw error; // Handle the error as needed
		}
	};
	console.log(sensFilter, "sensetivity");
	// Function to handle checkbox click and update control
	// Event handler for checkbox changes
	const handleCheckboxChange = async (controlId, property) => {
		if (data?.client_control_analysis_final) {
			// Find the index of the control with the given id
			const index = data?.client_control_analysis_final?.findIndex(
				(control) => control?.id === controlId
			);

			if (index !== -1) {
				// Clone the state object to avoid direct mutation
				const updatedState = { ...checkboxState };

				// Update the checkbox state for the specific control
				updatedState[controlId] = !updatedState[controlId];

				// Update the state with the new checkbox state
				setCheckboxState(updatedState);

				const updatedValue = updatedState[controlId];

				try {
					await updateControlProperty(controlId, `${property}`, updatedValue);
					// If successful, you can display a success message or update the UI as needed
				} catch (error) {
					console.error("Error updating control property: ", error);
					// Handle the error, e.g., show an error message to the user
				}
			}
		}
	};

	// Event handler for checkbox changes
	const handleCheckboxChangeImplemnted = async (
		controlId,
		property,
		isImplemented
	) => {
		// Check if checkboxState is defined
		if (data?.client_control_analysis_final) {
			// Find the index of the control with the given id
			const index = data?.client_control_analysis_final?.findIndex(
				(control) => control?.id === controlId
			);

			if (index !== -1) {
				// Clone the state object to avoid direct mutation
				const updatedState = { ...checkboxStateImplemented };

				// Update the checkbox state for the specific control
				updatedState[controlId] = isImplemented
					? !updatedState[controlId]
					: isImplemented;

				// Update the state with the new checkbox state
				setImplementedCheckboxState(updatedState);

				const updatedValue = updatedState[controlId];

				try {
					await updateControlProperty(controlId, `${property}`, updatedValue);

					// fetchData();
					// If successful, you can display a success message or update the UI as needed
				} catch (error) {
					console.error("Error updating control property: ", error);
					// Handle the error, e.g., show an error message to the user
				}
			}
		}
	};
	const [selectedItem, setSelectedItem] = useState({});
	console.log(selectedItem, "data from control analysis");
	// client_control_analysis_final
	const DisplayData = data?.client_control_analysis_final
		?.filter((controls) => controls.is_applicable_by_admin === true)
		?.map((controls, index) => {
			return (
				<tr key={controls.id}>
					<td>{controls?.control?.primary_threat?.name ?? "N/A"}</td>{" "}
					{/* <td>
						{controls?.control?.secondary_threats
							?? "N/A"}
					</td>{" "} */}
					<td>{controls?.control?.control_domain?.label ?? "N/A"}</td>{" "}
					<td>{controls?.control?.control_category?.label ?? "N/A"}</td>{" "}
					<td>{controls?.control?.name ?? "N/A"}</td>
					<td>{controls?.control?.control_type?.label ?? "N/A"}</td>
					<td>{controls?.control?.relevance ?? "N/A"}</td>
					<td>{controls?.control?.effectiveness ?? "N/A"}</td>
					<td>
						<input
							id={controls.id}
							type="checkbox"
							// checked={controls.is_implemented}
							checked={checkboxState[controls.id]}
							onChange={() => {
								const newApplicableState = !checkboxState[controls.id];
								if (newApplicableState === false) {
									// Set "is_implemented" to false if "is_applicable" is unchecked
									handleCheckboxChangeImplemnted(
										controls.id,
										"is_implemented",
										false
									);
								}
								handleCheckboxChange(controls.id, "is_applicable");
							}}
						/>
					</td>
					<td>
						<input
							id={controls.id}
							type="checkbox"
							checked={checkboxStateImplemented[controls.id]}
							// checked={controls.is_applicable}
							disabled={!checkboxState[controls.id]}
							onChange={() => {
								handleCheckboxChangeImplemnted(
									controls.id,
									"is_implemented",
									true
								);
							}}
						/>
					</td>
					<td className="text-white font-semibold">
						<p
							className="p-1.5 text-center rounded-xl"
							style={{
								backgroundColor: getBackgroundColor(
									controls?.control?.primary_threat?.threat_severity[sensFilter]
								),
								color: "black",
							}}
						>
							{/* {controls?.threat_severity?.low} */}
						</p>
					</td>
					<td
						onClick={() => {
							setSelectedItem(controls);
							setIsModalOpen(true);
						}}
					>
						<a className="w-42" href="#">
							View...
						</a>
					</td>
				</tr>
			);
		});

	return (
		<div
			className="
		p-10 md:p-4"
		>
			{true ? (
				<div className="p-4  md:space-y-6">
					{/* filter */}

					{showFilters && (
						<div
							onMouseLeave={() => {
								toggleFilters();
							}}
							className={`absolute text-white z-50 bg rounded-md p-2 mt-24 `}
						>
							<div className="">
								{selectedControlTypes.length >= 1 ||
								selectedControlCategory.length >= 1 ||
								selectedControlDomain.length >= 1 ||
								selectedControlApplicability.length >= 1 ? (
									<div className="flex justify-end items-end">
										<FilterAltOffIcon
											onClick={() => {
												handleClearSelections();
												toggleFilters();
											}}
											className="cursor-pointer"
										/>{" "}
									</div>
								) : null}
								<h4>Control Domain</h4>
								{controlDomains
									?.filter((list) => list?.length > 2)
									.map((type) => (
										<div key={type}>
											<label className="mx-6 flex space-x-2 text-xl">
												<input
													type="checkbox"
													value={type}
													defaultChecked={selectedControlDomain.includes(type)}
													onChange={handleControlDomainChange}
												/>

												<span>{capitalizeFirstLetter(type)}</span>
											</label>
										</div>
									))}
								<h4>Control Types</h4>
								{controlTypes
									?.filter((list) => list?.length > 2)
									.map((type) => (
										<div key={type}>
											<label className="mx-6 flex space-x-2 text-xl">
												<input
													type="checkbox"
													value={type}
													defaultChecked={selectedControlTypes.includes(type)}
													onChange={handleControlTypeChange}
												/>
												<span>{capitalizeFirstLetter(type)}</span>
											</label>
										</div>
									))}{" "}
								{controlCategory?.length >= 2 ? (
									<div>
										<h4>Control Category</h4>
										{controlCategory
											?.filter((list) => list?.length > 1)
											.map((category) => (
												<div key={category}>
													<label className="mx-6 flex space-x-2 text-xl">
														<input
															type="checkbox"
															value={category}
															defaultChecked={selectedControlCategory.includes(
																category
															)}
															onChange={handleControlCategoryChange}
														/>
														<span>{capitalizeFirstLetter(category)}</span>
													</label>
												</div>
											))}
									</div>
								) : null}
							</div>
						</div>
					)}
					<div
						className={`flex justify-between items-center p-2 border-b border-gray-500 rounded-t-lg`}
					>
						<span className="font-bold text-white ">Control Analysis </span>
						<ColorIndicator onColorClick={handleColorClick} />
						<div className=" flex justify-center items-center space-x-4 bg-white rounded-md">
							{sensetivities?.map((items) => {
								return (
									<div className="flex justify-end items-end p-2.5 ">
										<div class=" flex justify-center items-center">
											<input
												class="form-check-input appearance-none rounded-full h-6 w-6 border border-gray-300  checked:bg-blue-600 checked:border-green-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
												type="radio"
												name="flexRadioDefault"
												id={items.name}
												checked={items.name === sensFilter}
												onClick={() => setRadioFilter(`${items.name}`)}
											/>
											<label
												class="form-check-label inline-block text-gray-800"
												for="flexRadioDefault1"
											>
												{items.name}
											</label>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className={`flex justify-between items-center p-2 `}>
						<div className="flex justify-between items-center space-x-6">
							<div className="flex justify-between items-center space-x-4">
								<svg
									onClick={() => toggleFilters()}
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="w-10 hover:bg-transparent h-10 text-white cursor-pointer"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
									/>
								</svg>
							</div>
							<SearchInput
								searchValue={searchValue}
								setSearchValue={handleInputChange}
							/>
						</div>
						<div className="flex justify-between items-center space-x-4">
							{primaryThreats?.length > 1 ? (
								<div className="flex justify-center items-center space-x-4 mr-16">
									<p className="text-white font-bold">Threat Risk Profile:</p>
									<p
										className="p-1.5 text-center rounded-xl"
										style={{
											backgroundColor: getBackgroundColor(
												filteredData?.[0]?.threat_risk_profile?.severity
											),
											color: "black",
										}}
									>
										{filteredData?.[0]?.threat_risk_profile?.value?.toFixed(2)}
									</p>
								</div>
							) : null}
							<div className="flex justify-between items-center space-x-4 ">
								<Button
									className="btn-add-new"
									onClick={() => {
										fetchData();
										// refetch();
									}}
								>
									Recalculate
								</Button>
								<div className="flex justify-end items-end ">
									<Form.Control
										as="select"
										id="threats"
										value={primaryThreats}
										onChange={(e) => {
											setPrimaryThreats(e.target.value);
										}}
									>
										<option value="">Select Primary Threat</option>
										{primaryThreat?.map((item) => (
											<option value={item} key={item}>
												{item}
											</option>
										))}
									</Form.Control>
								</div>
							</div>

							{primaryThreats?.length > 0 ? (
								<div className="flex justify-end items-end text-white">
									<FilterAltOffIcon
										onClick={() => {
											setPrimaryThreats("");
										}}
										className="cursor-pointer text-white"
									/>{" "}
								</div>
							) : null}
						</div>
					</div>

					{data?.client_control_analysis_final?.length > 0 ? (
						<div className="z-30 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
							<div className=" overflow-auto  ">
								<div className="text-black">
									<div className="">
										<table
											className={`
                     table
                   relative z-30 `}
										>
											<thead className="sticky-header ">
												<tr>
													<th scope="">Primary Threat</th>{" "}
													{/* <th >Secondary Threat</th>{" "} */}
													<th scope="col">Control Domain</th>{" "}
													<th scope="col">Control Category</th>
													<th scope="col">Control Name</th>
													<th scope="col">Control Type</th>{" "}
													<th scope="col">
														Control Relevance
														{/* <button
															type="button"
															onClick={() => requestSort("relevance")}
															className={getClassNamesFor("relevance")}
														>
															<FilterListIcon />
														</button> */}
													</th>
													<th scope="col">
														Control Effectiveness %{" "}
														{/* <button
															type="button"
															onClick={() =>
																requestSort("reduction_percentage")
															}
															className={getClassNamesFor(
																"reduction_percentage"
															)}
														>
															<FilterListIcon />
														</button> */}
													</th>
													<th scope="col">Applicability</th>
													<th scope="col">Implemented?</th>
													{/* 
											<th scope="col">Control Applicability</th>
											<th scope="col">Control Use Case</th> */}
													{primaryThreats?.length < 1 ? (
														<th scope="col">
															Contextual risk profle
															<button
																type="button"
																onClick={() => requestSort("severity_value")}
																className={getClassNamesFor("severity_value")}
															>
																<FilterListIcon />
															</button>
														</th>
													) : null}
													<th className="" scope="col">
														More Details
													</th>
												</tr>
											</thead>
											{!loading && <tbody>{DisplayData}</tbody>}
										</table>
										{loading ? <TableLoadingAnimation /> : null}
									</div>
								</div>
							</div>
							<div className="flex flex-col justify-center items-center">
								{filteredData?.length > 5 ? (
									<ReactPaginate
										previousLabel={"<"}
										nextLabel={">"}
										activeClassName={"paginationActive"}
										pageCount={pageCount}
										onPageChange={changePage}
										containerClassName={"paginationBttns"}
										previousLinkClassName={"previousBttn"}
										nextLinkClassName={"nextBttn"}
										disabledClassName={"paginationDisabled"}
									/>
								) : null}
							</div>
						</div>
					) : (
						<div className="alert alert-warning" role="alert">
							There is no control analysis data at the moment, please check
							again later once all the required files add by the admin.
						</div>
					)}
				</div>
			) : (
				<LoadingSpinner />
			)}
			<ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				size="lg"
				header="Risk Reduction"
				selectedItem={selectedItem}
			/>
		</div>
	);
};

export default ControlAnalysis;
