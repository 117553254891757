import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import Modal from "./Modal";
import SortAndFilterByHandler from "./SortAndFilterByHandler";
import TableLoadingAnimation from "./TableLoading/TableLoadingAnimation";
import { DeleteModal } from "./DeleteModal";

const CustomTable = ({
	passedExcludeColumns = [],
	filteredData,
	selectedSeverityLevel,
	filterCriteria,
	onEditClick,
	onDeleteClick,
	onCheckboxClick,
	onCheckboxChange,
	checkedItems,
	dataPerPage = 7,
	addManageColumn = true,
	addManageEditColumn = false,
	page,
	pageSize,
	totalItems,
	onPageChange,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [recordId, setRecordId] = useState("");

	const [deleteModal, setDeleteModal] = useState(false);
	const toggleDeleteModal = () => setDeleteModal(!deleteModal);

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState("asc");

	const sortedData = SortAndFilterByHandler(
		filteredData,
		filterCriteria,
		sortField,
		sortOrder
	);
	// page pagination
	const [pageNumber, setPageNumber] = useState(0);
	const pagesVisited = pageNumber * dataPerPage;

	// Use filteredData instead of data for page count
	const pageCount = Math.ceil(filteredData?.length / dataPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const [showNoDataAlert, setShowNoDataAlert] = useState(false);

	useEffect(() => {
		setShowNoDataAlert(false);
		if (filteredData && filteredData.length === 0) {
			const timer = setTimeout(() => {
				setShowNoDataAlert(true);
			}, 300);
			return () => clearTimeout(timer);
		}
	}, [filteredData]);

	// Handler for header click
	const onHeaderClick = (header) => {
		setSortField(header);
		setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
	};

	// default unwanted columns
	const defaultExcludeColumns = [
		"id",
		"created_at",
		"updated_at",
		"created",
		"updated",
		"slug",
		"headers",
		"edited",
		"frequency_of_occurrence",
		"success_rate",
		"type",
	];

	// Combining default exclude columns with the ones passed as props
	const excludeColumns = Array.from(
		new Set([...defaultExcludeColumns, ...passedExcludeColumns])
	);

	const ThreatSeverity = ({ data, selectedSeverityLevel }) => {
		// Ensure `data` exists and is an object
		if (!data || typeof data !== "object") {
			return (
				<span className="p-3 w-14 text-center rounded-xl bg-gray-400">N/A</span>
			);
		}

		// Define valid severity levels in your new structure
		const validSeverityLevels = [
			"very_low",
			"low",
			"moderate",
			"high",
			"critical",
			"medium",
		];

		// Ensure selectedSeverityLevel is valid; default to "low" if not
		const severityKey = validSeverityLevels.includes(selectedSeverityLevel)
			? selectedSeverityLevel
			: "low";

		// Extract severity state directly from `data`
		const specificSeverityState = data[severityKey] || "low"; // Default to "low" if missing

		return (
			<span className={`threat-severity-label ${specificSeverityState}`}>
				{specificSeverityState.toUpperCase()}
			</span>
		);
	};

	const [minimizedColumns, setMinimizedColumns] = React.useState([]);
	let headers = [];
	if (filteredData && filteredData.length > 0) {
		filteredData.forEach((item) => {
			headers = [...headers, ...Object.keys(item)];
		});
		// Remove duplicate headers
		headers = [...new Set(headers)];
		headers = headers.filter((header) => !excludeColumns.includes(header));
		headers = headers.map((header) => header);
		if (addManageColumn) {
			headers.push("Manage");
		}
		if (addManageEditColumn) {
			headers.push("ManageEdit");
		}
	}

	const formatNumber = (num) => {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	// for removing "_" and capitalize first letter
	const transformHeaderText = (string) => {
		return string
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	};

	// Truncate text function
	const truncateText = (data, columnName, maxLength = 30) => {
		if (data === null || data === undefined || data === "") return "N/A";
		let text = "";
		if (Array.isArray(data)) {
			text = data?.join(", ");
		} else if (typeof data === "number" && columnName !== "year") {
			text = formatNumber(data);
		} else {
			text = data?.toString();
		}
		return text?.length <= maxLength
			? text
			: text.slice(0, maxLength) + "...see more";
	};

	const toggleMinimize = (header) => {
		if (minimizedColumns.includes(header)) {
			setMinimizedColumns((prev) => prev.filter((col) => col !== header));
		} else {
			setMinimizedColumns((prev) => [...prev, header]);
		}
	};

	const handleDelete = () => {
		onDeleteClick(recordId);
		toggleDeleteModal();
	};

	return (
		<>
			{deleteModal ? (
				<DeleteModal
					handleDelete={handleDelete}
					handleModal={toggleDeleteModal}
				/>
			) : null}
			{filteredData && filteredData.length > 0 ? (
				<>
					<div className="table-wrapper">
						<table className="table">
							<thead className="table-header ">
								<tr>
									{headers.map((header, idx) => (
										<th
											onClick={() => onHeaderClick(header)}
											className="table-header-data"
										>
											{transformHeaderText(header)}

											{/* Sorting Arrows */}
											{sortField === header &&
												(sortOrder === "asc" ? (
													<span className="sort-icon"> ↑</span>
												) : (
													<span className="sort-icon"> ↓</span>
												))}
										</th>
									))}
								</tr>
							</thead>
							<tbody className="table-body">
								{sortedData
									?.slice(pagesVisited, pagesVisited + dataPerPage)
									?.map((row) => (
										<tr key={row.id || row.name}>
											{headers.map((header, idx) => {
												const content = row[header?.toLowerCase()];

												// Check if the column is minimized
												const isMinimized = minimizedColumns.includes(header);
												// Determine if the modal should open
												const shouldOpenModal =
													content !== undefined &&
													content !== null &&
													(isMinimized
														? content.toString().length > 10
														: content.toString().length > 30);

												if (header === "threat_severity") {
													return (
														<td key={idx}>
															<ThreatSeverity
																data={row[header]}
																selectedSeverityLevel={selectedSeverityLevel}
															/>
														</td>
													);
												}

												if (typeof content === "boolean") {
													return (
														<td key={idx}>
															<input
																type="checkbox"
																checked={!!checkedItems[row.id]}
																onChange={(e) =>
																	onCheckboxChange(row.id, e.target.checked)
																}
															/>
														</td>
													);
												}

												if (header === "Manage") {
													return (
														// <td
														// 	key={idx}
														// 	className=" flex justify-center items-center "
														// >
														// 	<button
														// 		className="btn-edit"
														// 		onClick={() => {
														// 			onEditClick(row.id, row);
														// 		}}
														// 	>
														// 		<EditIcon />
														// 	</button>
														// 	<DeleteIcon
														// 		onClick={() => {
														// 			setRecordId(row.id);
														// 			toggleDeleteModal();
														// 		}}
														// 		// className="btn-delete"
														// 	/>
														// </td>
														<td className="manage-icons">
															<EditIcon
																onClick={() => onEditClick(row.id, row)}
															/>
															<DeleteIcon
																onClick={() => {
																	setRecordId(row.id);
																	toggleDeleteModal();
																}}
															/>
														</td>
													);
												}
												if (header === "ManageEdit") {
													return (
														<td
															key={idx}
															className=" flex justify-center items-center "
														>
															<button
																className="btn-edit"
																onClick={() => {
																	onEditClick(row.id, row);
																	console.log(row, "row");
																}}
															>
																<EditIcon />
															</button>
														</td>
													);
												}
												return (
													<td
														key={idx}
														onClick={() => {
															if (shouldOpenModal) {
																setIsModalOpen(true);
																setModalContent(
																	Array.isArray(content)
																		? content.join(", ")
																		: content.toString()
																);
															}
														}}
													>
														{row.hasOwnProperty(header?.toLowerCase())
															? truncateText(
																	content,
																	header,
																	isMinimized ? 10 : 30
															  )
															: ""}
													</td>
												);
											})}
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<div className="table-footer paginationBttns">
						{filteredData.length > dataPerPage ? (
							<ReactPaginate
								previousLabel={"<"}
								nextLabel={">"}
								activeClassName={"paginationActive"}
								pageCount={pageCount}
								onPageChange={changePage}
								containerClassName={"paginationBttns"}
								previousLinkClassName={"previousBttn"}
								nextLinkClassName={"nextBttn"}
								disabledClassName={"paginationDisabled"}
							/>
						) : null}
					</div>
				</>
			) : showNoDataAlert ? (
				<div className="alert-no-data">No data available at the moment.</div>
			) : (
				<TableLoadingAnimation />
			)}

			<Modal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				size="sm"
			>
				<Modal.Header>
					<Modal.Title>See more...</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modalContent}</Modal.Body>
				<Modal.Footer>
					<button
						className="btn-cancel"
						onClick={() => {
							setIsModalOpen(false);
						}}
					>
						Cancel
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CustomTable;
