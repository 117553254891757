import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import http from "../../../resources/http";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import ImpactModalHandler from "./ControlModalsUntilBEupdate/ImpactModalHandler";
import { CONTROL_IMPACT } from "../../../api/excelUpload";
import {
	setImpact,
	addImpact,
	updateImpact,
	deleteImpact,
} from "../../../authentication/store/reducers/impactSlice";
import swal from "sweetalert";
import {
	ADD_CONTROL_IMPACT,
	ADD_CONTROL_USECASE,
	DELETE_CONTROL_IMPACT,
	DELETE_CONTROL_USECASE,
	UPDATE_CONTROL_IMPACT,
	UPDATE_CONTROL_USECASE,
} from "../../../graphql/superAdmin/mutation/controlConfiguration/mutation";
import { useMutation, useQuery } from "@apollo/client";
import ControlsModalHandler from "./ControlModalsUntilBEupdate/ControlsModalHandler";
import { GET_CONTROL_IMPACT, GET_CONTROL_USE_CASE } from "../../../graphql/superAdmin/query/controlConfiguration/query";
import toast from "react-hot-toast";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";
const ControlUseCase = () => {
	const url = CONTROL_IMPACT;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const applicability = useSelector((state) => state.controls.applicability);
	const dispatch = useDispatch();

	const [id, setId] = useState();
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["control_impact", "description"];
	const [page, setPage] = useState(0);

	const pageSize = 10; // Number of items per page

	const { data, loading, error, refetch } = useQuery(GET_CONTROL_USE_CASE, {
		variables: { limit: pageSize, offset: page * pageSize },
	});
	const handlePageChange = ({ selected }) => {
		setPage(selected);
		refetch({ limit: pageSize, offset: selected * pageSize });
	};
	const totalItems = data?.control_use_case_aggregate?.count || 0;

	// useEffect(() => {
	// 	          // fetchData();
	// }, []);

	const filteredData = SearchHandler(
		data?.control_use_case ?? [],
		searchValue,
		fieldsToFilterBy
	);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const [update] = useMutation(UPDATE_CONTROL_USECASE);
	const [create] = useMutation(ADD_CONTROL_USECASE);
	const [del] = useMutation(DELETE_CONTROL_USECASE);
	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			const { data } = await del({
				variables: { id: parseInt(id) },
			});

			if (data?.delete_control_use_case?.affected_rows > 0) {
				setFetchLoading(false);
				refetch();
				toast.success("It has been deleted successfully");
			} else {
			}
		} catch (error) {
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};

	return (
		<div className="main-container">
			<div className="table-title ">
				<div className="table-title-text">
					<span>Control Use Case </span>
					<button className="tooltip">
						<FaInfoCircle className="btn-info icon" />
						<span className="tooltip-text">
							This table contains Control Use Case.
						</span>
					</button>
				</div>
				<div className="flex justify-between items-center space-x-4">
					<SearchInput
						searchValue={searchValue}
						setSearchValue={handleInputChange}
					/>
					<button
						onClick={() => {
							setSelectedItem(null);
							setMode("add");
							setIsModalOpen(true);
						}}
						className="btn-add-new tooltip"
					>
						<FaPlus className="icon" />
						<span className="tooltip-text">Add New Data</span>
					</button>
				</div>
			</div>
			{/* table */}
			{fetchLoading ? (
				<LoadingComponent />
			) : searchValue.length > 0 && filteredData.length === 0 ? (
				<div className="alert-no-data">
					No data available for
					<span className="search-value">{searchValue}</span>
					at the moment.
				</div>
			) : (
				<CustomTable
					filteredData={filteredData}
					onEditClick={handleEdit}
					onDeleteClick={handleDelete}
					page={page}
					pageSize={pageSize}
					totalItems={totalItems}
					onPageChange={handlePageChange}
					passedExcludeColumns={["__typename"]}
				/>
			)}
			{/* add and edit modal */}
			<ControlsModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="xl"
				selectedItemId={id}
				selectedItem={selectedItem}
				header="Control UseCase"
				url={url}
				fetchData={refetch}
				createMutation={create}
				updateMutation={update}
			/>
		</div>
	);
};

export default ControlUseCase;
