import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import swal from "sweetalert";
import { GET_INDUSTRY_SECTOR } from "../../../graphql/superAdmin/query/query";
import { ADD_INDUSTRY_DATA, UPDATE_INDUSTRY_DATA } from "../../../graphql/superAdmin/mutation/mutation";
import toast from "react-hot-toast";


// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
  <Form.Group>
    <Form.Label>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </Form.Label>
    <select className="form-control" value={value} onChange={onChange}>
      <option value="">Select {label.toLowerCase()}...</option>
      {options?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name || item.label}
        </option>
      ))}
    </select>
    {error && <span style={{ color: "red" }}>{error}</span>}
  </Form.Group>
);

// Reusable Input Component
const Input = ({ label, type, value, onChange, error, required, placeholder }) => (
  <Form.Group>
    <Form.Label>
      {label} {required && <span style={{ color: "red" }}>*</span>}
    </Form.Label>
    <input
      className="form-control"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {error && <span style={{ color: "red" }}>{error}</span>}
  </Form.Group>
);

const ModalHandler = ({
  isOpen,
  mode,
  onClose,
  header,
  selectedItem,
  selectedItemId,
  size,
  fetchData,
}) => {
  const [formData, setFormData] = useState({
    industry_sector: "",
    year: "",
    total_incidents: "",
    total_breaches: "",
    total_cost_of_data_breach: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch industry sectors using GraphQL query
  const { data: industrySectorsData } = useQuery(GET_INDUSTRY_SECTOR, {
    variables: { limit: 100, offset: 0 },
  });

  // GraphQL Mutations
  const [addIndustryData] = useMutation(ADD_INDUSTRY_DATA);
  const [updateIndustryData] = useMutation(UPDATE_INDUSTRY_DATA);

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
  };

  const formatPostgresError = (errorMessage) => {
    const match = errorMessage.match(/\((.*?)\)=\((.*?)\)/);
    if (match) {
      const fields = match[1].split(", ");
      const values = match[2].split(", ");
      let formattedMessage = "A record with the same values already exists for: ";
      const details = fields
        .map((field, index) => `${field.replace(/_/g, " ")}: "${values[index]}"`)
        .join(", ");
      return formattedMessage + details;
    }
    return "A duplicate entry error occurred. Please check your input and try again.";
  };

  const handleSubmit = () => {
    if (isSubmitting) return; // Prevent re-submission

    const newErrors = {
      industry_sector: !formData.industry_sector ? "This field is required." : "",
      year: !formData.year ? "This field is required." : "",
      total_incidents: !formData.total_incidents ? "This field is required." : "",
      total_breaches: !formData.total_breaches ? "This field is required." : "",
      total_cost_of_data_breach: !formData.total_cost_of_data_breach
        ? "This field is required."
        : "",
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      setIsSubmitting(true); // Disable further submissions

      const inputs = {
        created_at: new Date().toISOString(),
        industry_sector_pk: formData.industry_sector,
        year: formData.year,
        total_incidents: parseInt(formData.total_incidents, 10),
        total_breaches: parseInt(formData.total_breaches, 10),
        total_cost_of_data_breach: parseFloat(formData.total_cost_of_data_breach),
      };

      if (mode === "add") {
        addIndustryData({ variables: { inputs } })
          .then(() => {
            setIsSubmitting(false); // Re-enable submissions
            onClose();
            toast.success("Success", "Industry data has been added successfully!", "success");
            fetchData();
          })
          .catch((err) => {
            setIsSubmitting(false);
            const errorMessage =
              err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
            toast.error("Error", formatPostgresError(errorMessage), "error");
            console.error("GraphQL Error:", err);
          });
      } else {
        updateIndustryData({ variables: { id: parseInt(selectedItemId), input: inputs } })
          .then(() => {
            setIsSubmitting(false);
            onClose();
            toast.success("Success", "Industry data has been updated successfully!", "success");
            fetchData();
          })
          .catch((err) => {
            setIsSubmitting(false);
            const errorMessage =
              err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
            toast.error("Error", formatPostgresError(errorMessage), "error");
            console.error("GraphQL Error:", err);
          });
      }
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        industry_sector_pk: selectedItem.industry_sector?.id || "",
        year: selectedItem.year || "",
        total_incidents: selectedItem.total_incidents || "",
        total_breaches: selectedItem.total_breaches || "",
        total_cost_of_data_breach: selectedItem.total_cost_of_data_breach || "",
      });
    } else {
      setFormData({
        industry_sector: "",
        year: "",
        total_incidents: "",
        total_breaches: "",
        total_cost_of_data_breach: "",
      });
    }
  }, [selectedItem]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === "edit" ? `Update ${header}` : `Add New ${header}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-two-columns">
          <Form>
            <Dropdown
              label="Industry Sector"
              value={formData.industry_sector}
              onChange={(e) => handleChange("industry_sector", e.target.value)}
              options={industrySectorsData?.industry_sector}
              error={errors.industry_sector}
              required
            />
            <Input
              label="Year"
              type="date"
              value={formData.year}
              onChange={(e) => handleChange("year", e.target.value)}
              error={errors.year}
              required
            />
            <Input
              label="Total Incidents"
              type="number"
              value={formData.total_incidents}
              onChange={(e) => handleChange("total_incidents", e.target.value)}
              error={errors.total_incidents}
              required
            />
            <Input
              label="Total Breaches"
              type="number"
              value={formData.total_breaches}
              onChange={(e) => handleChange("total_breaches", e.target.value)}
              error={errors.total_breaches}
              required
            />
            <Input
              label="Total Cost of Data Breach"
              type="number"
              value={formData.total_cost_of_data_breach}
              onChange={(e) => handleChange("total_cost_of_data_breach", e.target.value)}
              error={errors.total_cost_of_data_breach}
              required
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-cancel" onClick={onClose}>
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === "edit" ? "Save Changes" : "Add"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;