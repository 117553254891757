import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import http from "../../../resources/http";
import {
	REPRODUCIBILITIES,
	REPRODUCIBILITY_BY_PK,
} from "../../../api/threatCatalog";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import ModalHandler from "./ModalHandler";
import { GET_REPRODUCIBILITIES } from "../../../graphql/superAdmin/query/query";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import {
	ADD_REPRODUCIBILITY,
	DELETE_REPRODUCIBILITY,
	UPDATE_REPRODUCIBILITY,
} from "../../../graphql/superAdmin/mutation/mutation";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";

const Reproducibility = () => {
	const url_create = REPRODUCIBILITIES;
	const url_by_pk = REPRODUCIBILITY_BY_PK;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const [id, setId] = useState();
	const [datas, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["difficulty_level", "value", "description"];

	//data
	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(REPRODUCIBILITIES);
			setData(response.data);
			setFetchLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setFetchLoading(false);
		}
	}

	// useEffect(() => {
	// 	          // fetchData();
	// }, []);
	const [update] = useMutation(UPDATE_REPRODUCIBILITY);
	const [create] = useMutation(ADD_REPRODUCIBILITY);
	const [del] = useMutation(DELETE_REPRODUCIBILITY);

	const [page, setPage] = useState(0);
	const pageSize = 10; // Number of items per page

	const { data, loading, error, refetch } = useQuery(GET_REPRODUCIBILITIES, {
		variables: { limit: pageSize, offset: page * pageSize },
	});
	const handlePageChange = ({ selected }) => {
		setPage(selected);
		refetch({ limit: pageSize, offset: selected * pageSize });
	};
	const totalItems = data?.reproducibility_level?.count || 0;

	const filteredData = SearchHandler(
		data?.reproducibility_level ?? [],
		searchValue,
		fieldsToFilterBy
	);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			const { data } = await del({
				variables: { id: parseInt(id) },
			});
			if (data?.delete_reproducibility_level?.affected_rows > 0) {
				setFetchLoading(false);
				refetch();
				toast.success("Success", "It has been deleted successfully", "success");
			} else {
			}
		} catch (error) {
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};

	return (
		<div className="main-container">
			<div className="table-container">
				<div className="table-title ">
					<div className="table-title-text">
						<span>Reproducibility </span>
						<button className="tooltip">
							<FaInfoCircle className="btn-info icon" />
							<span className="tooltip-text">
								This table contains Reproducibility.
							</span>
						</button>
					</div>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
							className="btn-add-new tooltip"
						>
							<FaPlus className="icon" />
							<span className="tooltip-text">Add New Data</span>
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
						page={page}
						pageSize={pageSize}
						totalItems={totalItems}
						onPageChange={handlePageChange}
						passedExcludeColumns={["__typename"]}
					/>
				)}{" "}
			</div>
			{/* add and edit modal */}
			<ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="xl"
				selectedItemId={id}
				selectedItem={selectedItem}
				header="Reproducibility"
				url_create={url_create}
				url_by_pk={url_by_pk}
				fetchData={refetch}
				createMutation={create}
				updateMutation={update}
			/>
		</div>
	);
};

export default Reproducibility;
