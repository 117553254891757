import React from "react";
import MultiSelect from "./MultiSelect";
import CronusSelect from "./CronusSelect";
import AutocompleteInput from "./AutocompleteInput";

const Form = ({ children }) => <>{children}</>;

Form.Group = ({ children }) => <div className="form-group">{children}</div>;

Form.Label = ({ children }) => <label className="form-label">{children}</label>;

Form.Control = ({
	type,
	options,
	value,
	placeholder,
	onChange,
	onSelect,
	error,
	disabled,
	fetchSuggestions,
	...props
}) => {
	switch (type) {
		case "select":
			return (
				<CronusSelect
					options={options}
					value={value}
					onChange={onChange}
					disabled={disabled}
					{...props}
				/>
			);
		case "multi-select":
			return (
				<MultiSelect
					options={options}
					value={value}
					onChange={onChange}
					disabled={disabled}
					{...props}
				/>
			);
		case "autocomplete":
			return (
				<AutocompleteInput
					options={options}
					value={value}
					onChange={onChange}
					onSelect={onSelect}
					placeholder={placeholder}
					disabled={disabled}
					fetchSuggestions={fetchSuggestions}
					{...props}
				/>
			);
		default:
			return (
				<div className="relative">
					<input
						className={`form-control peer ${error ? "is-invalid" : ""}`}
						type={type}
						value={value}
						placeholder=" "
						onChange={onChange}
						disabled={disabled}
						{...props}
					/>
					{placeholder && (
						<label className="form-label absolute left-3 top-3 peer-placeholder-shown:top-7 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:top-2 peer-focus:text-sm peer-focus:text-[var(--color-btn-primary)] transition-all">
							{placeholder}
						</label>
					)}
				</div>
			);
	}
};

Form.Control.Feedback = ({ children, type }) => (
	<div
		className={`mt-1 ${
			type === "invalid" ? "invalid-feedback" : "valid-feedback"
		}`}
	>
		{children}
	</div>
);

export default Form;
