import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const FinancialImpactBarChart = ({ data }) => {
	const processId = useSelector((state) => state.pid);
	const breach = data?.breach_costs > 0 ? data?.breach_costs : 0;
	const incident = data?.incident_costs > 0 ? data?.incident_costs : 0;
	const formatNumberWithCommas = (value) => {
		return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};
	const chartOptions = {
		chart: {
			type: "bar",
			height: 150,
			toolbar: {
				show: false,
			},
		},
		stroke: {
			colors: ["transparent"],
			width: 10,
		},
		plotOptions: {
			bar: {
				columnWidth: "50%",
				barWidth: "80%", // Adjust the width of the bars, e.g., 80%
			},
		},
		grid: {
			show: false,
		},
		dataLabels: {
			enabled: true,
			position: "right",
			formatter: function (val) {
				return formatNumberWithCommas(val); // Format numbers with commas
			},
		},
		xaxis: {
			categories: ["", ""], // Adjust the category names
		},
		yaxis: {
			show: false, // Hide Y-axis ticked numbers and values text
			labels: {
				formatter: function (val) {
					return formatNumberWithCommas(val); // Format numbers with commas
				},
			},
		},
	};

	const chartSeries = [
		{
			name: "Cost per Breach",
			data: [breach],
			color: "#062341", // Color for Bar 1
		},
		{
			name: "Cost per Incident",
			data: [incident],
			color: "#ce9f2c", // Color for Bar 2
		},
	];
	return (
		<div>
			<ReactApexChart
				options={chartOptions}
				series={chartSeries}
				type="bar"
				height={250}
			/>
		</div>
	);
};

export default FinancialImpactBarChart;

// const FinancialImpactBarChart = ({
//   financialIncident,
//   financialBreach,
//   operationalIncident,
//   operationalBreach,
// }) => {
//   const processId = useSelector((state) => state.pid);

//   const [data, setData] = useState([]);

//   useEffect(() => {
//     http
//       .get(
//         `${baseURL}/business_process/incident-breach-costs?businessProcessId=${processId}`
//       )
//       .then((response) => {
//         setData(response.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, [processId]);
//   const formattedData = {
//     breachCosts: formatNumber(data?.breach_costs),
//     incidentCosts: formatNumber(data?.incident_costs),
//   };
//   return (
//     <div>
//       <Chart
//         type="bar"
//         width={450}
//         height={250}
//         // series={[11, 22, 6, 6, 17, 22, 11, 6]}

//         series={[
//           {
//             name: 'Breach cost',
//             data: [
//               data?.breach_costs,
//               // Math.round((operationalBreach / 1000000) * 1000) / 1000,
//             ],
//           },
//           // {
//           //   name: 'Health & Safety',
//           //   data: [0, 0],
//           // },
//           // {
//           //   name: 'Environmental',
//           //   data: [0, 0],
//           // },
//           {
//             name: 'Incident Cost',
//             data: [0, data?.incident_costs],
//           },
//         ]}
//         options={{
//           labels: ['Incident', 'Breach'],
//           colors: ['#062341', '#ce9f2c'],
//           grid: {
//             show: true, // you can either change here to disable all grids
//             xaxis: {
//               lines: {
//                 show: false, //or just here to disable only x axis grids
//               },
//             },
//             yaxis: {
//               lines: {
//                 show: false, //or just here to disable only y axis
//               },
//               labels: {
//                 show: false, // Set to false to hide tick numbers
//                 formatter: function (val) {
//                   return val.toFixed(0);
//                 },
//               },
//             },
//           },
//           chart: {
//             stacked: true,
//             toolbar: {
//               show: false,
//             },

//             zoom: {
//               enabled: true,
//             },
//             responsive: [
//               {
//                 breakpoint: 480,
//                 options: {
//                   legend: {
//                     position: 'right',
//                     offsetX: -10,
//                     offsetY: 0,
//                     fontSize: '14px',
//                     fontWeight: 'bold',
//                   },
//                 },
//               },
//             ],
//             plotOptions: {
//               bar: {
//                 horizontal: true,
//                 borderRadius: 10,
//               },
//             },
//             dataLabels: {
//               enabled: true,
//               position: 'top',
//               formatter: function (val) {
//                 return val;
//               },
//               horizontal: true,
//               offsetX: 0,
//               style: {
//                 fontSize: '10px',
//                 colors: ['#000'],
//               },
//             },

//             fill: {
//               opacity: 1,
//             },
//             lables: {
//               show: false,
//             },
//           },
//           legend: {
//             show: true,
//             position: 'right',
//             offsetY: 40,
//             fontSize: '14px',
//             fontWeight: 'bold',
//             labels: {
//               colors: #979ea5,
//             },
//           },
//           //start
//           responsive: [
//             {
//               breakpoint: 535,
//               options: {
//                 chart: {
//                   width: 350,
//                   offsetX: 18,
//                 },
//                 legend: {
//                   offsetX: 10,
//                 },
//                 dataLabels: {
//                   style: {
//                     fontSize: '10px',
//                     fontWeight: 'normal',
//                   },
//                 },
//               },
//             },
//           ],
//           //finish
//           dataLabels: {
//             enabled: true,
//             style: {
//               fontSize: '16px',
//               fontFamily: 'Helvetica, Arial, sans-serif',
//               fontWeight: 'bold',
//             },
//           },
//         }}
//       />
//     </div>
//   );
// };

// export default FinancialImpactBarChart;
