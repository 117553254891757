const ThreatSeverityLegend = () => {
	const legendItems = [
		{ label: "Very Low", color: "#90ee90", textColor: "text-green-200" },
		{ label: "Low", color: "green", textColor: "text-green-500" },
		{ label: "Moderate", color: "yellow", textColor: "text-yellow-100" },
		{ label: "High", color: "orange", textColor: "text-yellow-500" },
		{ label: "Critical", color: "maroon", textColor: "text-red-800" },
	];

	return (
		<div className="flex p-4 justify-end items-center">
			<div className="flex space-x-2">
				{legendItems.map((item, index) => (
					<div key={index} className="flex items-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className={`h-6 w-6 ${item.textColor}`}
							fill={item.color}
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth="2"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
							/>
						</svg>
						<span className="ml-1">{item.label}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default ThreatSeverityLegend;
