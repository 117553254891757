import React, { useEffect, useState } from "react";
import CustomTable from "../../../shared/CustomTable";
import http from "../../../resources/http";
import {
	COSTS_LIST,
	COSTS_CREATE,
	COSTS_BY_PK,
	UPLOAD_COSTS_EXCEL,
} from "../../../api/excelUpload";
// import { downloadExcelDataRiskTC } from "../../../utils/ExportExcelUtility";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import SearchHandler from "../../../shared/SearchHandler";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import ModalHandler from "./ModalHandler";
import { downloadExcelData } from "../../../utils/ExportExcelUtility";
import ModalFileUpload from "../../../shared/ModalFileUpload";
import { GET_COSTS,EXPORT_COSTS,IMPORT_COSTS } from "../../../graphql/superAdmin/query/query";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_COST } from "../../../graphql/superAdmin/mutation/mutation";
import toast from "react-hot-toast";

const ViewCosts = () => {
	const url_create = COSTS_CREATE;
	const url_get = COSTS_LIST;
	const url_by_pk = COSTS_BY_PK;
	const UploadURL = UPLOAD_COSTS_EXCEL;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();
	const [fetchLoading, setFetchLoading] = useState(false);

	// for file upload
	const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

	// search
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = [""];
	const debounceDelay = 400;

	const { data, loading, error, refetch: fetchData } = useQuery(GET_COSTS, {
		variables: { limit: 100 },
	});
	// useEffect(() => {
	// 	          // fetchData();
	// }, []);
	const formatted = data?.cost?.map(items => ({
		id: items?.id,
		cost_category: items.cost_category,
		threat: items?.threat?.name,
		cost_of_breach: items?.cost_of_breach,
		cost_per_incident: items?.cost_per_incident,
		percent_of_total_breach: items?.percent_of_total_breach,
		threat_event_frequency: items?.threat_event_frequency,
		lost_business_cost: items?.lost_business_cost,
	}))

	const filteredData = SearchHandler(formatted ?? [], searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const [del] = useMutation(DELETE_COST);
	const [exportCost] = useMutation(EXPORT_COSTS);	
	const [importCost] = useMutation(IMPORT_COSTS);

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			const { data } = await del({
				variables: { id: parseInt(id) },
			});

			if (data?.delete_cost?.affected_rows > 0) {
				setFetchLoading(false);
				fetchData();
				toast.success('It has been deleted successfully');
			} else {
			}
		} catch (error) {
			setFetchLoading(false);
			console.error('Failed to delete item:', error);
		}
	};

	const handleDownloadExcel = async () => {
		try {
			const { data } = await exportCost({
				variables: {
					inputs: {
						extension: "xlsx"
					} 
				}
			});

			const fileContent = data?.export_cost?.data[0]?.file_content;
			if (!fileContent) throw new Error("No file content received.");

			const fileExtension = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";

			// Convert base64 to Blob
			const byteCharacters = atob(fileContent);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: `application/${fileExtension}` });

			// Create a link element to trigger the download
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = `Costs.xlsx`;

			// Append link, trigger download, and clean up
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			toast.success("File downloaded successfully!");
		} catch (error) {
			console.error("Error downloading file:", error);
			toast.error("Failed to download file");
		}
	};
	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span>Costs</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							onClick={() => {
								setIsModalUploadOpen(true);
							}}
							className="btn_file_download"
						>
							<img
								alt="upload-avatar"
								className="w-7 h-7"
								src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
							/>
						</button>
						<button className="btn_file_download">
							<svg
								onClick={() => 
									
								  handleDownloadExcel()

								}
								xmlns="http://www.w3.org/2000/svg"
								height={28}
								width={28}
								fill-rule="evenodd"
								fill="white"
								clip-rule="evenodd"
								image-rendering="optimizeQuality"
								shape-rendering="geometricPrecision"
								text-rendering="geometricPrecision"
								viewBox="0 0 17639 17639"
							>
								<path
									fill="#1e7145"
									d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
								/>
								<path
									fill="#1e7145"
									d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
								/>
							</svg>
						</button>
						<button
							className="btn-add-new"
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
						>
							<AddIcon />
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
						passedExcludeColumns={['__typename']}
					/>
				)}
				{/* add and edit modal */}
				<ModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="xl"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="Cost"
					url_create={url_create}
					url_by_pk={url_by_pk}
					fetchData={fetchData}
				/>
			</div>

			{/* for uploading new file */}

			<ModalFileUpload
				UploadURL={UploadURL}
				fetchData={fetchData}
				importMutation={importCost}
				isOpen={isModalUploadOpen}
				onClose={() => setIsModalUploadOpen(false)}
			/>
		</main>
	);
};

export default ViewCosts;
