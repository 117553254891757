import { gql } from '@apollo/client';

export const DELETE_AVAILABILITY = gql`
  mutation DELETE_AVAILABILITY($id: Int!) {
    delete_availability_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_AVAILABILITY = gql`
  mutation UPDATE_AVAILABILITY(
    $id: Int!
    $input: availability_levelInputType!
  ) {
    update_availability_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;


export const EXPORT_CONSENSUS_ASSESSMENT = gql`
  mutation EXPORT_CONSENSUS_ASSESSMENT($inputs: ExportInputType!) {
    export_consensus_assessment(inputs: $inputs) {
      data {
        file_content
      }
      __typename
    }
  }
`;


export const IMPORT_CONSENSUS_ASSESSMENT = gql`
  mutation IMPORT_CONSENSUS_ASSESSMENT($inputs: ImportInputType!) {
    import_consensus_assessment(inputs: $inputs) {
      data {
        inserted
        updated
        deleted
        total
        skip
        messages
      }
    }
  }
`;





export const EXPORT_NIST = gql`
   
mutation EXPORT_NIST($inputs: ExportInputType!)  {
  export_nist(inputs: $inputs) {
    data {
      file_content
    }
    __typename
  }
}
`;


export const IMPORT_NIST = gql`
 mutation IMPORT_NIST($inputs: ImportInputType!) {
  import_nist(inputs: $inputs)  {
    data{
      inserted
      updated
      
    }
  }
}

`;



export const ADD_AVAILABILITY = gql`
  mutation ADD_AVAILABILITY($inputs: [availability_levelInputType]!) {
    create_availability_level(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_CONFIDENTIALITY = gql`
  mutation DELETE_CONFIDENTIALITY($id: Int!) {
    delete_confidentiality_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CONFIDENTIALITY = gql`
  mutation UPDATE_CONFIDENTIALITY(
    $id: Int!
    $input: confidentiality_levelInputType!
  ) {
    update_confidentiality_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_CONFIDENTIALITY = gql`
  mutation ADD_CONFIDENTIALITY($inputs: [confidentiality_levelInputType]!) {
    create_confidentiality_level(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const DELETE_DISCOVERY = gql`
  mutation DELETE_DISCOVERY($id: Int!) {
    delete_discovery_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_DISCOVERY = gql`
  mutation UPDATE_DISCOVERY($id: Int!, $input: discovery_levelInputType!) {
    update_discovery_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_DISCOVERY = gql`
  mutation ADD_DISCOVERY($inputs: [discovery_levelInputType]!) {
    create_discovery_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_EXPLOITABILITY = gql`
  mutation DELETE_EXPLOITABILITY($id: Int!) {
    delete_exploitable_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_EXPLOITABILITY = gql`
  mutation UPDATE_EXPLOITABILITY(
    $id: Int!
    $input: exploitable_levelInputType!
  ) {
    update_exploitable_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_EXPLOITABILITY = gql`
  mutation ADD_EXPLOITABILITY($inputs: [exploitable_levelInputType]!) {
    create_exploitable_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_INTEGRITY = gql`
  mutation DELETE_INTEGRITY($id: Int!) {
    delete_integrity_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_INTEGRITY = gql`
  mutation UPDATE_INTEGRITY($id: Int!, $input: integrity_levelInputType!) {
    update_integrity_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_INTEGRITY = gql`
  mutation ADD_INTEGRITY($inputs: [integrity_levelInputType]!) {
    create_integrity_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_REPRODUCIBILITY = gql`
  mutation DELETE_REPRODICIBILITY($id: Int!) {
    delete_reproducibility_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_REPRODUCIBILITY = gql`
  mutation UPDATE_REPRODICIBILITY($id: Int!, $input: reproducibility_levelInputType!) {
    update_reproducibility_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_REPRODUCIBILITY = gql`
  mutation ADD_REPRODICIBILITY($inputs: [reproducibility_levelInputType]!) {
    create_reproducibility_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
//industry
export const DELETE_INDUSTRY = gql`
  mutation DELETE_INDUSTRY($id: Int!) {
    delete_industry_sector(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_INDUSTRY = gql`
  mutation UPDATE_INDUSTRY($id: Int!, $input: industry_sectorInputType!) {
    update_industry_sector(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_INDUSTRY = gql`
  mutation ADD_INDUSTRY($inputs: [industry_sectorInputType]!) {
    create_industry_sector(inputs: $inputs) {
      affected_rows
    }
  }
`;
//industry
export const DELETE_AT_MAPPING = gql`
  mutation DELETE_AT_MAPPING($id: Int!) {
    delete_asset_threat_mapping(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_AT_MAPPING = gql`
  mutation UPDATE_AT_MAPPING($id: Int!, $input: asset_threat_mappingInputType!) {
    update_asset_threat_mapping(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;

export const ADD_AT_MAPPING = gql`
  mutation ADD_AT_MAPPING($inputs: [asset_threat_mappingInputType]!) {
    create_asset_threat_mapping(inputs: $inputs) {
      affected_rows
    }
  }
`;

//industry
export const DELETE_ASSET_LIST = gql`
  mutation DELETE_AT_ASSET_LIST($id: Int!) {
    delete_assets(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_ASSET_LIST = gql`
  mutation UPDATE_AT_MAPPING($id: Int!, $input: assetsInputType!) {
    update_assets(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;

export const ADD_ASSET_LIST = gql`
  mutation ADD_AT_MAPPING($inputs: [assetsInputType]!) {
    create_assets(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const ADD_CONTROL_CATALOG = gql`
  mutation ADD_CONTROL_CATALOG($inputs: [controlInputType]!) {
    create_control(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const ADD_SENSITIVITY_LEVEL = gql`
  mutation ADD_SENSITIVITY_LEVEL($inputs: [risk_sensitivity_levelInputType]!) {
    create_risk_sensitivity_level(inputs: $inputs) {
      affected_rows
    }
  }
`;


export const UPDATE_SENSITIVITY_LEVEL = gql`
  mutation UPDATE_SENSITIVITY_LEVEL($id: Int!, $input: risk_sensitivity_levelInputType!) {
    update_risk_sensitivity_level(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const DELETE_SENSITIVITY_LEVEL = gql`
  mutation DELETE_SENSITIVITY_LEVEL($id: Int!) {
    delete_risk_sensitivity_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;



export const DELETE_CONTROL_CATELOG = gql`
mutation DELETE_CONTROL_CATELOG($id: Int!) {
    delete_control(where: { id: { exact: $id } }) {
      affected_rows
      }
      }
`;
export const ADD_THREAT_RISK_MAPPING = gql`
  mutation ADD_THREAT_RISK_MAPPING($inputs: [threat_risk_mappingInputType]!) {
    create_threat_risk_mapping(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const UPDATE_THREAT_RISK_MAPPING = gql`
  mutation UPDATE_THREAT_RISK_MAPPING($id: Int!, $input: threat_risk_mappingInputType!) {
    update_threat_risk_mapping(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;

export const DELETE_COST = gql`
mutation DELETEL_COST($id: Int!) {
    delete_cost(where: { id: { exact: $id } }) {
      affected_rows
      }
      }
`;

export const IMPORT_COST = gql`
  mutation IMPORT_COST($inputs: ImportInputType!) {
    import_cost(inputs: $inputs) {
      data {
        inserted
        updated
        deleted
        total
        skip
        messages
      }
    }

  }
`;


export const EXPORT_COST = gql`
  mutation EXPORT_COST($inputs: ExportInputType!) {
    export_cost(inputs: $inputs) {
      data {
        file_content
      }
      __typename
    }
  }
`;  




export const ADD_COST = gql`
  mutation ADD_THREAT_RISK_MAPPING($inputs: [costInputType]!) {
    create_cost(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const UPDATE_COST = gql`
  mutation UPDATE_COST($id: Int!, $input: costInputType!) {
    update_cost(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;


export const UPDATE_CONSENSUS_ASSESSMENT = gql`
  mutation UPDATE_CONSENSUS_ASSESSMENT($id: Int!, $input: consensus_assessmentInputType!) {
    update_consensus_assessment(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const DELETE_CONSENSUS_ASSESSMENT = gql`
mutation DELETEL_CONSENSUS_ASSESSMENT($id: Int!) {
    delete_consensus_assessment(where: { id: { exact: $id } }) {
      affected_rows
      }
      }
`;


export const ADD_CONSENSUS_ASSESSMENT = gql`
  mutation ADD_CONSENSUS_ASSESSMENT($inputs:  [consensus_assessmentInputType]!) {
    create_consensus_assessment(inputs: $inputs) {
      affected_rows
    }
  }
`;



export const UPDATE_INDUSTRY_DATA = gql`
  mutation UPDATE_INDUSTRY_DATA($id: Int!, $input: industry_dataInputType!) {
    update_industry_data(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const DELETE_INDUSTRY_DATA = gql`
mutation DELETEL_INDUSTRY_DATA($id: Int!) {
    delete_industry_data(where: { id: { exact: $id } }) {
      affected_rows
      }
      }
`;

export const IMPORT_INDUSTRY_DATA = gql`
  mutation IMPORT_INDUSTRY_DATA($inputs: ImportInputType!) {
    import_industry_data(inputs: $inputs) {
    data {
      inserted
      updated
      skip
      

    }

    }
  }
`;


export const EXPORT_INDUSTRY_DATA = gql`
  mutation EXPORT_INDUSTRY_DATA($inputs: ExportInputType!) {
    export_industry_data(inputs: $inputs) {
      data {
        file_content
      }
      __typename
    }
  }
`;

export const ADD_INDUSTRY_DATA = gql`
  mutation ADD_INDUSTRY_DATA($inputs:  [industry_dataInputType]!) {
    create_industry_data(inputs: $inputs) {
      affected_rows
    }
  }
`;







export const UPDATE_NIST = gql`
  mutation UPDATE_NIST($id: Int!, $input: nistInputType!) {
    update_nist(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const DELETE_NIST = gql`
mutation DELETEL_NIST($id: Int!) {
    delete_nist(where: { id: { exact: $id } }) {
      affected_rows
      }
      }
`;

export const ADD_NIST = gql`
  mutation ADD_NIST($inputs:  [nistInputType]!) {
    create_nist(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const UPDATE_RESPONSE_DISTRIBUTION = gql`
  mutation UPDATE_RESPONSE_DISTRIBUTION($id: Int!, $input: response_distributionInputType!) {
    update_response_distribution(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const DELETE_RESPONSE_DISTRIBUTION = gql`
mutation DELETEL_RESPONSE_DISTRIBUTION($id: Int!) {
    delete_response_distribution(where: { id: { exact: $id } }) {
      affected_rows
      }
      }
`;

export const ADD_RESPONSE_DISTRIBUTION = gql`
  mutation ADD_RESPONSE_DISTRIBUTION($inputs:  [response_distributionInputType]!) {
    create_response_distribution(inputs: $inputs) {
      affected_rows
    }
  }
`;





