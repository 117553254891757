import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import Modal from "../../shared/Modal";
import Form from "../../shared/Form";
import LoadingComponent from "../../shared/LoadingDropDownSelection/LoadingComponent";
import { CREATE_CLIENT_ASSET, UPDATE_CLIENT_BIA } from "../../graphql/client/query";
import { GET_ASSET_LIST } from "../../graphql/superAdmin/query/controlConfiguration/query";
import toast from "react-hot-toast";
import { SEARCH_PRODUCTS_BY_VENDOR } from "../../graphql/superAdmin/query/query";

const ModalHandler = ({
	isOpen,
	onClose,
	mode,
	size,
	selectedItem,
	fetchData,
	selectedClientId,
	selectedBusinessProcessId,
}) => {
	// State management
	const [formData, setFormData] = useState({
		vendor: "",
		product: "",
		version: "",
		assetName: "",
		hierarchy: "",
		businessAssets: "",
		regulation: "",
		assetType: "",
		assetRisk: "",
		assetCategorization: "",
		business_process: selectedBusinessProcessId,
		client: selectedClientId,
	});
	const [errors, setErrors] = useState({});
	const [isSearching, setIsSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [versionOptions, setVersionOptions] = useState([]);
	console.log(productOptions, "productOptions")
	// GraphQL queries
	const [searchProducts] = useLazyQuery(SEARCH_PRODUCTS_BY_VENDOR);
	const { data: assetData } = useQuery(GET_ASSET_LIST, { variables: { limit: 100 } });
	const [createClientAsset] = useMutation(CREATE_CLIENT_ASSET);
	const [updateClientAsset] = useMutation(UPDATE_CLIENT_BIA);

	// Initialize form for edit mode
	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			setFormData({
				vendor: selectedItem?.vendor || "",
				product: selectedItem?.product || "",
				version: selectedItem?.version || "",
				assetName: selectedItem?.name || "",
				hierarchy: selectedItem?.hierarchy || "",
				businessAssets: selectedItem?.business_asset || "",
				regulation: selectedItem?.regulations || "",
				assetType: selectedItem?.asset_type || "",
				assetRisk: selectedItem?.risk?.toLowerCase() || "",
				assetCategorization: selectedItem?.asset_categorization || "",
				business_process: selectedBusinessProcessId,
				client: selectedClientId,
			});
		}
	}, [selectedItem, mode, selectedBusinessProcessId, selectedClientId]);

	// Handle vendor search
	const handleSearch = async () => {
		if (!formData.vendor.trim()) {
			toast.error("Please enter a vendor name");
			return;
		}

		setIsSearching(true);
		try {
			const { data } = await searchProducts({
				variables: { vendor: formData.vendor.toLowerCase() }
			});

			if (data?.search_products?.length > 0) {
				setSearchResults(data.search_products);

				// Extract unique product names
				const uniqueProducts = [...new Set(
					data.search_products.map(item => item.product)
				)];
				setProductOptions(uniqueProducts);
				console.log(data.search_products, "list")

				toast.success(`Found ${data.search_products.length} products`);
			} else {
				toast.error("No products found for this vendor");
				setSearchResults([]);
				setProductOptions([]);
				setVersionOptions([]);
			}
		} catch (error) {
			console.error("Search error:", error);
			toast.error("Failed to search products");
		} finally {
			setIsSearching(false);
		}
	};

	// Handle product selection
	const handleProductChange = (product) => {
		setFormData(prev => ({ ...prev, product, version: "" }));

		// Filter versions for selected product
		const versions = searchResults
			.filter(item => item.product === product)
			.map(item => item.version);

		setVersionOptions(versions);
	};

	// Handle version selection
	const handleVersionChange = (version) => {
		const selectedItem = searchResults.find(
			item => item.product === formData.product && item.version === version
		);

		setFormData(prev => ({
			...prev,
			version,
			cpe: selectedItem?.cpe || ""
		}));
	};

	// Handle form input changes
	const handleChange = (key, value) => {
		setFormData(prev => ({ ...prev, [key]: value }));
		setErrors(prev => ({ ...prev, [key]: "" }));
	};

	// Form validation
	const validateForm = () => {
		const newErrors = {};
		if (!formData.vendor) newErrors.vendor = "Vendor is required";
		if (!formData.product) newErrors.product = "Product is required";
		if (!formData.version) newErrors.version = "Version is required";
		if (!formData.assetName) newErrors.assetName = "Asset name is required";

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	// Format CPE based on selections
	const formatCPE = () => {
		if (formData.cpe) return formData.cpe;

		const part = formData.assetType === "Operating System" ? 'o' :
			formData.assetType === "Hardware" ? 'h' : 'a';

		return `cpe:2.3:${part}:${formData.vendor}:${formData.product}:${formData.version}:*:*:*:*:*:*:*`;
	};

	// Form submission
	const handleSubmit = async () => {
		if (!validateForm()) return;

		try {
			const inputData = {
				created_at: new Date().toISOString().split("T")[0],
				name: formData.assetName,
				cpe: formatCPE(),
				product: formData.product,
				version: formData.version,
				vendor: formData.vendor,
				risk: formData.assetRisk,
				hierarchy: formData.hierarchy,
				client_pk: selectedClientId,
				business_process_pk: selectedBusinessProcessId,
				asset_categorization: formData.assetCategorization,
				asset_type: formData.assetType,
				business_asset: formData.businessAssets,
				regulations: formData.regulation
			};

			if (mode === "add") {
				const { data } = await createClientAsset({ variables: { inputs: inputData } });
				if (data?.create_client_asset?.affected_rows > 0) {
					toast.success("Asset added successfully!");
					fetchData();
					onClose();
				}
			} else {
				const { data } = await updateClientAsset({
					variables: {
						id: parseInt(selectedItem?.id),
						input: inputData
					}
				});
				if (data?.update_client_asset?.affected_rows > 0) {
					toast.success("Asset updated successfully!");
					fetchData();
					onClose();
				}
			}
		} catch (error) {
			console.error("Submission error:", error);
			toast.error(error.message || "Failed to submit asset");
		}
	};

	// Form fields configuration
	const formFieldsConfig = {
		// vendor: {
		// 	label: "Vendor",
		// 	type: "text",
		// 	placeholder: "Enter vendor name...",
		// 	value: formData.vendor,
		// 	onChange: (e) => handleChange("vendor", e.target.value),
		// 	error: errors.vendor,
		// 	action: {
		// 		label: "Search",
		// 		onClick: handleSearch,
		// 		loading: isSearching
		// 	}
		// },
		product: {
			label: "Product",
			type: "select",
			placeholder: "Select a product...",
			options: productOptions,
			value: formData.product,
			onChange: (e) => handleProductChange(e.target.value),
			error: errors.product,
			disabled: !formData.vendor || searchResults.length === 0,
		},
		version: {
			label: "Version",
			type: "select",
			placeholder: "Select a version...",
			options: versionOptions,
			value: formData.version,
			onChange: (e) => handleVersionChange(e.target.value),
			error: errors.version,
			disabled: !formData.product,
		},
		assetName: {
			label: "Asset Name",
			type: "text",
			placeholder: "Enter asset name...",
			value: formData.assetName,
			onChange: (e) => handleChange("assetName", e.target.value),
			error: errors.assetName,
		},
		assetType: {
			label: "Asset Type",
			type: "select",
			placeholder: "Select asset type...",
			options: ["Application", "Operating System", "Hardware"],
			value: formData.assetType,
			onChange: (e) => handleChange("assetType", e.target.value),
			error: errors.assetType,
		},
		assetCategorization: {
			label: "Asset Categorization",
			type: "select",
			placeholder: "Select...",
			options: ["Public", "Confidential", "Sensitive"],
			value: formData.assetCategorization,
			onChange: (e) => handleChange("assetCategorization", e.target.value),
			error: errors.assetCategorization,
		},
		assetRisk: {
			label: "Asset Risk",
			type: "select",
			placeholder: "Select...",
			options: ["low", "medium", "high"],
			value: formData.assetRisk,
			onChange: (e) => handleChange("assetRisk", e.target.value),
			error: errors.assetRisk,
		},
		regulation: {
			label: "Regulations",
			type: "select",
			placeholder: "Select...",
			options: ["LGPD",
				'CCPA',
				"NYDFS",
				"SOX"],
			value: formData.regulation,
			onChange: (e) => handleChange("regulation", e.target.value),
			error: errors.regulation,
		},
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update Asset` : `Add New Assetss`}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="vendor-search-section">
					<div className="">
						<label htmlFor="vendor">Vendor</label>
						<div className="flex justify-center items-center">
							<input
								type="text"
								id="vendor"
								placeholder="Enter vendor name..."
								value={formData.vendor}
								onChange={(e) => handleChange("vendor", e.target.value)}
								className="text-gray-900 w-96"
							/>
							<button
								className="btn-search"
								onClick={handleSearch}
								disabled={isSearching}
							>
								{isSearching ? "Searching..." : "Search"}
							</button>
						</div>
						{errors.vendor && (
							<div className="invalid-feedback">{errors.vendor}</div>
						)}
					</div>
				</div>
				<div className="modal-two-columns">
					<Form>
						{Object.entries(formFieldsConfig).map(([key, config]) => (
							<Form.Group key={key}>
								<Form.Label>{config.label}</Form.Label>
								<Form.Control {...config} />
								{config.error && (
									<Form.Control.Feedback type="invalid">
										{config.error}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						))}
					</Form>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<button className="btn-cancel" onClick={onClose}>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === 'edit' ? 'Save Changes' : 'Add Asset'}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;