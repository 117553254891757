import React, { useEffect, useState } from "react";
import CustomTable from "../../../shared/CustomTable";
import http from "../../../resources/http";
import {
	RESPONSE_DISTRIBUTION_LIST,
	RESPONSE_DISTRIBUTION_BY_PK,
} from "../../../api/excelUpload";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import SearchHandler from "../../../shared/SearchHandler";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import ModalHandler from "./ModalHandler";
import { GET_RESPONSE_DISTRIBUTION } from "../../../graphql/superAdmin/query/query";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { DELETE_RESPONSE_DISTRIBUTION } from "../../../graphql/superAdmin/mutation/mutation";

const ViewResponseDistribution = () => {
	const UploadUrl = "/excel-upload/upload-response-distribution-excel/";
	const url_get = RESPONSE_DISTRIBUTION_LIST;
	const url_by_pk = RESPONSE_DISTRIBUTION_BY_PK;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();
	const [fetchLoading, setFetchLoading] = useState(false);

	// search
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = [""];
	const debounceDelay = 400;
	const [page, setPage] = useState(0);
	const pageSize = 10; // Number of items per page

	const { data, loading, error, refetch: fetchData } = useQuery(GET_RESPONSE_DISTRIBUTION, {
		variables: { limit: pageSize, offset: page * pageSize },
	});
	console.log(data, "controls data")

	const handlePageChange = ({ selected }) => {
		setPage(selected);
		fetchData({ limit: pageSize, offset: selected * pageSize });
	};
	const filteredData = SearchHandler(data?.response_distribution ?? [], searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};

	console.log(JSON.stringify(data));
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};
	const [deleteRecord] = useMutation(DELETE_RESPONSE_DISTRIBUTION)

    const handleDelete = async (itemId) => {
        setFetchLoading(true);
        try {
            const { data } = await deleteRecord({
                variables: { id: parseInt(itemId) },
            });
            if (data?.delete_response_distribution?.affected_rows > 0) {
                setFetchLoading(false);
                fetchData();
                toast.success("It has been deleted successfully");
            } else {
                throw new Error(data?.delete_response_distribution?.message);
            }
        } catch (error) {
            console.error("Failed to delete item:", error);
            setFetchLoading(false);
            toast.error("Failed to delete item");
            fetchData();
        }
    };
	// Check if there are distinct "incident" and "breach" records
	const hasIncident = data?.response_distribution?.some((record) => record.attack_type === "incident");
	const hasBreach = data?.response_distribution?.some((record) => record.attack_type === "breach");

	// Create a boolean variable indicating if both incident and breach are present
	const hasBoth = hasIncident && hasBreach;
	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span className="font-bold ">Response Distribution</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						{hasBoth ? (
							<button
								className="btn-add-new"
								onClick={() => {
									setSelectedItem(null);
									setMode("add");
									setIsModalOpen(true);
								}}
							>
								<AddIcon />
							</button>
						) : null}
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
						passedExcludeColumns={['__typename']}
					/>
				)}
				{/* add and edit modal */}
				<ModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="xl"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="Availability"
					url_create={url_get}
					url_by_pk={url_by_pk}
					fetchData={fetchData}
				/>
			</div>
		</main>
	);
};

export default ViewResponseDistribution;
