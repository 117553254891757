import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import http from "../resources/http";
import FinancialImpactBarChart from "../Charts/ThreatProfiling/FinancialImpactBarChart";
import TopAttackVector from "../Charts/ThreatProfiling/TopAttackVector";
import CurrentIndustryThreats from "../Charts/ThreatProfiling/CurrentIndustryThreats";
import { useSelector } from "react-redux";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { baseURL } from "../resources/apiClient";
import NoDataToShow from "../utils/NoDataToShow";
import LoadingSpinner from "../utils/LoadingSpinner";
import { Modal } from "react-bootstrap";
import { Typography } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import RiskScore from "../Charts/ThreatProfiling/RiskScore";
import ExploitablePaths from "./ExploitablePaths";
import { sensetivities } from "../data/data";
import { useHandleTheme } from "../hooks/useHandleTheme";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ResponseDistributionHeatMap from "../Charts/ControlAnalysis/VulnerabilityHeatMap";
import { GET_THREAT_PROFILING } from "../graphql/client/query";
import { useQuery } from "@apollo/client";
const ThreatProfiling = () => {
	const [path, setPath] = useState([]);
	const [risk, setRisk] = useState([]);
	const processId = useSelector((state) => state.pid);
	const clientId = useSelector((state) => state.id);
	let type = JSON.parse(localStorage.getItem("cyber-minds"));
	const processName = useSelector((state) => state.name);
	const [loading, setLoading] = useState(true);
	const [showMore, setShowMore] = useState(false);
	const toggleMore = () => setShowMore(!showMore);
	const [test, setTest] = useState([]);
	const [data, setData] = useState([]);

	const [sensFilter, setRadioFilter] = useState("High");

	var initialScore = risk?.initial_average_risk_score?.value; //100
	var currentScore = risk?.average_risk_score?.value; //10

	var reductionScore = initialScore - currentScore;
	var percentageReductionScore = (
		(reductionScore / initialScore) *
		100
	)?.toFixed(1);
	const {
		data: threatProfiling,
		loading: loadingThreatPROFILING,
		error,
		refetch,
	} = useQuery(GET_THREAT_PROFILING, {
		variables: { business_process_id: parseInt(processId) ?? 0 },
	});

	return (
		<div className=" dark:bg-gray-900 relative">
			{!threatProfiling ? (
				<div>
					<div className="overflow-x-hidden flex flex-col ">
						<div className="">
							<div className="p-10 grid place-items-center sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3  2xl:grid-cols-3  gap-4">
								<div className="flex flex-col h-full w-full justify-evenly relative overflow-hidden rounded-lg shadow-2xl">
									<div className="dark:bg-gray-800 bg h-auto  text-xl rounded-t-lg font-bold p-2 flex  items-center">
										<div class="flex flex-col ">
											<div class="flex items-center space-x-2">
												<p>Potential Exploitable Paths</p>{" "}
												<p class="text-xs">
													<svg
														class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium text-3xl cursor-pointer css-vubbuv"
														focusable="false"
														aria-hidden="true"
														viewBox="0 0 24 24"
														data-testid="HelpOutlineIcon"
													>
														<path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
													</svg>
												</p>
											</div>
										</div>
									</div>
									<div class="flex justify-center items-center flex-col mt-2   h-full text-6xl rounded-b-lg  p-3">
										<span class="inline-block  space-x-2  text-6xl ">
											<span class="">
												{" "}
												{path.incidents_count + "/" + path.breaches_count}
											</span>
										</span>
									</div>
									<div
										style={{ borderBottom: "4px solid #273c63" }}
										className="rounded-lg flex flex-col w-full justify-evenly shadow"
									>
										<div className="  dark:bg-gray-100 text-xl flex justify-evenly items-center rounded-md">
											<div className="mt-1">
												<p>Breach Paths/Incident Paths</p>
											</div>
										</div>
									</div>
								</div>

								<div className="flex flex-col h-full w-full justify-evenly relative overflow-hidden rounded-lg shadow-2xl">
									<div className="dark:bg-gray-800 bg h-auto  text-xl rounded-t-lg font-bold p-2 flex  items-center">
										<div className="relative">
											<div className="flex items-center space-x-2 w-full">
												<p>Frequency of Occurrence</p>
												<p className="text-xs ">
													<Tooltip
														className="text-3xl cursor-pointer"
														title={
															<Typography fontSize={15}>
																Number assets and vendors
															</Typography>
														}
														placement="top"
													>
														<HelpOutline />
													</Tooltip>
												</p>
											</div>
										</div>
									</div>

									<div className="flex flex-col justify-center items-center border-gray-200 h-full rounded-b-lg pl-3 pt-3">
										<span className="text-5xl p-2.5">
											{risk?.frequencyOfOccurrence}
										</span>
									</div>
									<div
										style={{ borderBottom: "4px solid #273c63" }}
										className="rounded-lg flex flex-col w-full justify-evenly shadow"
									>
										<div className=" bg-gray-100 text-xl flex justify-evenly items-center rounded-md">
											{" "}
											<div className="mt-1">
												<p className="">
													Initial Value:{" "}
													{risk?.initialFrequencyOfOccurrence?.toUpperCase()}
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="flex flex-col h-full w-full justify-evenly relative overflow-hidden rounded-lg shadow-2xl">
									<div className="bg dark:bg-gray-800 h-auto  text-xl rounded-t-lg font-bold p-2 flex  items-center">
										<div className="relative">
											<div className="flex items-center space-x-2">
												<p>Contextual Risk Score </p>
												<p className="text-xs ">
													<Tooltip
														className="text-3xl cursor-pointer"
														title={
															<Typography fontSize={15}>
																Number assets and vendors
															</Typography>
														}
														placement="top"
													>
														<HelpOutline />
													</Tooltip>
												</p>
											</div>
										</div>
									</div>

									<div>
										<RiskScore data={risk} />
									</div>
									<div
										style={{ borderBottom: "4px solid #273c63" }}
										className="bg-gray-100 rounded-lg flex flex-col w-full justify-evenly shadow"
									>
										<div className=" bg-gray-100 mx-4 text-xl flex justify-between items-center rounded-md">
											{" "}
											<div className="mt-1">
												<p>
													Initially:
													{risk?.initial_average_risk_score?.severity?.toUpperCase()}
												</p>
											</div>
											<div className="mt-1">
												<p>
													{risk?.average_risk_score?.severity?.toUpperCase()}
												</p>
											</div>
											<div className="flex mt-2">
												<ArrowDropDownIcon className="h-10  text-red-500 w-10" />
												<p className="">{percentageReductionScore + "%"}</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="p-10 z-10 grid place-items-center  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-2  gap-4">
								<div className=" rounded-lg  border-gray-200 relative overflow-hidden  w-full h-full shadow-2xl">
									<div className=" flex flex-col pb-4">
										<div className="dark:bg-gray-800 bg-gray-600 text-white pl-4    font-semibold h-10   flex space-x-1 items-center">
											<span>Response Distribution</span>
										</div>
										<div className="w-full h-full   flex justify-center items-center">
											<ResponseDistributionHeatMap />
										</div>
									</div>
								</div>

								<div className=" rounded-lg  border-gray-200 relative overflow-hidden  w-full h-full shadow-2xl">
									<div className=" flex flex-col pb-4">
										<div className="dark:bg-gray-800 bg-gray-600 text-white pl-4    font-semibold h-10   flex space-x-1 items-center">
											<span>Financial Impacts</span>
											<span className="  text-sm cursor-pointer">
												<Tooltip
													className="text-3xl cursor-pointer"
													title={
														<Typography fontSize={15}>
															Number assets and vendors
														</Typography>
													}
													placement="top"
												>
													<HelpOutline />
												</Tooltip>
											</span>
										</div>
										<div className="w-full  flex justify-center items-center">
											<FinancialImpactBarChart data={data} />{" "}
										</div>
									</div>
								</div>
							</div>
							<div className="px-10 grid place-items-center sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1  2xl:grid-cols-1  gap-4">
								<div className="flex flex-col h-full w-full justify-evenly relative overflow-hidden rounded-lg shadow-2xl">
									<div className="bg dark: bg-gray-800  h-auto  text-xl rounded-t-lg font-bold p-2 flex  items-center">
										<div className="flex flex-col ">
											<div className="flex items-center space-x-2">
												<p>Exploitable Paths</p>{" "}
												<p className="text-xs">
													<Tooltip
														className="text-3xl cursor-pointer"
														title={
															<Typography fontSize={15}>
																Number assets and vendors
															</Typography>
														}
														placement="top"
													>
														<HelpOutline />
													</Tooltip>
												</p>
											</div>
										</div>
									</div>
									<div className=" border-gray-200  h-full">
										<div className=" ">
											<div className="p-4 ">
												<ExploitablePaths />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div>
					{loading ? (
						<div>
							<LoadingSpinner />
						</div>
					) : (
						<NoDataToShow />
					)}
				</div>
			)}
			<Modal show={showMore} className="mt-1">
				<Modal.Header closeButton onClick={() => toggleMore()}>
					<Modal.Title>More Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>More details here.</p>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ThreatProfiling;
