import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
	GET_THREAT_CATEGORIES,
	GET_DISCOVERABILITY_LEVELS,
	GET_EXPLOITABILITY_LEVELS,
	GET_REPRODUCIBILITIES,
	GET_AVAILABILITY_LEVELS,
	GET_INTEGRITY_LEVELS,
	GET_CONFIDENTIALITY_LEVELS,
	ADD_THREAT_VECTOR,
	UPDATE_THREAT_VECTOR,
} from "../../graphql/superAdmin/query/query";

import swal from "sweetalert";
import Form from "../Form";
import Modal from "../Modal";
import toast from "react-hot-toast";

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: "red" }}>*</span>}
		</Form.Label>
		<select className="form-control" value={value} onChange={onChange}>
			<option value="">Select {label.toLowerCase()}...</option>
			{options?.map((item) => (
				<option key={item.id} value={item.id}>
					{item.difficulty_level || item.name || item.category}
				</option>
			))}
		</select>
		{error && <span style={{ color: "red" }}>{error}</span>}
	</Form.Group>
);

// Reusable Input Component
const Input = ({
	label,
	type,
	value,
	onChange,
	error,
	required,
	placeholder,
}) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: "red" }}>*</span>}
		</Form.Label>
		<input
			className="form-control"
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
		/>
		{error && <span style={{ color: "red" }}>{error}</span>}
	</Form.Group>
);

const ThreatRepositoryModal = ({
	addThreat,
	updateThreat,
	isOpen,
	mode,
	onClose,
	header,
	size,
	fetchData,
	selectedItem,
}) => {
	const [formData, setFormData] = useState({
		name: "",
		threatCategory: "",
		discoverability: "",
		exploitability: "",
		reproducibility: "",
		confidentiality: "",
		availability: "",
		integrity: "",
		threatVectorIncident: "",
		costOfIncident: "",
		costOfBreach: "",
		percentOfBreach: "",
		lostOfBusinessCost: "",
		threatDescription: "",
		threatScenario: "",
	});

	const [errors, setErrors] = useState({});

	// Fetch data for dropdowns
	const { data: categoriesData } = useQuery(GET_THREAT_CATEGORIES, {
		variables: { limit: 10 },
	});
	const { data: discoverData } = useQuery(GET_DISCOVERABILITY_LEVELS, {
		variables: { limit: 10 },
	});
	const { data: exploitData } = useQuery(GET_EXPLOITABILITY_LEVELS, {
		variables: { limit: 10 },
	});
	const { data: reproduceData } = useQuery(GET_REPRODUCIBILITIES, {
		variables: { limit: 10 },
	});
	const { data: availableData } = useQuery(GET_AVAILABILITY_LEVELS, {
		variables: { limit: 10 },
	});
	const { data: integritiesData } = useQuery(GET_INTEGRITY_LEVELS, {
		variables: { limit: 10 },
	});
	const { data: confidentData } = useQuery(GET_CONFIDENTIALITY_LEVELS, {
		variables: { limit: 10 },
	});

	const handleChange = (key, value) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
		if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
	};

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Threat Vector Name is required.';
    // if (!formData.threatCategory) newErrors.threatCategory = 'Threat Category is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
	const handleSubmit = async () => {
		if (!validateForm()) return;
    
		const inputs = {
			created_at: new Date().toISOString(),
			name: formData.name,
			description: formData.threatDescription,
			discovery_level_pk: parseInt(formData.discoverability),
			// threat_category_pk: parseInt(formData.threatCategory),
			exploitable_level_pk: parseInt(formData.exploitability),
			reproducibility_level_pk: parseInt(formData.reproducibility),
			confidentiality_level_pk: parseInt(formData.confidentiality),
			integrity_level_pk: parseInt(formData.integrity),
			availability_level_pk: parseInt(formData.availability),
			event_frequency: 2,
			cost_of_incident: parseFloat(formData.costOfIncident),
			cost_of_breach: parseFloat(formData.costOfBreach),
			percent_of_breach: parseFloat(formData.percentOfBreach),
			lost_business_cost: parseFloat(formData.lostOfBusinessCost),
			client_pk: parseInt(
				JSON.parse(localStorage.getItem("cyber-minds"))?.user?.client?.id
			),
		};

		try {
			if (mode === "add") {
				await addThreat({ variables: { inputs } });
				toast.success("Threat added successfully!");
			} else {
				await updateThreat({
					variables: { id: parseInt(selectedItem.id), input: inputs },
				});
				toast.success("Threat updated successfully!");
			}
			fetchData();
			onClose();
		} catch (error) {
			swal("Error", "An error occurred. Please try again.", "error");
			console.error(error);
		}
	};

	useEffect(() => {
		if (selectedItem) {
			setFormData({
				name: selectedItem.name || "",
				threatCategory: selectedItem?.category?.id || "",
				discoverability: selectedItem?.discovery_level?.id || "",
				exploitability: selectedItem?.exploitable_level?.id || "",
				reproducibility: selectedItem?.reproducibility_level?.id || "",
				confidentiality: selectedItem?.confidentiality_level?.id || "",
				availability: selectedItem?.availability_level?.id || "",
				integrity: selectedItem?.integrity_level?.id || "",
				threatVectorIncident: selectedItem.event_frequency || "",
				costOfIncident: selectedItem.cost_of_incident || "",
				costOfBreach: selectedItem.cost_of_breach || "",
				percentOfBreach: selectedItem.percent_of_breach || "",
				lostOfBusinessCost: selectedItem.lost_business_cost || "",
				threatDescription: selectedItem.description || "",
				threatScenario: selectedItem.threat_senario || "",
			});
		}
	}, [selectedItem]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? "Edit Threat Vector" : "Add New Threat Vector"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body columns={3}>
				<Form>
					<Input
						label="Threat Vector Name"
						type="text"
						value={formData.name}
						onChange={(e) => handleChange("name", e.target.value)}
						error={errors.name}
						required
					/>
					<Dropdown
						label="Threat Category"
						value={formData.threatCategory}
						onChange={(e) => handleChange("threatCategory", e.target.value)}
						options={categoriesData?.threat_category}
						error={errors.threatCategory}
						required
					/>
					<Dropdown
						label="Discoverability"
						value={formData.discoverability}
						onChange={(e) => handleChange("discoverability", e.target.value)}
						options={discoverData?.discovery_level}
						error={errors.discoverability}
						required
					/>
					<Dropdown
						label="Exploitability"
						value={formData.exploitability}
						onChange={(e) => handleChange("exploitability", e.target.value)}
						options={exploitData?.exploitable_level}
						error={errors.exploitability}
						required
					/>
					<Dropdown
						label="Reproducibility"
						value={formData.reproducibility}
						onChange={(e) => handleChange("reproducibility", e.target.value)}
						options={reproduceData?.reproducibility_level}
						error={errors.reproducibility}
						required
					/>
					<Dropdown
						label="Confidentiality"
						value={formData.confidentiality}
						onChange={(e) => handleChange("confidentiality", e.target.value)}
						options={confidentData?.confidentiality_level}
						error={errors.confidentiality}
						required
					/>
					<Dropdown
						label="Integrity"
						value={formData.integrity}
						onChange={(e) => handleChange("integrity", e.target.value)}
						options={integritiesData?.integrity_level}
						error={errors.integrity}
						required
					/>
					<Dropdown
						label="Availability"
						value={formData.availability}
						onChange={(e) => handleChange("availability", e.target.value)}
						options={availableData?.availability_level}
						error={errors.availability}
						required
					/>
					<Input
						label="Threat Vector Incident"
						type="number"
						value={formData.threatVectorIncident}
						onChange={(e) =>
							handleChange("threatVectorIncident", e.target.value)
						}
						error={errors.threatVectorIncident}
					/>
					<Input
						label="Cost Of Incident"
						type="number"
						value={formData.costOfIncident}
						onChange={(e) => handleChange("costOfIncident", e.target.value)}
						error={errors.costOfIncident}
					/>
					<Input
						label="Cost Of Breach"
						type="number"
						value={formData.costOfBreach}
						onChange={(e) => handleChange("costOfBreach", e.target.value)}
						error={errors.costOfBreach}
					/>
					<Input
						label="Percent Of Breach"
						type="number"
						value={formData.percentOfBreach}
						onChange={(e) => handleChange("percentOfBreach", e.target.value)}
						error={errors.percentOfBreach}
					/>
					<Input
						label="Lost Of Business Cost"
						type="number"
						value={formData.lostOfBusinessCost}
						onChange={(e) => handleChange("lostOfBusinessCost", e.target.value)}
						error={errors.lostOfBusinessCost}
					/>
					<Input
						label="Threat Description"
						type="text"
						value={formData.threatDescription}
						onChange={(e) => handleChange("threatDescription", e.target.value)}
						error={errors.threatDescription}
					/>
					<Input
						label="Threat Scenario"
						type="text"
						value={formData.threatScenario}
						onChange={(e) => handleChange("threatScenario", e.target.value)}
						error={errors.threatScenario}
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn-cancel" onClick={onClose}>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ThreatRepositoryModal;
