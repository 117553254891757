import React, { useRef, useEffect } from "react";

const Modal = ({ isOpen, onClose, size = "md", children }) => {
	const modalRef = useRef(null);

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === "Escape") {
				onClose();
			}
		};
		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
	}, [onClose]);

	useEffect(() => {
		if (isOpen) {
			const prevActiveElement = document.activeElement;
			modalRef.current?.focus();

			return () => {
				if (prevActiveElement && prevActiveElement.focus) {
					prevActiveElement.focus();
				}
			};
		}
	}, [isOpen]);
	const handleClickOutside = (e) => {
		if (modalRef.current && !modalRef.current.contains(e.target)) {
			onClose(); // Close modal if clicked outside
		}
	};

	const getSizeClass = () => {
		switch (size) {
			case "sm":
				return "max-w-[400px]";
			case "md":
				return "max-w-[600px]";
			case "lg":
				return "max-w-[900px]";
			case "xl":
				return "max-w-[1140px]";
			default:
				return "max-w-[600px]";
		}
	};

	if (!isOpen) return null;

	return (
		<>
			<div
				className={`modal-backdrop ${isOpen ? "show" : "fade"}`}
				onClick={handleClickOutside}
			></div>
			<div
				role="dialog"
				tabIndex="-1"
				aria-modal="true"
				className={`modal ${isOpen ? "show" : "fade"}`}
				onClick={handleClickOutside}
			>
				<div className="modal-dialog">
					<div
						className={`modal-content ${getSizeClass()} mx-auto`}
						ref={modalRef}
					>
						{children}
					</div>
				</div>
			</div>
		</>
	);
};

Modal.Header = ({ children }) => <div className="modal-header">{children}</div>;

Modal.Title = ({ children }) => <h2 className="modal-title">{children}</h2>;

Modal.Body = ({ children, columns = 1 }) => {
	const getColumnClass = () => {
		switch (columns) {
			case 1:
				return "grid grid-cols-1 gap-4";
			case 2:
				return "grid grid-cols-1 md:grid-cols-2 gap-4";
			case 3:
				return "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4";
			case 4:
				return "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4";
			default:
				return "grid grid-cols-1 gap-4";
		}
	};

	return <div className={`modal-body ${getColumnClass()}`}>{children}</div>;
};

Modal.Footer = ({ children }) => <div className="modal-footer">{children}</div>;

export default Modal;
