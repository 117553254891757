import { gql } from '@apollo/client';


export const IMPORT_THREAT = gql`
  mutation IMPORT_THREAT(
    $inputs: ImportInputType!
  ) {
    import_threat(inputs: $inputs) {
      data{
        total
      }
    }
  }
`;
export const IMPORT_BIA = gql`
  mutation IMPORT_BIA(
    $inputs: ImportInputType!
  ) {
    import_asset(inputs: $inputs) {
      data{
      inserted
      updated
      deleted
      total
      }
    }
  }
`;

export const IMPORT_CONTROL_CATALOG = gql`
  mutation IMPORT_CONTROL_CATALOG(
    $inputs: ImportInputType!
  ) {
    import_control(inputs: $inputs) {
      data{
      inserted
      updated
      deleted
      total
      skip
      messages
      }
    }
  }
`


export const IMPORT_AT_MAPPING = gql`
  mutation IMPORT_AT_MAPPING(
    $inputs: ImportInputType!
    ) {
    import_asset_threat_mapping(inputs: $inputs) {
      data{
      inserted
      updated
      deleted
      skip
      total
      }
    }
  }
`;

export const EXPORT_THREAT = gql`
  mutation EXPORT_THREAT(
    $inputs: ExportInputType!
  ) {
    export_threat(inputs: $inputs) {
      data{
       file_content
      }
    }
  }
`;

export const EXPORT_AT_MAPPING = gql`
  mutation EXPORT_AT_MAPPING(
    $inputs: ExportInputType!
  ) {
    export_asset_threat_mapping(inputs: $inputs) {
      data{
       file_content
      }
    }
  }
`;
export const EXPORT_BIA = gql`
  mutation EXPORT_BIA(
    $inputs: ExportInputType!
  ) {
    export_asset(inputs: $inputs) {
      data{
       file_content
      }
    }
  }
`;
export const EXPORT_CONTROL_CATALOG = gql`
  mutation EXPORT_CONTROL_CATALOG(
    $inputs: ExportInputType!
  ) {
    export_control(inputs: $inputs) {
      data{
       file_content
      }
    }
  }
`;