import React from "react";
import ThreatRepositoryMain from "./ThreatRepositoryMain";
import {
	ADD_THREAT_VECTOR,
	DELETE_THREAT_VECTOR,
	GET_THREATS,
	UPDATE_THREAT_VECTOR,
} from "../../graphql/superAdmin/query/query";
import { useMutation } from "@apollo/client";

const ThreatVectorSuper = () => {
	const [addThreat] = useMutation(ADD_THREAT_VECTOR);
	const [updateThreat] = useMutation(UPDATE_THREAT_VECTOR);
	const [deleteThreat] = useMutation(DELETE_THREAT_VECTOR);

	return (
		<>
			<ThreatRepositoryMain
				deleteThreatQuery="delete_threat"
				name="threat"
				aggregate="threat_aggregate"
				query={GET_THREATS}
				addThreat={addThreat}
				updateThreat={updateThreat}
				deleteThreat={deleteThreat}
			/>
		</>
	);
};

export default ThreatVectorSuper;
