import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { ADD_CONTROL_CATALOG } from '../../../graphql/superAdmin/mutation/mutation';
import {
  GET_CONTROL_TYPE,
  GET_CONTROL_DOMAIN,
  GET_CONTROL_CATEGORY,
  GET_CONTROL_APPLICABILITY,
  GET_CONTROL_IMPACT,
  GET_CONTROL_SCOPE,
} from '../../../graphql/superAdmin/query/controlConfiguration/query';
import { GET_THREATS, UPDATE_CONTROL_CATALOG } from '../../../graphql/superAdmin/query/query';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import swal from 'sweetalert';
import MultiSelect from '../../../shared/MultiSelect';

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
  <Form.Group>
    <Form.Label>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </Form.Label>
    <select className="form-control" value={value} onChange={onChange}>
      <option value="">Select {label.toLowerCase()}...</option>
      {options?.map((item) => (
        <option key={item.id} value={item.id}>
          {item.label || item.name}
        </option>
      ))}
    </select>
    {error && <span style={{ color: 'red' }}>{error}</span>}
  </Form.Group>
);

// Reusable Input Component
const Input = ({ label, type, value, onChange, error, required, placeholder }) => (
  <Form.Group>
    <Form.Label>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </Form.Label>
    <input
      className="form-control"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {error && <span style={{ color: 'red' }}>{error}</span>}
  </Form.Group>
);

const ModalHandler = ({ isOpen, mode, onClose, header, size, fetchData, selectedItem }) => {
  const [formData, setFormData] = useState({
    control_name: '',
    control_domain: '',
    control_category: '',
    control_scope: '',
    control_impact: '',
    control_type: '',
    control_applicability: '',
    primary_threat: '',
    secondary_threat: [],
    industry_usecase: [],
    industry_standard_text: [],
    control_description: '',
    cmc_name: '',
    control_efficacy: '',
    example_threat: '',
    industry_identifier: '',
    iso_nist: '',
    mitre: '',
    number_of_threats_solved: '',
    owasp: '',
    rationalized_threats: '',
    relevance: '',
    stride: '',
  });

  const [errors, setErrors] = useState({});

  // Fetch data for dropdowns
  const { data: controlTypeData } = useQuery(GET_CONTROL_TYPE, { variables: { limit: 100, offset: 0 } });
  const { data: controlDomainData } = useQuery(GET_CONTROL_DOMAIN, { variables: { limit: 100, offset: 0 } });
  const { data: controlCategoryData } = useQuery(GET_CONTROL_CATEGORY, { variables: { limit: 100, offset: 0 } });
  const { data: controlApplicabilityData } = useQuery(GET_CONTROL_APPLICABILITY, { variables: { limit: 100, offset: 0 } });
  const { data: controlImpactData } = useQuery(GET_CONTROL_IMPACT, { variables: { limit: 100, offset: 0 } });
  const { data: controlScopeData } = useQuery(GET_CONTROL_SCOPE, { variables: { limit: 100, offset: 0 } });
  const { data: threatData } = useQuery(GET_THREATS, { variables: { limit: 100, offset: 0 } });

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    if (errors[key]) setErrors((prev) => ({ ...prev, [key]: '' }));
  };

  const [addControl] = useMutation(ADD_CONTROL_CATALOG);
  const [updateControl] = useMutation(UPDATE_CONTROL_CATALOG);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const formatPostgresError = (errorMessage) => {
    const match = errorMessage.match(/\((.*?)\)=\((.*?)\)/);

    if (match) {
      const fields = match[1].split(", ");
      const values = match[2].split(", ");

      let formattedMessage = "A record with the same values already exists for: ";

      const details = fields.map((field, index) => `${field.replace(/_/g, " ")}: "${values[index]}"`).join(", ");

      return formattedMessage + details;
    }

    return "A duplicate entry error occurred. Please check your input and try again.";
  };
  const [selectedSecondaryThreats, setSelectedSecondaryThreats] = useState([]);
  const threatOptions = threatData?.threat?.map((threat) => threat.name)

  const handleChangeTest = (selectedNames) => {
    setSelectedSecondaryThreats(selectedNames);
    console.log("Selected Names:", selectedNames);
  };
  const handleSubmit = () => {
    if (isSubmitting) return; // Prevent re-submission

    const newErrors = {
      control_name: !formData.control_name ? 'This field is required.' : '',
      control_domain: !formData.control_domain ? 'This field is required.' : '',
      control_category: !formData.control_category ? 'This field is required.' : '',
    };

    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      setIsSubmitting(true); // Disable further submissions

      const inputs = {
        created_at: new Date().toISOString(),
        name: formData.control_name,
        control_domain_pk: parseInt(formData.control_domain, 10),
        control_type_pk: parseInt(formData.control_type, 10),
        control_scope_pk: parseInt(formData.control_scope, 10),
        control_impact_pk: parseInt(formData.control_impact, 10),
        control_category_pk: parseInt(formData.control_category, 10),
        control_applicability_pk: parseInt(formData.control_applicability, 10),
        primary_threat_pk: parseInt(formData.primary_threat, 10),
        industry_usecase: formData.industry_usecase,
        secondary_threats: JSON.stringify(selectedSecondaryThreats)
      };

      if (mode === "add") {
        addControl({ variables: { inputs } })
          .then(() => {
            setIsSubmitting(false); // Re-enable submissions
            onClose();
            swal('Success', 'Control has been added successfully!', 'success');
            fetchData();
          })
          .catch((err) => {
            setIsSubmitting(false);
            const errorMessage = err?.graphQLErrors?.[0]?.message || 'Something went wrong, please try again.';

            swal('Error', formatPostgresError(errorMessage), 'error');
            console.error('GraphQL Error:', err);
          });
      } else {
        updateControl({ variables: { id: parseInt(selectedItem?.id), input: inputs } })
          .then(() => {
            setIsSubmitting(false);
            onClose();
            swal('Success', 'Control has been updated successfully!', 'success');
            fetchData();
          })
          .catch((err) => {
            setIsSubmitting(false);
            const errorMessage = err?.graphQLErrors?.[0]?.message || 'Something went wrong, please try again.';
            console.error('GraphQL Error:', err);
            swal('Error', formatPostgresError(errorMessage), 'error');
          });
      }
    }
  };
  console.log(controlDomainData, "controlDomainData")

  useEffect(() => {
    if (selectedItem) {
      const updatedFormData = {
        control_name: selectedItem.control_name || '',
        control_domain: controlDomainData?.control_domain.find(
          (domain) => domain.label === selectedItem.control_domain
        )?.id || '',
        control_category: controlCategoryData?.control_category.find(
          (category) => category.label === selectedItem.control_category
        )?.id || '',
        control_scope: controlScopeData?.control_scope.find(
          (scope) => scope.label === selectedItem.control_scope
        )?.id || '',
        control_impact: controlImpactData?.control_impact.find(
          (impact) => impact.label === selectedItem.control_impact
        )?.id || '',
        control_type: controlTypeData?.control_type.find(
          (type) => type.label === selectedItem.control_type
        )?.id || '',
        control_applicability: controlApplicabilityData?.control_applicability.find(
          (applicability) => applicability.label === selectedItem.control_applicability
        )?.id || '',
        primary_threat: threatData?.threat.find(
          (threat) => threat.name === selectedItem.primary_threat
        )?.id || '',
        secondary_threat: selectedItem.secondary_threats || [],
        industry_usecase: selectedItem.industry_usecase || [],
        industry_standard_text: selectedItem.industry_standard_text || [],
        control_description: selectedItem.control_description || '',
        cmc_name: selectedItem.cmc_name || '',
        control_efficacy: selectedItem.control_efficacy || '',
        example_threat: selectedItem.example_threat || '',
        industry_identifier: selectedItem.industry_identifier || '',
        iso_nist: selectedItem.iso_nist || '',
        mitre: selectedItem.mitre || '',
        number_of_threats_solved: selectedItem.number_of_threats_solved || '',
        owasp: selectedItem.owasp || '',
        rationalized_threats: selectedItem.rationalized_threats || '',
        relevance: selectedItem.relevance || '',
        stride: selectedItem.stride || '',
      };

      setFormData(updatedFormData);
    } else {
      setFormData({
        control_name: '',
        control_domain: '',
        control_category: '',
        control_scope: '',
        control_impact: '',
        control_type: '',
        control_applicability: '',
        primary_threat: '',
        secondary_threat: [],
        industry_usecase: [],
        industry_standard_text: [],
        control_description: '',
        cmc_name: '',
        control_efficacy: '',
        example_threat: '',
        industry_identifier: '',
        iso_nist: '',
        mitre: '',
        number_of_threats_solved: '',
        owasp: '',
        rationalized_threats: '',
        relevance: '',
        stride: '',
      });
    }
  }, [selectedItem, controlDomainData, controlCategoryData, controlScopeData, controlImpactData, controlTypeData, controlApplicabilityData, threatData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>{mode === 'edit' ? `Update ${header}` : `Add New ${header}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-three-columns">
          <Form className="modal-three-columns">
            {/* Control Details */}
            <Input
              label="Control Name"
              type="text"
              value={formData.control_name}
              onChange={(e) => handleChange('control_name', e.target.value)}
              error={errors.control_name}
              required
            />
            <Dropdown
              label="Control Domain"
              value={formData.control_domain}
              onChange={(e) => handleChange('control_domain', e.target.value)}
              options={controlDomainData?.control_domain}
              error={errors.control_domain}
              required
            />
            <Dropdown
              label="Control Category"
              value={formData.control_category}
              onChange={(e) => handleChange('control_category', e.target.value)}
              options={controlCategoryData?.control_category}
              error={errors.control_category}
              required
            />
            <Dropdown
              label="Control Type"
              value={formData.control_type}
              onChange={(e) => handleChange('control_type', e.target.value)}
              options={controlTypeData?.control_type}
              error={errors.control_type}
              required
            />
            <Dropdown
              label="Control Scope"
              value={formData.control_scope}
              onChange={(e) => handleChange('control_scope', e.target.value)}
              options={controlScopeData?.control_scope}
              error={errors.control_scope}
              required
            />
            <Dropdown
              label="Control Impact"
              value={formData.control_impact}
              onChange={(e) => handleChange('control_impact', e.target.value)}
              options={controlImpactData?.control_impact}
              error={errors.control_impact}
              required
            />
            <Dropdown
              label="Control Applicability"
              value={formData.control_applicability}
              onChange={(e) => handleChange('control_applicability', e.target.value)}
              options={controlApplicabilityData?.control_applicability}
              error={errors.control_applicability}
              required
            />

            {/* Threat Details */}
            <Dropdown
              label="Primary Threat"
              value={formData.primary_threat}
              onChange={(e) => handleChange('primary_threat', e.target.value)}
              options={threatData?.threat}
              error={errors.primary_threat}
              required
            />
            <Dropdown
              label="Secondary Threat"
              value={formData.secondary_threat}
              onChange={(e) => handleChange('secondary_threat', e.target.value)}
              options={threatData?.threat}
              error={errors.secondary_threat}
              required
            />
            <div className="form-group">
              <label>Secondary Threat"</label>
              <MultiSelect
                options={threatOptions}
                value={selectedSecondaryThreats}
                onChange={handleChangeTest}
                disabled={false} // Set to true to disable the dropdown
              />
            </div>

            {/* Industry Details */}
            <Input
              label="Industry Use Case"
              type="text"
              value={formData.industry_usecase}
              onChange={(e) => handleChange('industry_usecase', e.target.value)}
              error={errors.industry_usecase}
            />
            <Input
              label="Industry Standard Text"
              type="text"
              value={formData.industry_standard_text}
              onChange={(e) => handleChange('industry_standard_text', e.target.value)}
              error={errors.industry_standard_text}
            />
            <Input
              label="Control Description"
              type="text"
              value={formData.control_description}
              onChange={(e) => handleChange('control_description', e.target.value)}
              error={errors.control_description}
            />

            {/* Additional Fields */}
            <Input
              label="CMC Name"
              type="text"
              value={formData.cmc_name}
              onChange={(e) => handleChange('cmc_name', e.target.value)}
              error={errors.cmc_name}
            />
            <Input
              label="Control Efficacy"
              type="text"
              value={formData.control_efficacy}
              onChange={(e) => handleChange('control_efficacy', e.target.value)}
              error={errors.control_efficacy}
            />
            <Input
              label="Example Threat"
              type="text"
              value={formData.example_threat}
              onChange={(e) => handleChange('example_threat', e.target.value)}
              error={errors.example_threat}
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-cancel" onClick={onClose}>
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;