import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import swal from 'sweetalert';
import { GET_COST_CATEGORY } from '../../../graphql/superAdmin/query/controlConfiguration/query';
import { GET_THREATS } from '../../../graphql/superAdmin/query/query';
import { ADD_COST, UPDATE_COST } from '../../../graphql/superAdmin/mutation/mutation';
import toast from 'react-hot-toast';

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
  <Form.Group>
    <Form.Label>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </Form.Label>
    <select className="form-control" value={value} onChange={onChange}>
      <option value="">Select {label.toLowerCase()}...</option>
      {options?.map((item) => (
        <option key={item.id} value={item.category || item.id}>
          {item.label || item.name || item.category}
        </option>
      ))}
    </select>
    {error && <span style={{ color: 'red' }}>{error}</span>}
  </Form.Group>
);

// Reusable Input Component
const Input = ({ label, type, value, onChange, error, required, placeholder }) => (
  <Form.Group>
    <Form.Label>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </Form.Label>
    <input
      className="form-control"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {error && <span style={{ color: 'red' }}>{error}</span>}
  </Form.Group>
);

const ModalHandler = ({
  isOpen,
  mode,
  onClose,
  header,
  selectedItem,
  selectedItemId,
  size,
  fetchData,
}) => {
  const [formData, setFormData] = useState({
    cost_category: '',
    threat_vector: '',
    cost_of_breach: '',
    percent_of_total_breach: '',
    threat_event_frequency: '',
    cost_per_incident: '',
    lost_business_cost: '',
  });

  const [errors, setErrors] = useState({});

  // Fetch data for dropdowns
  const { data: costCategoryData } = useQuery(GET_COST_CATEGORY, { variables: { limit: 100, offset: 0 } });
  const { data: threatData } = useQuery(GET_THREATS, { variables: { limit: 100, offset: 0 } });

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    if (errors[key]) setErrors((prev) => ({ ...prev, [key]: '' }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  // GraphQL Mutations
  const [addCostThreat] = useMutation(ADD_COST);
  const [updateCostThreat] = useMutation(UPDATE_COST);

  const formatPostgresError = (errorMessage) => {
    const match = errorMessage.match(/\((.*?)\)=\((.*?)\)/);

    if (match) {
      const fields = match[1].split(", ");
      const values = match[2].split(", ");

      let formattedMessage = "A record with the same values already exists for: ";

      const details = fields.map((field, index) => `${field.replace(/_/g, " ")}: "${values[index]}"`).join(", ");

      return formattedMessage + details;
    }

    return "A duplicate entry error occurred. Please check your input and try again.";
  };

  const handleSubmit = () => {
    if (isSubmitting) return; // Prevent re-submission
    const newErrors = {
      // cost_category: !formData.cost_category ? 'This field is required.' : '',
      threat_vector: !formData.threat_vector ? 'This field is required.' : '',
      cost_of_breach: !formData.cost_of_breach ? 'This field is required.' : '',
      percent_of_total_breach: !formData.percent_of_total_breach ? 'This field is required.' : '',
      threat_event_frequency: !formData.threat_event_frequency ? 'This field is required.' : '',
      cost_per_incident: !formData.cost_per_incident ? 'This field is required.' : '',
      lost_business_cost: !formData.lost_business_cost ? 'This field is required.' : '',
    };

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      setIsSubmitting(true); // Disable further submissions

      const inputs = {
        created_at: new Date().toISOString(),
        // cost_category: formData.cost_category, // Send the category value directly
        threat_pk: parseInt(formData.threat_vector, 10),
        cost_of_breach: parseFloat(formData.cost_of_breach),
        percent_of_total_breach: parseFloat(formData.percent_of_total_breach),
        threat_event_frequency: parseFloat(formData.threat_event_frequency),
        cost_per_incident: parseFloat(formData.cost_per_incident),
        lost_business_cost: parseFloat(formData.lost_business_cost),
      };

      if (mode === 'add') {
        addCostThreat({ variables: { inputs } })
          .then(() => {
            setIsSubmitting(false); // Re-enable submissions
            onClose();
            toast.success('Record added successfully!');
            fetchData();
          })
          .catch((err) => {
            setIsSubmitting(false);
            const errorMessage = err?.graphQLErrors?.[0]?.message || 'Something went wrong, please try again.';
            toast.error('Error', formatPostgresError(errorMessage), 'error');
            console.error('GraphQL Error:', err);
          });
      } else {
        updateCostThreat({ variables: { id: parseInt(selectedItemId), input: inputs } })
          .then(() => {
            setIsSubmitting(false);
            onClose();
            toast.success('Record has been updated successfully!');
            fetchData();
          })
          .catch((err) => {
            setIsSubmitting(false);
            const errorMessage = err?.graphQLErrors?.[0]?.message || 'Something went wrong, please try again.';
            toast.error('Error', formatPostgresError(errorMessage), 'error');
            console.error('GraphQL Error:', err);
          });
      }
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        cost_category: selectedItem.cost_category || '',
        threat_vector: threatData?.threat.find(
          (threat) => threat.name === selectedItem.threat
        )?.id || '',
        cost_of_breach: selectedItem.cost_of_breach || '',
        percent_of_total_breach: selectedItem.percent_of_total_breach || '',
        threat_event_frequency: selectedItem.threat_event_frequency || '',
        cost_per_incident: selectedItem.cost_per_incident || '',
        lost_business_cost: selectedItem.lost_business_cost || '',
      });
    } else {
      setFormData({
        cost_category: '',
        threat_vector: '',
        cost_of_breach: '',
        percent_of_total_breach: '',
        threat_event_frequency: '',
        cost_per_incident: '',
        lost_business_cost: '',
      });
    }
  }, [selectedItem]);
  console.log(costCategoryData, "costCategoryData")
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>{mode === 'edit' ? `Update ${header}` : `Add New ${header}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-two-columns">
          <Form>
            <Dropdown
              label="Cost Category"
              value={formData.cost_category}
              onChange={(e) => handleChange('cost_category', e.target.value)}
              options={costCategoryData?.cost_category}
              error={errors.cost_category}
              required
            />
            <Dropdown
              label="Threat Vector"
              value={formData.threat_vector}
              onChange={(e) => handleChange('threat_vector', e.target.value)}
              options={threatData?.threat}
              error={errors.threat_vector}
              required
            />
            <Input
              label="Cost of Breach"
              type="number"
              value={formData.cost_of_breach}
              onChange={(e) => handleChange('cost_of_breach', e.target.value)}
              error={errors.cost_of_breach}
              required
            />
            <Input
              label="Percent of Total Breach"
              type="number"
              value={formData.percent_of_total_breach}
              onChange={(e) => handleChange('percent_of_total_breach', e.target.value)}
              error={errors.percent_of_total_breach}
              required
            />
            <Input
              label="Threat Event Frequency"
              type="number"
              value={formData.threat_event_frequency}
              onChange={(e) => handleChange('threat_event_frequency', e.target.value)}
              error={errors.threat_event_frequency}
              required
            />
            <Input
              label="Cost Per Incident"
              type="number"
              value={formData.cost_per_incident}
              onChange={(e) => handleChange('cost_per_incident', e.target.value)}
              error={errors.cost_per_incident}
              required
            />
            <Input
              label="Lost Business Cost"
              type="number"
              value={formData.lost_business_cost}
              onChange={(e) => handleChange('lost_business_cost', e.target.value)}
              error={errors.lost_business_cost}
              required
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-cancel" onClick={onClose}>
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;