import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useContext, useEffect, useState } from "react";
import apiClient from "../../resources/apiClient";
// import cronusLogo from "../../assets/imgs/cronusLogo.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cronusBlueNew from "../../assets/imgs/cronusBlueNew.png";
// import { ThemeContext } from "../../context/ThemeProvider";
import { useHandleTheme } from "../../hooks/useHandleTheme";
import PleaseWait from "../../shared/PleaseWaitLoader/PleaseWait";
import { storeCurrentDateTimeInLocalStorage } from "../../utils/helpers";
import { set_token, set_user_data, set_user_status } from "../store/actions";
import { setUserName } from "../store/reducers/userNameSlice";
import { useMutation } from "@apollo/client";
import { LOGIN } from "./../../graphql/shared/mutation/mutation";

const Login = () => {
	// const { theme, setTheme } = useContext(ThemeContext);
	// const { handleDarkTheme, handleLightTheme, darkMode } = useHandleTheme();
	const dispatch = useDispatch();

	// const toggleTheme = () => {
	// 	setTheme("light");
	// 	handleLightTheme();
	// };
	// useEffect(() => {
	// 	toggleTheme();
	// }, []);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [status, setStatus] = useState("");
	const history = useHistory();
	const [viewPassword, setViewPassword] = useState(false);
	const toggleViewPassword = () => setViewPassword(!viewPassword);
	const [checked, setChecked] = useState(false);
	const [loading, setLoading] = useState(false);

	const currentTime = new Date();
	const year = currentTime.getFullYear();

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const [login, { data, loading: mutationLoading, error }] = useMutation(LOGIN);
	const submit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			const response = await login({
				variables: { email, password, otp: null },
			});
			setLoading(false);
			const userData = response.data.login;
			storeCurrentDateTimeInLocalStorage();
			localStorage.setItem(
				"data",
				JSON.stringify({
					email,
					password,
					userId: userData.user.id,
				})
			);
			localStorage.setItem("totp", userData.totp);
			dispatch(setUserName(userData.user.username));
			//   if (userData.user.status === 'active') {

			if (userData.two_fa_configured === true) {
				history.push("/multifactor-authentication");
			} else {
				registerUserDetails(response);
				history.push("/multifactor-authentication-setup");
			}
			//   else {
			//     setStatus('inactiveUser');
			//     setLoading(false);
			//   }
		} catch (err) {
			setLoading(false);
			console.error(err);
			setStatus("invalidUser");
		}
	};

	const registerUserDetails = (response) => {
		console.log(response.data, "data here");
		dispatch(set_token(response.data.login.token));
		dispatch(
			set_user_data({
				...response.data.login.user,
				totp: response.data.login.totp,
			})
		);
		dispatch(
			set_user_status({
				approved: response.data.login.user.approved,
				loggedIn: true,
				access_token: response.data.login.token,
				refresh_token: response.data.login.refresh_token,
			})
		);
	};

	return (
		<div className="bg flex  justify-around items-center  h-screen overflow-y-hidden">
			<div className="h-full  text-4xl  flex  justify-center items-center">
				<div className="flex flex-col justify-between items-start h-4/5">
					<span className="font-bold ">
						Secure Your Enterprise with a <br></br>Data-Driven Cyber Strategy.
					</span>
					<span className="text-3xl">
						Protect Your Business with <br></br>Proven Cybersecurity Frameworks.
					</span>
					<div className="flex space-x-4">
						<button
							type="button"
							style={{ backgroundColor: "#ce9f2c" }}
							className="focus:outline-none  focus:ring-4  font-medium rounded-lg text-xl  px-5 py-3 mr-2 mb-2 "
						>
							Get Started
						</button>
						<button
							style={{ backgroundColor: "#11c4f5" }}
							type="button"
							className="focus:outline-none     focus:ring-4  font-medium rounded-lg text-xl  px-5 py-3 mr-2 mb-2 "
						>
							Learn More{" "}
						</button>
					</div>
					<div className="flex  justify-center items-center ">
						<span className="text-xl">Powered By</span>

						<img
							className=" w-44 object-contain"
							src="https://i.ibb.co/F3kB1p7/imgs/logo.png"
							alt="cyberminds-logo"
						/>
					</div>
					<div className="flex">
						<div className="flex flex-col space-y-4">
							<div className=" flex justify-center items-center space-x-5">
								<a
									className="decoration-none"
									target="_blank"
									href="https://www.youtube.com/channel/UCoe0mRdgqbOYXp6paXRiSVQ"
									rel="noReferrer"
								>
									{" "}
									<YouTubeIcon className="h-6 text-red" />
								</a>
								<a
									href="https://twitter.com/cybermindsc"
									target="_blank"
									rel="noReferrer"
								>
									{" "}
									<TwitterIcon className="h-6" />
								</a>
								<a
									href="https://www.linkedin.com/company/cyberminds-consulting/"
									target="_blank"
									rel="noReferrer"
								>
									{" "}
									<LinkedInIcon className="h-6" target="_blank" />
								</a>
								<a
									href="https://www.cybermindssolutions.com/"
									target="_blank"
									rel="noReferrer"
								>
									{" "}
									<LanguageIcon className="h-6" />
								</a>
							</div>
							<div>
								<span className=" flex justify-center items-center text-xs">
									©{year} by CyberMinds Consulting. All Rights Reserved.
								</span>
							</div>
						</div>{" "}
					</div>
				</div>
			</div>
			<div className="h-5/6 ">
				<form className="p-6  dark:bg-gray-700 flex flex-col rounded-2xl shadow-2xl shadow-cyan-500/50">
					<div className="flex justify-center items-center  ">
						<img
							className="h-44 w-44 rounded-full"
							src={cronusBlueNew}
							alt="Cronus logo"
						/>
					</div>
					<div className="mb-6">
						<label
							for="email"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Email
						</label>
						<div>
							<input
								type="email"
								id="email"
								value={email}
								className="flex items-center p-2.5 bg-gray-50 border text-gray-900 text-sm rounded-xl shadow-2xl shadow-cyan-500/50 focus:ring-slate-200 focus:border-slate-200  w-full
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Email..."
								required
							/>
						</div>
					</div>
					<div className="mb-6">
						<label
							for="password"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Password
						</label>
						<div
							className="flex justify-between items-center bg-gray-50 border text-gray-900 text-sm rounded-xl shadow-2xl shadow-cyan-500/50 focus:ring-gray-200 focus:border-slate-200  w-full
                   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
						>
							<input
								type={viewPassword ? "text" : "password"}
								id="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="•••••••••"
								required
								className="inline-block p-2.5  border text-gray-900 text-sm rounded-xl shadow-2xl shadow-cyan-500/50 focus:ring-slate-200 focus:border-slate-200  w-full
                   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
							/>
							<span
								className="cursor-pointer inline-block p-1"
								onClick={() => toggleViewPassword()}
							>
								{viewPassword ? "🙈" : "👁️"}
							</span>
						</div>

						<label
							onClick={() => history.push("/reset-password")}
							className="flex cursor-pointer justify-end items-end mb-2 mt-2 text-sm font-medium text-blue-500 dark:text-blue-300"
						>
							Forgot Password?
						</label>
					</div>
					{status === "inactiveUser" ? (
						<p className="text-red-500 text-sm">
							Your credentials are not active at the <br></br> moment. Please
							contact
							<a
								href="#"
								className="text-blue-500 hover:underline dark:text-blue-500"
							>
								support
							</a>{" "}
						</p>
					) : status === "invalidUser" ? (
						<p className="text-red-500 text-sm">
							Email and password are not valid.
						</p>
					) : null}

					<div className="flex items-start mb-6">
						<div className="flex items-center h-5">
							<input
								id="remember"
								type="checkbox"
								value=""
								className="w-4 h-4 rounded border border-slate-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-200dark:focus:ring-gray-300-200 dark:focus:border-gray-300"
								required
								checked={checked}
								onChange={handleChange}
							/>
						</div>
						<label
							for="remember"
							className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-400"
						>
							I agree with the{" "}
							<a
								href="#"
								className="text-blue-600 hover:underline dark:text-blue-500"
							>
								terms and conditions
							</a>
							.
						</label>
					</div>

					{checked ? (
						loading ? (
							<PleaseWait />
						) : (
							<button
								className="focus:outline-none    focus:ring-4  font-medium rounded-lg text-sm  px-5 py-3 mr-2 mb-2 "
								type="submit"
								style={{ backgroundColor: "#ce9f2c" }}
								onClick={(e) => {
									submit(e);
								}}
							>
								Sign In
							</button>
						)
					) : (
						<button
							className="focus:outline-none  focus:ring-4  font-medium rounded-lg text-sm  px-5 py-3 mr-2 mb-2 "
							type="submit"
							style={{ backgroundColor: "#ce9f2c" }}
						>
							Sign In
						</button>
					)}
				</form>
			</div>
		</div>
	);
};

export default Login;
