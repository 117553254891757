import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import swal from "sweetalert";
import { ADD_CONSENSUS_ASSESSMENT, UPDATE_CONSENSUS_ASSESSMENT } from "../../../graphql/superAdmin/mutation/mutation";
import toast from "react-hot-toast";

// Reusable Input Component
const Input = ({ label, type, value, onChange, error, required, placeholder }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: "red" }}>*</span>}
		</Form.Label>
		<input
			className="form-control"
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
		/>
		{error && <span style={{ color: "red" }}>{error}</span>}
	</Form.Group>
);

const ModalHandler = ({
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	fetchData,
}) => {
	const [formData, setFormData] = useState({
		question_id: "",
		consensus_assessment_question: "",
		control_id: "",
		control_title: "",
		control_specification: "",
	});

	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Fetch dropdown options using GraphQL queries

	// GraphQL Mutations
	const [addControlQuestion] = useMutation(ADD_CONSENSUS_ASSESSMENT);
	const [updateControlQuestion] = useMutation(UPDATE_CONSENSUS_ASSESSMENT);

	const handleChange = (key, value) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
		if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
	};

	const formatPostgresError = (errorMessage) => {
		const match = errorMessage.match(/\((.*?)\)=\((.*?)\)/);
		if (match) {
			const fields = match[1].split(", ");
			const values = match[2].split(", ");
			let formattedMessage = "A record with the same values already exists for: ";
			const details = fields
				.map((field, index) => `${field.replace(/_/g, " ")}: "${values[index]}"`)
				.join(", ");
			return formattedMessage + details;
		}
		return "A duplicate entry error occurred. Please check your input and try again.";
	};

	const handleSubmit = () => {
		if (isSubmitting) return; // Prevent re-submission

		const newErrors = {
			question_id: !formData.question_id ? "This field is required." : "",
			consensus_assessment_question: !formData.consensus_assessment_question
				? "This field is required."
				: "",
			control_id: !formData.control_id ? "This field is required." : "",
			control_title: !formData.control_title ? "This field is required." : "",
			control_specification: !formData.control_specification
				? "This field is required."
				: "",
		};

		setErrors(newErrors);

		if (Object.values(newErrors).every((error) => !error)) {
			setIsSubmitting(true); // Disable further submissions

			const inputs = {
				created_at: new Date().toISOString(),
				question_id: formData.question_id,
				consensus_assessment_question: formData.consensus_assessment_question,
				control_id: formData.control_id,
				control_title: formData.control_title,
				control_specification: formData.control_specification,
			};

			if (mode === "add") {
				addControlQuestion({ variables: { inputs } })
					.then(() => {
						setIsSubmitting(false); // Re-enable submissions
						onClose();
						toast.success("Success", "Control question has been added successfully!", "success");
						fetchData();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage =
							err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
						toast.error("Error", formatPostgresError(errorMessage), "error");
						console.error("GraphQL Error:", err);
					});
			} else {
				updateControlQuestion({ variables: { id: parseInt(selectedItemId), input: inputs } })
					.then(() => {
						setIsSubmitting(false);
						onClose();
						toast.success("Success", "Control question has been updated successfully!", "success");
						fetchData();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage =
							err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
						toast.error("Error", formatPostgresError(errorMessage), "error");
						console.error("GraphQL Error:", err);
					});
			}
		}
	};

	useEffect(() => {
		if (selectedItem) {
			setFormData({
				question_id: selectedItem.question_id || "",
				consensus_assessment_question: selectedItem.consensus_assessment_question || "",
				control_id: selectedItem.control_id || "",
				control_title: selectedItem.control_title || "",
				control_specification: selectedItem.control_specification || "",
			});
		} else {
			setFormData({
				question_id: "",
				consensus_assessment_question: "",
				control_id: "",
				control_title: "",
				control_specification: "",
			});
		}
	}, [selectedItem]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update ${header}` : `Add New ${header}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-two-columns">
					<Form>
						<Input
							label="Question Id"
							type="text"
							value={formData.question_id}
							onChange={(e) => handleChange("question_id", e.target.value)}
							error={errors.question_id}
							required
						/>
						<Input
							label="Consensus Assessment Question"
							type="text"
							value={formData.consensus_assessment_question}
							onChange={(e) => handleChange("consensus_assessment_question", e.target.value)}
							error={errors.consensus_assessment_question}
							required
						/>
						<Input
							label="Control Id"
							type="text"
							value={formData.control_id}
							onChange={(e) => handleChange("control_id", e.target.value)}
							error={errors.control_id}
							required
						/>
						<Input
							label="Control Title"
							type="text"
							value={formData.control_title}
							onChange={(e) => handleChange("control_title", e.target.value)}
							error={errors.control_title}
							required
						/>
						<Input
							label="Control Specification"
							type="text"
							value={formData.control_specification}
							onChange={(e) => handleChange("control_specification", e.target.value)}
							error={errors.control_specification}
							required
						/>
					</Form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn-cancel" onClick={onClose}>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;