import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import http from '../../../resources/http';
import {
  EXPLOITABLE_PATH,
  EXPLOITABLE_PATHS,
  FORMATTED_EXPLOITABLE_PATHS,
} from '../../../api/businessProcess';
import CustomTable from '../../../shared/CustomTable';
import SearchHandler from '../../../shared/SearchHandler';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import ModalHandler from './ModalHandler';
import ModalFileUpload from '../../../shared/ModalFileUpload';
import { DELETE_EXPLOITABLE_PATH, GET_EXPLOITABLE_PATHS } from '../../../graphql/superAdmin/query/query';

const ExploitablePath = () => {
  const url = EXPLOITABLE_PATHS;
  const [id, setId] = useState();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState("add"); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_EXPLOITABLE_PATHS, {
    variables: { limit: 100 },
  });
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.exploitable_path_aggregate?.count || 0;

  const formatted = data?.exploitable_path?.map(path => ({
    id: path?.id,
    entry: path?.entry?.name ?? "-",
    middle: path?.middle?.name ?? "-",
    end: path?.end?.name ?? "-",
    type: path?.path_type ?? "-",
  }));

  const debounceDelay = 1000;
  const fieldsToFilterBy = ["entry", "middle", "end", "type"];

  const filteredData = SearchHandler(
    formatted ?? [],
    searchValue,
    fieldsToFilterBy,
    debounceDelay
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };

  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode("edit");
    setIsModalOpen(true);
  };

  const [del] = useMutation(DELETE_EXPLOITABLE_PATH);
  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });

      if (data?.delete_exploitable_path?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };

  return (
    <div className="main-container">
      <div className="table-title ">
        <span className="font-bold ">Exploitable Paths</span>

        <div className="flex justify-between items-center space-x-4">
          <SearchInput
            searchValue={searchValue}
            setSearchValue={handleInputChange}
          />
          <button
            onClick={() => {
              setIsModalUploadOpen(true);
            }}
            className="btn_file_download"
          >
            <img
              alt="upload-avatar"
              className="w-7 h-7"
              src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
            />
          </button>
          <button className="btn_file_download">
            <svg
              // onClick={() => downloadExcelData(data, "ExploitablePaths")}
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fill-rule="evenodd"
              fill="white"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              setSelectedItem(null);
              setMode("add");
              setIsModalOpen(true);
            }}
            className="btn-add-new"
          >
            <AddIcon />
          </button>
        </div>
      </div>
      {fetchLoading ? (
        <LoadingComponent />
      ) : searchValue.length > 0 && filteredData.length === 0 ? (
        <div className="alert-no-data">
          No data available for
          <span className="search-value">{searchValue}</span>
          at the moment.
        </div>
      ) : (
        <CustomTable
          filteredData={filteredData}
          onEditClick={handleEdit}
          onDeleteClick={handleDelete}
          dataPerPage={pageSize}
          page={page}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={handlePageChange}        />
      )}

      <ModalHandler
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode={mode}
        size="xl"
        header="Exploitable Path"
        selectedItemId={id}
        selectedItem={selectedItem}
        url_get={url}
        fetchData={refetch}
      />

      {/* <ModalFileUpload
        header="Exploitable Path Data"
        UploadURL={EXPLOITABLE_PATHS}
        fetchData={fetchData}
        isOpen={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
      /> */}
    </div>
  );
};

export default ExploitablePath;