import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeProvider";
import { logout } from "../store/actions";

const Signout = () => {
	const history = useHistory();
	const { theme, setTheme } = useContext(ThemeContext);
	const dispatch = useDispatch();

	const handleLogout = () => {
		// Dispatch the logout action
		dispatch(logout());
	};
	useEffect(() => {
		handleLogout();
		setTheme("light");
		localStorage.removeItem("lastVisitDateTime");
		localStorage.removeItem("data");
		history.push("/signin");
	}, []);

	return null;
};

export default Signout;
