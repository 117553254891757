import React, { useEffect, useState } from 'react';
import CustomTable from '../../../shared/CustomTable';
import CronusSelect from '../../../shared/CronusSelect';
import http from '../../../resources/http';
import {
  RISK_TC_LIST,
  RISK_TC_BY_PK,
  UPLOAD_RISK_TC_MAPPING_EXCEL,
} from '../../../api/excelUpload';
import {
  THREAT_CATEGORIES,
  THREAT_VECTORS_LIST,
} from '../../../api/threatCatalog';
import { downloadExcelDataRiskTC } from '../../../utils/ExportExcelUtility';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import SearchHandler from '../../../shared/SearchHandler';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import swal from 'sweetalert';
import ModalHandler from './ModalHandler';
import ModalFileUpload from '../../../shared/ModalFileUpload';
import { DELETE_THREAT_RISK_MAPPING, GET_TC_MAPPING, GET_THREAT_RISK_MAPPING, EXPORT_THREAT_RISK_MAPPING,IMPORT_THREAT_RISK_MAPPING } from '../../../graphql/superAdmin/query/query';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';

const ViewTcMapping = () => {
  const url_get = RISK_TC_LIST;
  const url_by_pk = RISK_TC_BY_PK;
  const UploadURL = UPLOAD_RISK_TC_MAPPING_EXCEL;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [threatCategoryDropdownOptions, setThreatCategoryDropdownOptions] =
    useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);

  // for file upload
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  // search
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = [
    'threats',
    'threat_category',
    'path',
    'cost_category',
  ];
  const debounceDelay = 400; // Debounce delay in milliseconds

  async function fetchData() {
    setFetchLoading(true);
    try {
      const response = await http.get(url_get);
      const listOfThreatCategory = await http.get(THREAT_CATEGORIES);

      setData(response.data);
      const threatCategoryOptions = listOfThreatCategory.data?.map(
        (item) => item.name
      );
      setThreatCategoryDropdownOptions(threatCategoryOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setFetchLoading(false);
    }
  }
  // useEffect(() => {
  // 	          // fetchData();
  // }, []);

  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch } = useQuery(GET_THREAT_RISK_MAPPING, {
    variables: { limit: pageSize, offset: page * pageSize },
  });

  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };

  const totalItems = data?.threat_risk_mapping_aggregate?.count || 0;
  const transformedData = data?.threat_risk_mapping?.map((item) => ({
    id: item.id,
    threat: item.threats?.name,
    cost_category: item.cost_category,
    threat_category: item.threat_category,
    operational: item.operational,
    financial: item.financial,
    health_and_safety: item.health_and_safety,
    environment: item.environment,
    comment: item.comment
  })) || [];


  console.log(transformedData, 'data');

  const filteredData = SearchHandler(
    transformedData,
    searchValue,
    fieldsToFilterBy,
    debounceDelay
  );
  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };
  
  const [del] = useMutation(DELETE_THREAT_RISK_MAPPING);
  const [exportThreatRiskMapping] = useMutation(EXPORT_THREAT_RISK_MAPPING);
  const [importThreatRiskMapping] = useMutation(IMPORT_THREAT_RISK_MAPPING);
  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });

      if (data?.delete_threat_risk_mapping?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };

  const handleDownload = async () => {
    const { data } = await exportThreatRiskMapping({
      variables: {
        inputs: {
          extension: "xlsx"
        } 
      }
    });

    const fileContent = data?.export_threat_risk_mapping[0]?.file_content;
    if (fileContent) {
      const binaryData = atob(fileContent);
      console.log("binaryData ", binaryData);
      
      // Create a Uint8Array from the binary data
      const bytes = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        bytes[i] = binaryData.charCodeAt(i);
      }
      
      // Create blob from the bytes
      const blob = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      console.log("blob ", blob);
      
      // Create download link and trigger download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'industry_data_export.xlsx';
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
    setFetchLoading(false);

  
  };

  return (
    <div className="main-container">
      <div className="table-title ">
        <span className="">Threat Risk Mapping</span>

        <div className="flex justify-between items-center space-x-4">
          <div class="">
            <button
              className="btn-add-new"
              onClick={() => setDropdownShow(true)}
            >
              <FilterListIcon />
            </button>
            {/* {dropdownShow ? (
							<CronusSelect
								options={uniqueThreatCategoryDDOptions}
								// onChange={handleCheckboxChangeItem}
							/>
						) : null} */}
          </div>
          <SearchInput
            searchValue={searchValue}
            setSearchValue={handleInputChange}
          />
          <button
            onClick={() => {
              setIsModalUploadOpen(true);
            }}
            className="btn_file_download"
          >
            <img
              alt="upload-avatar"
              className="w-7 h-7"
              src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
            />
          </button>
          <button className="btn_file_download">
            <svg
              onClick={() => handleDownload()}
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fill-rule="evenodd"
              fill="white"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>
          <button
            onClick={() => {
              setSelectedItem(null);
              setMode('add');
              setIsModalOpen(true);
            }}
            className="btn-add-new"
          >
            <AddIcon />
          </button>
        </div>
      </div>
      {fetchLoading ? (
        <LoadingComponent />
      ) : searchValue.length > 0 && filteredData.length === 0 ? (
        <div className="alert-no-data">
          No data available for
          <span className="search-value">{searchValue}</span>
          at the moment.
        </div>
      ) : (
        <CustomTable
          filteredData={filteredData}
          onEditClick={handleEdit}
          onDeleteClick={handleDelete}
          page={page}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          passedExcludeColumns={['__typename']}
        />
      )}

      {/* add and edit modal */}
      <ModalHandler
        importMutation={importThreatRiskMapping}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode={mode}
        size="xl"
        header="Threat Risk Mapping"
        selectedItemId={id}
        selectedItem={selectedItem}
        url_get={url_get}
        url_by_pk={url_by_pk}
        fetchData={refetch}
      />
      {/* for uploading new file */}

      <ModalFileUpload
        header={'Threat Risk Mapping'}
        UploadURL={UploadURL}
        importMutation={importThreatRiskMapping}
        fetchData={fetchData}
        isOpen={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
      />
    </div>
  );
};

export default ViewTcMapping;
