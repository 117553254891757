import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import http from "../../../resources/http";
import {
	THREAT_CATEGORIES,
	THREAT_CATEGORY_BY_PK,
} from "../../../api/threatCatalog";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import ThreatCategoryModalHandler from "./ThreatCategoryModalHandler";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";

const ThreatCategory = () => {
	const url_get_create = THREAT_CATEGORIES;
	const url_by_pk = THREAT_CATEGORY_BY_PK;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const [id, setId] = useState();
	const [data, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["difficulty_level", "value", "description"];

	//data
	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url_get_create);
			setData(response.data);
			setFetchLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setFetchLoading(false);
		}
	}

	// useEffect(() => {
	// 	          // fetchData();
	// }, []);

	const filteredData = SearchHandler(data, searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			await http.delete(`${url_by_pk}${id}`);
			// fetchData();
			setFetchLoading(false);
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			swal("Failed", "Failed to delete item", "error");
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	return (
		<div className="main-container">
			<div className="table-container">
				<div className="table-title ">
					<div className="table-title-text">
						<span>Threat Category </span>
						<button className="tooltip">
							<FaInfoCircle className="btn-info icon" />
							<span className="tooltip-text">
								This table contains Threat Category.
							</span>
						</button>
					</div>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
							className="btn-add-new tooltip"
						>
							<FaPlus className="icon" />
							<span className="tooltip-text">Add New Data</span>
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
					/>
				)}{" "}
			</div>
			{/* add and edit modal */}
			<ThreatCategoryModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="lg"
				selectedItemId={id}
				selectedItem={selectedItem}
				header="Threat Category"
				url_get_create={url_get_create}
				url_by_pk={url_by_pk}
				fetchData={fetchData}
			/>
		</div>
	);
};

export default ThreatCategory;
