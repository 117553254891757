import { gql } from '@apollo/client';
export const GET_AAPLICATION_ACCESSIBILITY = gql`
  query GET_AAPLICATION_ACCESSIBILITY($limit: Int!, $offset: Int) {
    application_accessibility(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    application_accessibility_aggregate {
      count
    }
  }
`;
export const GET_BROADCAST_POPULATION_OF_USERS = gql`
  query GET_BROADCAST_POPULATION_OF_USERS($limit: Int!, $offset: Int) {
    user_broadest_population(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    user_broadest_population_aggregate {
      count
    }
  }
`;
export const GET_BUSINESS_CRITICALITY = gql`
  query GET_BUSINESS_CRITICALITY($limit: Int!, $offset: Int) {
    business_criticality_rpo(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    business_criticality_rpo_aggregate {
      count
    }
  }
`;
export const GET_BUSINESS_IMPACT = gql`
  query GET_BUSINESS_IMPACT($limit: Int!, $offset: Int) {
    business_impact_config(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    business_impact_config_aggregate {
      count
    }
  }
`;
export const GET_DATA_CLASSIFICATION = gql`
  query GET_DATA_CLASSIFICATION($limit: Int!, $offset: Int) {
    data_classification_level(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    data_classification_level_aggregate {
      count
    }
  }
`;
export const GET_HOSTING_MODEL = gql`
  query GET_HOSTING_MODEL($limit: Int!, $offset: Int) {
    hosting_model(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    hosting_model_aggregate {
      count
    }
  }
`;
export const GET_DATA_SOURCE = gql`
  query GET_DATA_SOURCE($limit: Int!, $offset: Int) {
    data_source(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    data_source_aggregate {
      count
    }
  }
`;
export const GET_USER_INTERFACE = gql`
  query GET_USER_INTERFACE($limit: Int!, $offset: Int) {
    user_interface_accessibility(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    user_interface_accessibility_aggregate {
      count
    }
  }
`;
export const GET_USER_POPULATION = gql`
  query GET_USER_POPULATION($limit: Int!, $offset: Int) {
    user_population(limit: $limit, offset: $offset) {
      id
      description
      label
    }
    application_accessibility_aggregate {
      count
    }
  }
`;
