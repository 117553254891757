import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import http from "../../../resources/http";
import { USER_POPULATION } from "../../../api/configurations";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import ModalHandler from "./ModalHandler";
import { GET_USER_POPULATION } from "../../../graphql/superAdmin/query/businessProcessConfiguration/query";
import { useMutation, useQuery } from "@apollo/client";
import {
	ADD_USER_POPULATION,
	DELETE_USER_POPULATION,
	UPDATE_USER_POPULATION,
} from "../../../graphql/superAdmin/mutation/businessProcessConfiguration/mutation";
import toast from "react-hot-toast";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";

const UserPopulation = () => {
	const url = USER_POPULATION;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add");
	const [selectedItem, setSelectedItem] = useState(null);

	const [id, setId] = useState();
	const [datas, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["label", "value", "description"];
	const [page, setPage] = useState(0);
	const pageSize = 10; // Number of items per page

	const { data, loading, error, refetch } = useQuery(GET_USER_POPULATION, {
		variables: { limit: pageSize, offset: page * pageSize },
	});
	const handlePageChange = ({ selected }) => {
		setPage(selected);
		refetch({ limit: pageSize, offset: selected * pageSize });
	};
	const totalItems = data?.user_population_aggregate?.count || 0;

	const [update] = useMutation(UPDATE_USER_POPULATION);
	const [create] = useMutation(ADD_USER_POPULATION);
	const [del] = useMutation(DELETE_USER_POPULATION);

	//data
	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url);
			setData(response.data);
			setFetchLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setFetchLoading(false);
		}
	}

	const filteredData = SearchHandler(
		data?.user_population ?? [],
		searchValue,
		fieldsToFilterBy
	);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			const { data } = await del({
				variables: { id: parseInt(id) },
			});

			if (data?.delete_user_population?.affected_rows > 0) {
				setFetchLoading(false);
				refetch();
				toast.success("It has been deleted successfully");
			} else {
			}
		} catch (error) {
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	return (
		<main>
			<div className="main-container">
				<div className="table-container">
					<div className="table-title ">
						<div className="table-title-text">
							<span>User Population </span>
							<button className="tooltip">
								<FaInfoCircle className="btn-info icon" />
								<span className="tooltip-text">
									This table contains User Population.
								</span>
							</button>
						</div>
						<div className="flex justify-between items-center space-x-4">
							<SearchInput
								searchValue={searchValue}
								setSearchValue={handleInputChange}
							/>
							{/* Add New Data Button */}
							<button
								onClick={() => {
									setSelectedItem(null);
									setMode("add");
									setIsModalOpen(true);
								}}
								className="btn-add-new tooltip"
							>
								<FaPlus className="icon" />
								<span className="tooltip-text">Add New Data</span>
							</button>
						</div>
					</div>
					{/* table */}
					{loading ? (
						<LoadingComponent />
					) : searchValue.length > 0 && filteredData.length === 0 ? (
						<div className="alert-no-data">
							No data available for
							<span className="search-value">{searchValue}</span>
							at the moment.
						</div>
					) : (
						<CustomTable
							filteredData={filteredData}
							onEditClick={handleEdit}
							onDeleteClick={handleDelete}
							page={page}
							pageSize={pageSize}
							totalItems={totalItems}
							onPageChange={handlePageChange}
							passedExcludeColumns={["__typename"]}
						/>
					)}
				</div>
				{/* add and edit modal */}
				<ModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="xl"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="User Population"
					url={url}
					fetchData={refetch}
					createMutation={create}
					updateMutation={update}
				/>
			</div>
		</main>
	);
};

export default UserPopulation;
