import React, { createContext, useState, useEffect } from "react";

const ThemeContext = createContext();

const getInitialTheme = () => {
	const savedTheme = localStorage.getItem("theme");
	if (savedTheme) return savedTheme;

	// Check system preference
	const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
	return prefersDark ? "dark" : "light";
};

const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState(getInitialTheme);

	useEffect(() => {
		// Ensure document classList updates only when necessary
		document.documentElement.classList.toggle("dark", theme === "dark");
		document.documentElement.classList.toggle("light", theme === "light");

		// Update localStorage
		localStorage.setItem("theme", theme);
	}, [theme]);

	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};

export { ThemeContext, ThemeProvider };
