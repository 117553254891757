import React, { useCallback, useState } from "react";
import CustomTable from "../../shared/CustomTable";
import ManageTable from "../../shared/ManageTable/ManageTable";
import ThreatSeverityLegend from "../../shared/Legend/ThreatSeverityLegend";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";
// import http from "../../resources/http";
import {
	THREAT_VECTORS_LIST,
	UPDATE_THREAT_VECTOR,
} from "../../api/threatCatalog";
import { UPLOAD_THREAT_VECTOR_EXCEL } from "../../api/excelUpload";
// import { downloadThreatRepository } from "../../utils/ExportExcelUtility";
import SearchInput from "../../shared/SearchInput/SearchInput";
import SearchHandler from "../../shared/SearchHandler";
import LoadingComponent from "../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import swal from "sweetalert";
import ModalHandler from "./ModalHandler";
import ModalFileUpload from "../../shared/ModalFileUpload";
import ThreatRepositoryModal from "../../shared/ThreatRepositoryModal/ThreatRepositoryModal";
import axios from "axios";
import { useMutation, useQuery } from "@apollo/client";
import {
	ADD_THREAT_VECTOR,
	DELETE_THREAT_VECTOR,
	GET_THREATS,
} from "../../graphql/superAdmin/query/query";
import {
	EXPORT_THREAT,
	IMPORT_THREAT,
} from "../../graphql/superAdmin/mutation/files/mutation";
import useExportAndDownload from "../../hooks/useExportAndDownload";
import toast from "react-hot-toast";

const ThreatRepositoryMain = ({
	deleteThreatQuery,
	aggregate,
	name,
	query,
	addThreat,
	updateThreat,
	deleteThreat,
}) => {
	const UploadURL = UPLOAD_THREAT_VECTOR_EXCEL;
	const url_create = THREAT_VECTORS_LIST;
	const url_get = THREAT_VECTORS_LIST;
	const url_by_pk = "";
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleModalShowHide = () => setIsModalOpen(!isModalOpen);

	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();

	const [selectedSeverityLevel, setSelectedSeverityLevel] = useState("low");

	const [fetchLoading, setFetchLoading] = useState(false);
	// for file upload
	const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

	const [exportThreat] = useMutation(EXPORT_THREAT); // Specific mutation
	const { exportAndDownloadFile } = useExportAndDownload();
	const [fileExtension, setFileExtension] = useState("xlsx");

	const handleExport = () => {
		exportAndDownloadFile(exportThreat, fileExtension, "Threat Repository"); // Pass the mutation
	};
	const [dataPerPage, setDataPerPage] = useState(5);
	const [expandedSections, setExpandedSections] = useState({
		rows: false,
		severity: false,
	});

	// Function to toggle expanded sections (Rows Per Page & Severity Level)
	const toggleSection = (section) => {
		setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
	};
	const columnExplanations = {
		"Threat Severity":
			"Indicates the severity of a threat (Low, Moderate, High, Critical).",
		Name: "The name of the security threat.",
		Description: "A brief description of the threat.",
		"Event Frequency": "The number of times this threat has been observed.",
		"Cost Of Incident": "Estimated financial impact of this threat.",
		"Percent Of Breach":
			"Percentage of security breaches related to this threat.",
		"Lost Business Cost":
			"Financial losses due to security breaches caused by this threat.",
	};

	// search
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = [
		"name",
		"availability",
		"category",
		"discoverability",
		"exploitablity",
		"integrity",
		"confidentiality",
		"reproducibility",
		"threat_senario",
		"description",
	];

	// const dataPerPage = 6;
	const debounceDelay = 400;
	const { loading, error, data, refetch } = useQuery(query, {
		variables:
			name === "threat"
				? { limit: 10 }
				: {
						clientId: parseInt(
							JSON.parse(localStorage.getItem("cyber-minds"))?.user?.client?.id
						),
				  },
	});

	const filteredData = SearchHandler(
		data?.[name] ?? [],
		searchValue,
		fieldsToFilterBy,
		debounceDelay
	);

	// Handle the edit click from the table
	const handleEdit = useCallback((id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	}, []);

	const handleInputChange = useCallback((newValue) => {
		setSearchValue(newValue);
	}, []);

	const handleDelete = async (itemId) => {
		setFetchLoading(true);
		try {
			const { data } = await deleteThreat({
				variables: { id: parseInt(itemId) },
			});
			if (data?.[deleteThreatQuery]?.affected_rows > 0) {
				setFetchLoading(false);
				refetch();
				toast.success("It has been deleted successfully");
				// Optionally refetch or update the data in your component state here
				//           // fetchData();
			} else {
				throw new Error(data?.delete_[name]?.message);
			}
		} catch (error) {
			console.error("Failed to delete item:", error);
			setFetchLoading(false);
			toast.error("Failed to delete item");
			refetch();
		}
	};
	const [importThreat] = useMutation(IMPORT_THREAT); //100, 100, 1000, 400
	const [fileContent, setFileContent] = useState("");

	// Function to convert file to Base64
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = () => {
			const base64 = reader.result.split(",")[1]; // Get Base64 string after the comma
			setFileContent(base64); // Set the base64 string in state
		};

		reader.onerror = (error) => {
			console.error("File reading error:", error);
		};

		if (file) {
			reader.readAsDataURL(file); // Converts file to base64 encoded string
		}
	};

	const handleSubmit = async () => {
		try {
			const response = await importThreat({
				variables: {
					file_content: fileContent,
					inputs: {
						/* Add any additional input fields here */
					},
				},
			});
			console.log("Import Response:", response.data.import_threat.data.total);
		} catch (error) {
			console.error("Error importing threat:", error);
		}
	};
	return (
		<div className="main-container">
			<div className="table-container">
				<div className="table-title">
					<div className="table-title-text">
						<span>Threat Repository </span>
						<button className="tooltip">
							<FaInfoCircle className="btn-info icon" />
							<span className="tooltip-text">
								This table contains threat data categorized by severity.
							</span>
						</button>
					</div>

					<div className="flex justify-between items-center space-x-4">
						{/* Search Input */}
						<SearchInput
							searchValue={searchValue}
							setSearchValue={setSearchValue}
						/>

						{/* Upload Button */}


						<button
						onClick={() => {
							setIsModalUploadOpen(true);
						}}
						className="btn_file_download"
					>
						<img
							alt="upload-avatar"
							className="w-7 h-7"
							src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
						/>
					</button>
						{/* <button
							onClick={() => setIsModalUploadOpen(true)}
							className="btn_file_upload tooltip"
						>
							<FaUpload className="icon" />
							<span className="tooltip-text">Upload File</span>
						</button> */}

						{/* Download Button */}
						{/* <button
							onClick={handleExport}
							className="btn_file_download tooltip"
						>
							<FaDownload className="icon" />
							<span className="tooltip-text">Download File</span>
						</button> */}

						<button className="btn_file_download">
									<svg
									onClick={() =>
										handleExport()
									
									}

							
									xmlns="http://www.w3.org/2000/svg"
									height={28}
									width={28}
									fill-rule="evenodd"
									fill="white"
									clip-rule="evenodd"
									image-rendering="optimizeQuality"
									shape-rendering="geometricPrecision"
									text-rendering="geometricPrecision"
									viewBox="0 0 17639 17639"
									>
									<path
										fill="#1e7145"
										d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
									/>
									<path
										fill="#1e7145"
										d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
									/>
									</svg>
								</button>

						{/* Add New Threat Button */}
						<button
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
							className="btn-add-new tooltip"
						>
							<FaPlus className="icon" />
							<span className="tooltip-text">Add New Data</span>
						</button>
						<ManageTable
							dataPerPage={dataPerPage}
							setDataPerPage={setDataPerPage}
							selectedSeverityLevel={selectedSeverityLevel}
							setSelectedSeverityLevel={setSelectedSeverityLevel}
							setRowsPerPage={setDataPerPage}
							expandedSections={expandedSections}
							toggleSection={toggleSection}
						/>
					</div>
				</div>
				{/* Table or Loading */}
				{loading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value"> {searchValue} </span>
						at the moment.
					</div>
				) : (
					<>
						{/* Table */}
						<CustomTable
							filteredData={filteredData}
							selectedSeverityLevel={selectedSeverityLevel}
							onEditClick={handleEdit}
							onDeleteClick={handleDelete}
							dataPerPage={dataPerPage}
							columnExplanations={columnExplanations}
							passedExcludeColumns={[
								"impact",
								"likelihood",
								"category",
								"client",
								"discovery_level",
								"exploitable_level",
								"reproducibility_level",
								"confidentiality_level",
								"integrity_level",
								"availability_level",
								"init_id",
								"__typename",
								// 'percent_of_breach',
								// 'lost_business_cost',
							]}
						/>
					</>
				)}
			</div>
			{/* threat severity legend*/}
			<ThreatSeverityLegend />

			{/* add and edit modal */}
			<ModalHandler
				isOpen={false}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="xl"
				header="Threat Vector"
				selectedItemId={id}
				selectedItem={selectedItem}
				url_create={url_create}
				url_get={url_get}
				url_by_pk={url_by_pk}
				// fetchData={fetchData}
			/>
			<ThreatRepositoryModal
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
					setSelectedItem(null);
				}}
				updateThreat={updateThreat}
				addThreat={addThreat}
				mode={mode}
				size="xl"
				header="Controls Catalog"
				selectedItemId={id}
				selectedItem={selectedItem}
				url_create={url_get}
				url_get={url_get}
				url_by_pk={url_by_pk}
				fetchData={refetch}
			/>
			{/* {isModalOpen && (
				<ThreatRepositoryModal
					selectedItemId={id}
					action={mode}
					handleModalShowHide={handleModalShowHide}
					payload={selectedItem}
					url={url_create}
					modifyUrl={UPDATE_THREAT_VECTOR}
					fetchData={refetch}
				/>
			)} */}

			{/* for uploading new file */}
			<ModalFileUpload
				header="Threat Vector"
				UploadURL={UploadURL}
				importMutation={importThreat}
				// fetchData={fetchData}
				isOpen={isModalUploadOpen}
				onClose={() => setIsModalUploadOpen(false)}
			/>
		</div>
	);
};

export default ThreatRepositoryMain;
