import React, { useState, useEffect, useRef } from "react";
import { FaCog, FaPlus, FaMinus, FaSlidersH } from "react-icons/fa";
import "./manageTable.css";

const ManageTable = ({
	dataPerPage,
	setDataPerPage,
	selectedSeverityLevel,
	setSelectedSeverityLevel,
}) => {
	const [isManageOpen, setIsManageOpen] = useState(false);
	const [expandedSections, setExpandedSections] = useState({
		rows: false,
		severity: false,
	});
	const dropdownRef = useRef(null);
	// Track mouse leave delay
	let closeTimeout = null;

	// Toggle dropdown visibility
	const handleToggleDropdown = () => {
		setIsManageOpen(!isManageOpen);
	};

	// Close dropdown when clicking outside
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsManageOpen(false);
		}
	};

	// Delayed close on mouse leave
	const handleMouseLeave = () => {
		closeTimeout = setTimeout(() => {
			setIsManageOpen(false);
		}, 250);
	};

	// Prevent closing immediately when hovering back
	const handleMouseEnter = () => {
		clearTimeout(closeTimeout);
	};

	// Toggle sections
	const toggleSection = (section) => {
		setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
	};

	return (
		<div
			className="relative"
			ref={dropdownRef}
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
		>
			{/* Manage Settings Button (Filter Icon) */}
			<button
				className="btn-manage"
				onClick={handleToggleDropdown}
				aria-expanded={isManageOpen}
				aria-label="Manage Table Settings"
			>
				<FaSlidersH className="icon" />
			</button>

			{/* Dropdown Menu */}
			{isManageOpen && (
				<div className="manage-dropdown" onClick={handleClickOutside}>
					{/* Rows Per Page */}
					<div className="dropdown-item">
						<div
							className="dropdown-header"
							onClick={() => toggleSection("rows")}
						>
							<span>Rows Per Page</span>
							<button
								className="toggle-btn"
								aria-expanded={expandedSections.rows}
								aria-controls="rows-options"
							>
								{expandedSections.rows ? <FaMinus /> : <FaPlus />}
							</button>
						</div>
						{expandedSections.rows && (
							<div id="rows-options" className="options-group">
								{[5, 10, 15].map((num) => (
									<button
										key={num}
										className={`option-btn ${
											dataPerPage === num ? "selected" : ""
										}`}
										onClick={() => setDataPerPage(num)}
										aria-pressed={dataPerPage === num}
									>
										{num}
									</button>
								))}
							</div>
						)}
					</div>

					{/* Severity Level */}
					<div className="dropdown-item">
						<div
							className="dropdown-header"
							onClick={() => toggleSection("severity")}
						>
							<span>Severity Level</span>
							<button
								className="toggle-btn"
								aria-expanded={expandedSections.severity}
								aria-controls="severity-options"
							>
								{expandedSections.severity ? <FaMinus /> : <FaPlus />}
							</button>
						</div>
						{expandedSections.severity && (
							<div id="severity-options" className="options-group">
								{["Low", "Medium", "High"].map((level) => (
									<button
										key={level}
										className={`option-btn ${
											selectedSeverityLevel === level.toLowerCase()
												? "selected"
												: ""
										}`}
										onClick={() =>
											setSelectedSeverityLevel(level.toLowerCase())
										}
										aria-pressed={selectedSeverityLevel === level.toLowerCase()}
									>
										{level}
									</button>
								))}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ManageTable;
