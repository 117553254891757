import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import swal from 'sweetalert';
import toast from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import TableLoadingAnimation from '../shared/TableLoading/TableLoadingAnimation';
import {
	GET_BUSINESS_PROCESS,
	DELETE_BUSINESS_PROCESS,
} from '../graphql/client/query';
import { ADD_BUSINESS_PROCESS, UPDATE_BUSINESS_PROCESS } from '../graphql/superAdmin/mutation/businessProcessConfiguration/mutation';
import { getBackgroundColor } from '../utils/getBackgroundColor';
import { calculatePercentageReduction } from '../utils/helpers';
import ModalHandler from './ModalHandlerBusinessProcess';

const ManageBusinessProcessAdmin = ({ isPopUp }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalEditOpen, setIsModalEditOpen] = useState(false);
	const [mode, setMode] = useState('add'); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [pageNumber, setPageNumber] = useState(0);
	const itemsPerPage = 4;
	const pagesVisited = pageNumber * itemsPerPage;

	// Fetch business processes
	const { data, loading, refetch } = useQuery(GET_BUSINESS_PROCESS, {
		variables: { limit: 100 },
	});

	// Mutations
	const [addBusinessProcess] = useMutation(ADD_BUSINESS_PROCESS);
	const [updateBusinessProcess] = useMutation(UPDATE_BUSINESS_PROCESS);
	const [deleteBusinessProcessMutation] = useMutation(DELETE_BUSINESS_PROCESS);

	const pageCount = Math.ceil((data?.business_process?.length || 0) / itemsPerPage);

	const changePage = ({ selected }) => setPageNumber(selected);

	const handleEditClick = (sector) => {
		setSelectedItem(sector);
		setMode('edit');
		setIsModalEditOpen(true);
	};

	const deleteBusinessProcess = async (id) => {
		try {
			const response = await deleteBusinessProcessMutation({
				variables: { id: parseInt(id, 10) },
			});
			if (response.data.delete_business_process.affected_rows > 0) {
				toast.success('Business process deleted successfully');
				refetch();
			} else {
				toast.error('No business process found with the provided id');
			}
		} catch (err) {
			toast.error('Error deleting business process');
			console.error('Error:', err);
		}
	};

	const DisplayData = data?.business_process
		?.slice(pagesVisited, pagesVisited + itemsPerPage)
		?.map((sector) => (
			<tr key={sector.id}>
				<td>{sector.name}</td>
				<td>
					<p
						className="p-4 text-center rounded-xl w-36"
						style={{
							backgroundColor: getBackgroundColor(sector?.sensitivity_level?.toLowerCase()),
							color: 'black',
						}}
					/>
				</td>
				<td>
					<p
						className="p-4 text-center rounded-xl w-36"
						style={{
							backgroundColor: getBackgroundColor(sector?.application_risk_profile?.low),
							color: 'black',
						}}
					/>
				</td>
				<td>{calculatePercentageReduction(12, 20) + '%'}</td>
				{!isPopUp && (
					<td>
						<IconButton
							onClick={() => {
								setIsModalEditOpen(true);
								handleEditClick(sector);
							}}
						>
							<EditIcon />
						</IconButton>
						<IconButton onClick={() => deleteBusinessProcess(sector.id)}>
							<DeleteIcon />
						</IconButton>
					</td>
				)}
			</tr>
		));

	return (
		<div className="p-10">
			<div className="table-title">
				<span className="font-bold">Business Processes</span>
				<div className="flex justify-between items-center space-x-4">
					<button
						className="btn-add-new"
						onClick={() => {
							setSelectedItem(null);
							setMode('add');
							setIsModalOpen(true);
						}}
					>
						<AddIcon />
					</button>
				</div>
			</div>

			<div>
				<table className="tables">
					<thead>
						<tr>
							<th>Business process name</th>
							<th>Application Risk Profile</th>
							<th>Residual Risk</th>
							<th>Percentage reduction</th>
							{!isPopUp && <th>Manage</th>}
						</tr>
					</thead>
					<tbody>{DisplayData}</tbody>
				</table>
				{loading && <TableLoadingAnimation />}
				{data?.business_process?.length > itemsPerPage && (
					<ReactPaginate
						previousLabel={'<'}
						nextLabel={'>'}
						activeClassName={'paginationActive'}
						pageCount={pageCount}
						onPageChange={changePage}
						containerClassName={'paginationBttns'}
						previousLinkClassName={'previousBttn'}
						nextLinkClassName={'nextBttn'}
						disabledClassName={'paginationDisabled'}
					/>
				)}
			</div>

			<ModalHandler
				isOpen={isModalOpen || isModalEditOpen}
				mode={mode}
				onClose={() => {
					setIsModalOpen(false);
					setIsModalEditOpen(false);
				}}
				header="Business Process"
				selectedItem={selectedItem}
				createMutation={addBusinessProcess}
				updateMutation={updateBusinessProcess}
				fetchData={refetch}
			/>
		</div>
	);
};

export default ManageBusinessProcessAdmin;