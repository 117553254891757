import React from "react";
import { useSelector } from "react-redux";
import useNumberFormatter from "../../utils/useNumberFormatter";
import Chart from "react-apexcharts";

const FinancialImpact = ({ data }) => {
	const client_currency = useSelector(
		(state) => state.user?.client_obj?.currency
	);

	const client_currency_symbol =
		client_currency && client_currency === "US DOLLAR"
			? "$"
			: client_currency === "EURO"
			? "€"
			: client_currency === "ETB"
			? "฿"
			: client_currency === "YEN"
			? "¥"
			: client_currency === "WON"
			? "₩"
			: client_currency === "POUND"
			? "£"
			: client_currency === "PESO"
			? "₱"
			: "$";

	const costOfBreach = parseInt(data?.breach_cost);
	const costOfIncident = parseInt(data?.incident_cost);
	console.log(costOfBreach, costOfIncident, "pairs of data");
	const options = {
		labels: ["Breach", "Incident"],
		colors: ["var(--color-chart-one)", "var(--color-chart-two)"],
		legend: {
			show: true,
			position: "bottom",
		},
		tooltip: {
			y: {
				formatter: (val) => `${client_currency_symbol}${val}`,
			},
		},
		plotOptions: {
			pie: {
				expandOnClick: false,
			},
		},
	};

	const series = [costOfBreach, costOfIncident];

	return (
		<Chart
			className="p-4"
			options={options}
			series={series}
			type="pie"
			height="400"
		/>
	);
};

export default FinancialImpact;
