import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import http from "../../../resources/http";
import { Button, Form, Modal } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import { downloadConsensus } from "../../../utils/ExportExcelUtility";
import UploadConsensus from "./UploadConsensus";
import swal from "sweetalert";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import {
	CONSENSUS_ASSESSMENT_QUESTIONNAIRE_LIST,
	CONSENSUS_ASSESSMENT_QUESTIONNAIRE_ADD,
	CONSENSUS_ASSESSMENT_TITLES,
} from "../../../api/excelUpload";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import CustomTable from "../../../shared/CustomTable";
import { DELETE_CONSENSUS_ASSESSMENT, DELETE_COST, IMPORT_CONSENSUS_ASSESSMENT ,EXPORT_CONSENSUS_ASSESSMENT} from "../../../graphql/superAdmin/mutation/mutation";
import SearchHandler from "../../../shared/SearchHandler";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CONSENSUS_ASSESSMENT_QUESTIONNAIRE } from "../../../graphql/superAdmin/query/query";
import toast from "react-hot-toast";
import ModalFileUpload from "../../../shared/ModalFileUpload";
import ModalHandler from "./ModalHandler";
const ViewConsensus = () => {
	const [title, setTitle] = useState("Application & Interface Security - AIS");
	const [searchValue, setSearchValue] = useState("");
	const { data, loading, error, refetch: fetchData } = useQuery(GET_CONSENSUS_ASSESSMENT_QUESTIONNAIRE, {
		variables: { limit: 100 },
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();
	const [fetchLoading, setFetchLoading] = useState(false);

	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 6;
	const pagesVisited = pageNumber * usersPerPage;
	const pageCount = Math.ceil(10 / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const [selected, setSelected] = useState([]);

	const [fetchTitle, setFetchedTitle] = useState([]);

	const [showAdd, setShowAdd] = useState(false);
	const handleShowAdd = () => setShowAdd(!showAdd);

	const [consensus, setConsensus] = useState({
		question_id: "",
		consensus_assessment_question: "",
		control_specification: "",
		control_title: "",
		control_id: "",
	});

	//toggle add
	const [showFileUploader, setShowFileUploader] = useState(false);
	const toggleFileUploader = () => setShowFileUploader(!showFileUploader);
	const fieldsToFilterBy = [""];

	const filteredData = SearchHandler(data?.consensus_assessment ?? [], searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const [del] = useMutation(DELETE_CONSENSUS_ASSESSMENT);
	const [importConsensus] = useMutation(IMPORT_CONSENSUS_ASSESSMENT);
	const [exportConsensus] = useMutation(EXPORT_CONSENSUS_ASSESSMENT);
	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			const { data } = await del({
				variables: { id: parseInt(id) },
			});

			if (data?.delete_consensus_assessment?.affected_rows > 0) {
				setFetchLoading(false);
				fetchData();
				toast.success('It has been deleted successfully');
			} else {
			}
		} catch (error) {
			setFetchLoading(false);
			console.error('Failed to delete item:', error);
		}
	};

	const handleImport = async (file) => {
		try {
			const { data } = await importConsensus({
				variables: { 
					inputs: { 
						file,
						// Convert any list fields to JSON strings before sending
						transformFields: {
							related_controls: (value) => JSON.stringify(value)
						}
					} 
				}
			});
			
			if (data?.import_consensus_assessment?.success) {
				toast.success('Import successful');
				fetchData();
			}
		} catch (error) {
			console.error('Import error:', error);
			toast.error('Failed to import data');
		}
	};


	const handleExport = async () => {
		const { data } = await exportConsensus({
			variables: { 
				inputs: {
					extension: "xlsx"
				}
			}
		});
		
		const fileContent = data?.export_consensus_assessment?.data[0]?.file_content;
		if (!fileContent) throw new Error("No file content received.");

		const fileExtension = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		const byteCharacters = atob(fileContent);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: `application/${fileExtension}` });

		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = `Consensus.xlsx`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		toast.success("File downloaded successfully!");
	};

	return (
		<div className="main-container">
			<div className="table-title ">
				<span className="font-bold ">Consensus Data</span>{" "}
				<div className="w-64">
					<label
						for="countries"
						class="block mb-2 text-sm font-semibold text-white dark:text-gray-400"
					>
						Select Title
					</label>
					<select
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
						id="countries"
						class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					>
						{fetchTitle?.map((x) => (
							<option value={x}>{x}</option>
						))}
					</select>
				</div>{" "}
				<div className="flex justify-between items-center space-x-4">
					<SearchInput
						searchValue={searchValue}
						setSearchValue={handleInputChange}
					/>
					<button
						onClick={() => toggleFileUploader()}
						className="btn_file_download"
					>
						<img
							alt="upload-avatar"
							className="w-7 h-7"
							src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
						/>
					</button>

					<button className="btn_file_download">
						<svg
							onClick={() =>
								handleExport()
							}
							xmlns="http://www.w3.org/2000/svg"
							height={28}
							width={28}
							fill-rule="evenodd"
							fill="white"
							clip-rule="evenodd"
							image-rendering="optimizeQuality"
							shape-rendering="geometricPrecision"
							text-rendering="geometricPrecision"
							viewBox="0 0 17639 17639"
						>
							<path
								fill="#1e7145"
								d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
							/>
							<path
								fill="#1e7145"
								d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
							/>
						</svg>
					</button>
					<button
						onClick={(e) => {
							setSelectedItem(null);
							setMode("add");
							setIsModalOpen(true);
						}}
						className="btn-add-new"
					>
						<AddIcon />
					</button>
				</div>
			</div>

			{fetchLoading ? (
				<LoadingComponent />
			) : searchValue.length > 0 && filteredData.length === 0 ? (
				<div className="alert-no-data">
					No data available for
					<span className="search-value">{searchValue}</span>
					at the moment.
				</div>
			) : (
				<CustomTable
					filteredData={filteredData}
					onEditClick={handleEdit}
					onDeleteClick={handleDelete}
					passedExcludeColumns={['__typename']}
				/>
			)}

			{/* upload model */}

			{showFileUploader ? (

				<ModalFileUpload
					fetchData={fetchData}
					importMutation={importConsensus}
					isOpen={showFileUploader}
					onClose={() => setShowFileUploader(false)}
				/>
				// <UploadConsensus
				// 	fetchData={fetchData}
				// 	toggleFileUploader={toggleFileUploader}
				// />
			) : null}

			{/* adding model */}
			 <ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="xl"
				selectedItemId={id}
				selectedItem={selectedItem}
				header="Cost"
				fetchData={fetchData} 
			/>
		</div>
	);
};

export default ViewConsensus;
