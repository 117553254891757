import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { columns } from "../../../data/data";
import { usePathOptions } from "../../../hooks/usePathOptions";
import { baseURL } from "../../../resources/apiClient";
import http from "../../../resources/http";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { GET_EXPLOITABLE_PATH_QUERY } from "../../../graphql/client/query";
import { useQuery } from "@apollo/client";
import { GET_CLIENT_EXPLOITABLE_PATHS } from "../../../graphql/superAdmin/query/query";

const BreachList = ({ path }) => {
	const { pathOptions } = usePathOptions();
	const [pageNumber, setPageNumber] = useState(0);
	const [breachs, setBreachs] = useState([]);
	const usersPerPage = 10;
	const pagesVisited = pageNumber * usersPerPage;
	const processId = useSelector((state) => state.pid);

	const pageCount = Math.ceil(path?.breaches_count / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const isAdmin = false;
	// useEffect(() => {
	// 	http
	// 		.get(
	// 			`${baseURL}/business_process/exploitable-paths-formatted?businessProcessId=${processId}&isAdmin=${isAdmin}`
	// 		)
	// 		.then((response) => {
	// 			setBreachs(response.data);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// }, [processId]);
	const { data, loading, error, refetch } = useQuery(GET_CLIENT_EXPLOITABLE_PATHS, {
		variables: { limit: 100 },
	});

	console.log(data, 'data');

	const transformed = data?.client_exploitable_path?.map((items) => ({
		end: items?.exploitable_path?.end?.name ? items?.exploitable_path?.end?.name + "(B)" : null,
		middle: items?.exploitable_path?.middle?.name + "(I)",
		entry: items?.exploitable_path?.entry?.name + "(E)",
	}));

	const transformedData = transformed?.reduce((acc, curr) => {
		if (curr.entry && curr.middle && curr.end) {
			const foundIndex1 = acc.findIndex(
				(item) => item[0] === curr.entry && item[1] === curr.middle
			);

			if (foundIndex1 !== -1) {
				acc[foundIndex1][2]++;
			} else {
				acc.push([curr.entry, curr.middle, 1]);
			}

			const foundIndex2 = acc.findIndex(
				(item) => item[0] === curr.middle && item[1] === curr.end
			);

			if (foundIndex2 !== -1) {
				acc[foundIndex2][2]++;
			} else {
				acc.push([curr.middle, curr.end, 1]);
			}
		}
		return acc;
	}, []);

	console.log(transformed, 'transformed')
	return (
		<div className="p-4">
			<div
				style={{
					height: "25px",
				}}
			>
				<span className="p-2 text-xl font-semibold border-b-2">
					Potential Exploitable Paths
				</span>
			</div>
			{true ? (
				<div
					style={{
						height: "330px",
						minHeight: "330px",
						paddingTop: "8px",
						width: "100%",
					}}
				>
					<div
						style={{
							height: "25px",
							paddingBottom: "6px",
						}}
						className="flex justify-between font-semibold "
					>
						<div>Entry</div>
						<div>Middle (Incidents)</div>
						<div>End (Breaches)</div>
					</div>

					{transformedData && (
						<Chart
							sankeyCycle="true"
							chartType="Sankey"
							width="100%"
							height="300px"
							data={[columns, ...transformedData]}
							loader={<div>Loading Chart....</div>}
							options={pathOptions}
							className="google-Sankey"
						/>
					)}
				</div>
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
};

export default BreachList;
