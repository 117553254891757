import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import {
	THREAT_CATEGORIES,
	THREAT_VECTORS_LIST,
} from "../../../api/threatCatalog";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { GET_THREATS } from "../../../graphql/superAdmin/query/query";
import { ADD_THREAT_RISK_MAPPING, UPDATE_THREAT_RISK_MAPPING } from "../../../graphql/superAdmin/mutation/mutation";

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: "red" }}>*</span>}
		</Form.Label>
		<select className="form-control" value={value} onChange={onChange}>
			<option value="">Select {label.toLowerCase()}...</option>
			{options?.map((item) => (
				<option key={item.id} value={item.id}>
					{item.name}
				</option>
			))}
		</select>
		{error && <span style={{ color: "red" }}>{error}</span>}
	</Form.Group>
);

// Reusable Input Component
const Input = ({ label, type, value, onChange, error, required, placeholder }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: "red" }}>*</span>}
		</Form.Label>
		<input
			className="form-control"
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
		/>
		{error && <span style={{ color: "red" }}>{error}</span>}
	</Form.Group>
);

const ModalHandler = ({
	
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	fetchData,
}) => {
	const [fetchLoading, setFetchLoading] = useState(false);

	const initialState = {
		cost_category: "",
		threat_category: "",
		threats_pk: "",
		comment: "",
		operational: "",
		reputational: "",
		financial: "",
		health_and_safety: "",
		environment: "",
	};

	const [formData, setFormData] = useState(initialState);
	const [errors, setErrors] = useState({});

	const { data: threatData } = useQuery(GET_THREATS, {
		variables: { limit: 100, offset: 0 }
	});

	const [addThreatMapping] = useMutation(ADD_THREAT_RISK_MAPPING);
	const [updateThreat] = useMutation(UPDATE_THREAT_RISK_MAPPING);

	const handleChange = (key, value) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
		if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
	};

	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			setFormData({
				cost_category: selectedItem.cost_category || "",
				threat_category: selectedItem.threat_category || "",
				threats_pk: selectedItem.threats?.id || "",
				comment: selectedItem.comment || "",
				operational: selectedItem.operational || "",
				reputational: selectedItem.reputational || "",
				financial: selectedItem.financial || "",
				health_and_safety: selectedItem.health_and_safety || "",
				environment: selectedItem.environment || "",
			});
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const handleSubmit = async () => {
		const newErrors = {
			threats_pk: !formData.threats_pk ? "This field is required." : "",
		};

		setErrors(newErrors);

		if (Object.values(newErrors).every((error) => !error)) {
			setFetchLoading(true);

			const inputs = {
				created_at: new Date().toISOString(),
				threat_category: formData.threat_category,
				threats_pk: parseInt(formData.threats_pk),
				cost_category: formData.cost_category,
				operational: formData.operational,
				threat_path: formData.reputational,
				financial: formData.financial,
				health_and_safety: formData.health_and_safety,
				environment: formData.environment,
				comment: formData.comment,
			};

			try {
				let response;
				if (mode === "edit") {
					response = await updateThreat({
						variables: {
							id: parseInt(selectedItemId),
							input: inputs,
						},
					});
				} else {
					response = await addThreatMapping({
						variables: { inputs },
					});
				}

				const dynamicKey = Object.keys(response.data)[0];
				if (response.data[dynamicKey].affected_rows > 0) {
					toast.success(
						mode === "edit"
							? "It has been updated successfully"
							: "It has been added successfully"
					);
					resetForm();
					fetchData();
					onClose();
				}
			} catch (error) {
				if (error instanceof ApolloError) {
					const errorMessage = error.message.includes("duplicate key value")
						? "Control label already exists. Please choose a different one."
						: "An error occurred while processing your request.";
					toast.error(errorMessage);
				} else {
					console.error("Unexpected error:", error);
					toast.error("An unexpected error occurred.");
				}
			} finally {
				setFetchLoading(false);
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update ${header}` : `Add New ${header}`}
				</Modal.Title>
			</Modal.Header>
			{fetchLoading ? (
				<LoadingComponent />
			) : (
				<Modal.Body>
					<div className="modal-two-columns">
						<Form>
							<Input
								label="Threat Category"
								type="text"
								placeholder="Enter Threat Category..."
								value={formData.threat_category}
								onChange={(e) => handleChange("threat_category", e.target.value)}
								error={errors.threat_category}
							/>
							<Dropdown
								label="Threat"
								value={formData.threats_pk}
								onChange={(e) => handleChange("threats_pk", e.target.value)}
								options={threatData?.threat || []}
								error={errors.threats_pk}
								required
							/>
							<Input
								label="Cost Category"
								type="text"
								placeholder="Text..."
								value={formData.cost_category}
								onChange={(e) => handleChange("cost_category", e.target.value)}
								error={errors.cost_category}
							/>
							<Input
								label="Operational"
								type="text"
								placeholder="Text..."
								value={formData.operational}
								onChange={(e) => handleChange("operational", e.target.value)}
								error={errors.operational}
							/>
							<Input
								label="Reputational"
								type="text"
								placeholder="Text..."
								value={formData.reputational}
								onChange={(e) => handleChange("reputational", e.target.value)}
								error={errors.reputational}
							/>
							<Input
								label="Financial"
								type="text"
								placeholder="Text..."
								value={formData.financial}
								onChange={(e) => handleChange("financial", e.target.value)}
								error={errors.financial}
							/>
							<Input
								label="Health and Safety"
								type="text"
								placeholder="Text..."
								value={formData.health_and_safety}
								onChange={(e) => handleChange("health_and_safety", e.target.value)}
								error={errors.health_and_safety}
							/>
							<Input
								label="Environment"
								type="text"
								placeholder="Text..."
								value={formData.environment}
								onChange={(e) => handleChange("environment", e.target.value)}
								error={errors.environment}
							/>
							<Input
								label="Comment"
								type="textarea"
								placeholder="Text..."
								value={formData.comment}
								onChange={(e) => handleChange("comment", e.target.value)}
								error={errors.comment}
							/>
						</Form>
					</div>
				</Modal.Body>
			)}
			<Modal.Footer>
				<button className="btn-cancel" onClick={() => { onClose(); resetForm(); }}>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;