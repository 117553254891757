import React, { useEffect, useState } from 'react';
import CustomTable from '../../../shared/CustomTable';
import http from '../../../resources/http';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import SearchHandler from '../../../shared/SearchHandler';
import {
  RISK_AT_MAPPING_LIST,
  RISK_AT_FILE_UPLOAD,
} from '../../../api/excelUpload';
import { downloadExcelATMapping } from '../../../utils/ExportExcelUtility';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import ATAddEditHandler from './ATAddEditHandler';
import ModalFileUpload from '../../../shared/ModalFileUpload';
import APIService from '../../../services/apiService';
import swal from 'sweetalert';
import { GET_AT_MAPPING } from '../../../graphql/superAdmin/query/query';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_AT_MAPPING, DELETE_AT_MAPPING, UPDATE_AT_MAPPING } from '../../../graphql/superAdmin/mutation/mutation';
import toast from 'react-hot-toast';
import { EXPORT_AT_MAPPING, IMPORT_AT_MAPPING } from '../../../graphql/superAdmin/mutation/files/mutation';
import useExportAndDownload from '../../../hooks/useExportAndDownload';
import { GET_ASSET_LIST } from '../../../graphql/superAdmin/query/controlConfiguration/query';

const DataTable = () => {
  const UploadURL = RISK_AT_FILE_UPLOAD;
  const url_CU = RISK_AT_MAPPING_LIST;
  const { datas, loadings, errors, fetchData } = APIService(url_CU);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);

  const [id, setId] = useState();
  // const [data, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  // for file upload
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  // search
  const [searchValue, setSearchValue] = useState('');

  const fieldsToFilterBy = ['asset', 'threat'];
  // const arrayFieldsToFilterBy = ["asset"];

  const debounceDelay = 400;
  console.log(id, 'id');

  // useEffect(() => {
  //             // fetchData();
  // }, []);
  const [exportAT] = useMutation(EXPORT_AT_MAPPING); // Specific mutation
  const { exportAndDownloadFile } = useExportAndDownload();
  const [fileExtension, setFileExtension] = useState("xlsx");

  const handleExport = () => {
    exportAndDownloadFile(exportAT, fileExtension, "Asset Threat Mapping"); // Pass the mutation
  };
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, refetch } = useQuery(GET_AT_MAPPING, {
    variables: { limit: pageSize, offset: page * pageSize },
  });
  const { data: assetList } = useQuery(GET_ASSET_LIST, {
    variables: { limit: pageSize, offset: page * pageSize },
  });

  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems = data?.asset_threat_mapping?.count || 0;

  const formatted = data?.asset_threat_mapping?.map(item => ({
    id: item?.id,
    asset: item?.asset?.name,
    threat: item?.threat?.name
  }));
  console.log(formatted, "formatted");

  // const filteredData = data?.filter(
  //   (row) =>
  //     row?.asset?.name?.match(new RegExp(searchValue, "i")) ||
  //     row?.threat?.name?.match(new RegExp(searchValue, "i"))
  // );

  const filteredData = SearchHandler(
    formatted ?? [],
    searchValue,
    fieldsToFilterBy,
    debounceDelay
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };
  const excelData = formatted?.map((item) => {
    return {
      asset: item?.asset, // Assigning the first (and only) element of the array
      threat: item?.threat,
    };
  });
  const [update] = useMutation(UPDATE_AT_MAPPING);
  const [create] = useMutation(ADD_AT_MAPPING);
  const [del] = useMutation(DELETE_AT_MAPPING);
  const [importThreat] = useMutation(IMPORT_AT_MAPPING); //100, 100, 1000, 400

  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });

      if (data?.delete_asset_threat_mapping?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };

  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span className="">Assets Threat Mapping</span>

          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            <button
              onClick={() => {
                setIsModalUploadOpen(true);
              }}
              className="btn_file_download"
            >
              <img
                alt="upload-avatar"
                className="w-7 h-7"
                src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
              />
            </button>

            <button className="btn_file_download">
              <svg
                onClick={handleExport}
                xmlns="http://www.w3.org/2000/svg"
                height={28}
                width={28}
                fillRule="evenodd"
                fill="white"
                clipRule="evenodd"
                imageRendering="optimizeQuality"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                viewBox="0 0 17639 17639"
              >
                <path
                  fill="#1e7145"
                  d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
                />
                <path
                  fill="#1e7145"
                  d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setSelectedItem(null);
                setMode('add');
                setIsModalOpen(true);
              }}
              className="btn-add-new"
            >
              <AddIcon />
            </button>
          </div>
        </div>

        {fetchLoading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && filteredData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <CustomTable
            filteredData={filteredData}
            searchValue={searchValue}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
          />
        )}

        <ATAddEditHandler
          data={assetList}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          size="md"
          header="Assets Threat"
          mode={mode}
          selectedItemId={id}
          selectedItem={selectedItem}
          url_CU={url_CU}
          fetchData={refetch}
          createMutation={create}
          updateMutation={update}
        />

        {/* for uploading new file */}
        <ModalFileUpload
          UploadURL={UploadURL}
          fetchData={refetch}
          importMutation={importThreat}
          header="Assets Threat"
          isOpen={isModalUploadOpen}
          onClose={() => setIsModalUploadOpen(false)}
        />
      </div>
    </main>
  );
};

export default DataTable;
