import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import ModalHandler from "./ModalHandler";
import { useMutation, useQuery } from "@apollo/client";
import {
	ADD_ASSET_LIST,
	DELETE_ASSET_LIST,
	UPDATE_ASSET_LIST,
} from "../../../graphql/superAdmin/mutation/mutation";
import { GET_ASSET_LIST } from "../../../graphql/superAdmin/query/controlConfiguration/query";
import { FaUpload, FaDownload, FaPlus, FaInfoCircle } from "react-icons/fa";

const Assets = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["label", "value", "description"];

	const [update] = useMutation(UPDATE_ASSET_LIST);
	const [create] = useMutation(ADD_ASSET_LIST);

	//data

	const [page, setPage] = useState(0);
	const pageSize = 14; // Number of items per page
	const [del] = useMutation(DELETE_ASSET_LIST);
	const dataPerPage = 6;
	const {
		data: asset,
		loading,
		error,
		refetch,
	} = useQuery(GET_ASSET_LIST, {
		variables: { limit: pageSize, offset: page * pageSize },
	});
	const handlePageChange = ({ selected }) => {
		setPage(selected);
		refetch({ limit: pageSize, offset: selected * pageSize });
	};
	const totalItems = asset?.assets_aggregate?.count || 0;
	// useEffect(() => {
	// 	          // fetchData();
	// }, []);

	const filteredData = SearchHandler(
		asset?.assets ?? [],
		searchValue,
		fieldsToFilterBy
	);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			const { data } = await del({
				variables: { id: parseInt(id) },
			});
			if (data?.delete_assets?.affected_rows > 0) {
				setFetchLoading(false);
				refetch();
				swal("Success", "It has been deleted successfully", "success");
			} else {
			}
		} catch (error) {
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	return (
		<div className="main-container">
			<div className="table-container">
				<div className="table-title ">
					<div className="table-title-text">
						<span>Asset </span>
						<button className="tooltip">
							<FaInfoCircle className="btn-info icon" />
							<span className="tooltip-text">
								This table contains list of Assets.
							</span>
						</button>
					</div>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						{/* Add New Button */}
						<button
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
							className="btn-add-new tooltip"
						>
							<FaPlus className="icon" />
							<span className="tooltip-text">Add New Asset</span>
						</button>
					</div>
				</div>
				{/* table */}
				{loading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={asset?.assets}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
						page={page}
						pageSize={pageSize}
						totalItems={totalItems}
						dataPerPage={dataPerPage}
						onPageChange={handlePageChange}
						passedExcludeColumns={["__typename"]}
					/>
				)}
			</div>

			{/* add and edit modal */}
			<ModalHandler
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				mode={mode}
				size="sm"
				selectedItemId={id}
				selectedItem={selectedItem}
				header="asset"
				createMutation={create}
				updateMutation={update}
				fetchData={refetch}
			/>
		</div>
	);
};

export default Assets;
