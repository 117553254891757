import React from 'react'
import ThreatRepositoryMain from '../SuperAdmin/Home/ThreatRepositoryMain'
import { ADD_THREAT_VECTOR_CLIENT, DELETE_THREAT_VECTOR_CLIENT, GET_CLIENT_THREATS, UPDATE_THREAT_VECTOR_CLIENT } from '../graphql/superAdmin/query/query'
import { useMutation } from '@apollo/client';

const ThreatVector = () => {
  const [addThreat] = useMutation(ADD_THREAT_VECTOR_CLIENT);
  const [updateThreat] = useMutation(UPDATE_THREAT_VECTOR_CLIENT);
  const [deleteThreat] = useMutation(DELETE_THREAT_VECTOR_CLIENT);

  return (
    <div><ThreatRepositoryMain deleteThreatQuery="delete_client_threat" name="client_threats" aggregate="threat_aggregate" query={GET_CLIENT_THREATS} addThreat={addThreat} updateThreat={updateThreat} deleteThreat={deleteThreat} /></div>
  )
}

export default ThreatVector