import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Modal from '../shared/Modal';
import swal from 'sweetalert';
import toast from 'react-hot-toast';
import MultipleSelectCheckmarks from '../shared/MultiSelect/MultiSelect';
import { complianceRequirementsOptions } from '../data/data'; // Assuming this is the options list
import {
    GET_AAPLICATION_ACCESSIBILITY,
    GET_BROADCAST_POPULATION_OF_USERS,
    GET_BUSINESS_CRITICALITY,
    GET_BUSINESS_IMPACT,
    GET_DATA_CLASSIFICATION,
    GET_DATA_SOURCE,
    GET_HOSTING_MODEL,
    GET_USER_INTERFACE,
    GET_USER_POPULATION,
} from '../graphql/superAdmin/query/businessProcessConfiguration/query';
import MultiSelect from '../shared/MultiSelect';

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
    <div className="form-group">
        <label>
            {label} {required && <span style={{ color: 'red' }}>*</span>}
        </label>
        <select className="form-control" value={value || ''} onChange={onChange}>
            <option value="">Select {label.toLowerCase()}...</option>
            {options?.map((item) => (
                <option key={item.id} value={item.id}>
                    {item.label}
                </option>
            ))}
        </select>
        {error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
);

// Reusable Input Component
const Input = ({ label, type, value, onChange, error, required, placeholder }) => (
    <div className="form-group">
        <label>
            {label} {required && <span style={{ color: 'red' }}>*</span>}
        </label>
        <input
            className="form-control"
            type={type}
            placeholder={placeholder}
            value={value || ''}
            onChange={onChange}
        />
        {error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
);

const ModalHandler = ({
    isOpen,
    mode,
    onClose,
    header,
    selectedItem,
    createMutation,
    fetchData,
    updateMutation,
}) => {
    const initialState = {
        name: '',
        business_owner: '',
        application_owner: '',
        revenue_contribution: '',
        max_sensitive_records: '',
        business_criticality_rpo: '',
        data_classification_level: '',
        user_broadest_population: '',
        user_population: '',
        application_accessibility: '',
        hosting_model: '',
        user_interface_accessibility: '',
        data_source: '',
        business_impact: '',
        risk_sensitivity: '',
        compliance_requirements: [], // Multi-select field
    };

    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState({});

    // Fetch dropdown data
    const { data: accessibilityData } = useQuery(GET_AAPLICATION_ACCESSIBILITY, { variables: { limit: 10, offset: 0 } });
    const { data: broadcastData } = useQuery(GET_BROADCAST_POPULATION_OF_USERS, { variables: { limit: 10, offset: 0 } });
    const { data: criticalityData } = useQuery(GET_BUSINESS_CRITICALITY, { variables: { limit: 10, offset: 0 } });
    const { data: impactData } = useQuery(GET_BUSINESS_IMPACT, { variables: { limit: 10, offset: 0 } });
    const { data: classificationData } = useQuery(GET_DATA_CLASSIFICATION, { variables: { limit: 10, offset: 0 } });
    const { data: hostingData } = useQuery(GET_HOSTING_MODEL, { variables: { limit: 10, offset: 0 } });
    const { data: dataSourceData } = useQuery(GET_DATA_SOURCE, { variables: { limit: 10, offset: 0 } });
    const { data: interfaceData } = useQuery(GET_USER_INTERFACE, { variables: { limit: 10, offset: 0 } });
    const { data: userPopulationData } = useQuery(GET_USER_POPULATION, { variables: { limit: 10, offset: 0 } });

    const validations = {
        name: (value) => (value ? '' : 'This field is required.'),
        business_owner: (value) => (value ? '' : 'This field is required.'),
        application_owner: (value) => (value ? '' : 'This field is required.'),
        // compliance_requirements is optional, so no validation
    };

    const handleChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
        if (validations[key]) {
            setErrors((prev) => ({ ...prev, [key]: validations[key](value) }));
        }
    };

    const handleComplianceChange = (selected) => {
        setFormData((prev) => ({ ...prev, compliance_requirements: selected }));
    };
    console.log(selectedItem, "selected")

    useEffect(() => {
        if (mode === 'edit' && selectedItem) {
            setFormData({
                name: selectedItem.name || '',
                business_owner: selectedItem.business_owner || '',
                application_owner: selectedItem.application_owner || '',
                revenue_contribution: selectedItem.revenue_contribution || '',
                max_sensitive_records: selectedItem.max_sensitive_records || '',
                business_criticality_rpo: criticalityData?.business_criticality_rpo?.find(
                    (domain) => domain.label === selectedItem.business_criticality_rpo.label
                )?.id || '',
                data_classification_level: classificationData?.data_classification_level?.find(
                    (domain) => domain.label === selectedItem.data_classification_level.label
                )?.id || '',
                user_broadest_population: broadcastData?.user_broadest_population?.find(
                    (domain) => domain.label === selectedItem.user_broadest_population.label
                )?.id || '',
                user_population: userPopulationData?.user_population?.find(
                    (domain) => domain.label === selectedItem.user_population.label
                )?.id || '',
                application_accessibility: accessibilityData?.application_accessibility?.find(
                    (domain) => domain.label === selectedItem.application_accessibility.label
                )?.id || '',
                hosting_model: hostingData?.hosting_model?.find(
                    (domain) => domain.label === selectedItem.hosting_model.label
                )?.id || '',
                user_interface_accessibility: interfaceData?.user_interface_accessibility?.find(
                    (domain) => domain.label === selectedItem.user_interface_accessibility.label
                )?.id || '',
                data_source: dataSourceData?.data_source?.find(
                    (domain) => domain.label === selectedItem.data_source.label
                )?.id || '',
                business_impact: impactData?.business_impact_config?.find(
                    (domain) => domain.label === selectedItem.business_impact.label
                )?.id || '',
                risk_sensitivity: selectedItem.risk_sensitivity || '',
                compliance_requirements: selectedItem.compliance_requirements || [], // Assuming this comes as an array
            });
        } else {
            setFormData(initialState);
        }
    }, [mode, selectedItem]);
    const [selectedCompliance, setSelectedCompliance] = useState([]);


    const handleChangeTest = (selectedNames) => {
        setSelectedCompliance(selectedNames);
        console.log("Selected Names:", selectedNames);
    };
    const handleSubmit = async () => {
        const newErrors = {};
        Object.keys(validations).forEach((key) => {
            newErrors[key] = validations[key](formData[key]);
        });
        setErrors(newErrors);

        if (Object.values(newErrors).every((error) => !error)) {
            const inputs = {
                created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
                name: formData.name,
                // business_owner: formData.business_owner,
                // application_owner: formData.application_owner,
                revenue_contribution: parseInt(formData.revenue_contribution, 10) || 0,
                max_sensitive_records: parseInt(formData.max_sensitive_records, 10) || 0,
                business_criticality_rpo_pk: parseInt(formData.business_criticality_rpo, 10) || null,
                data_classification_level_pk: parseInt(formData.data_classification_level, 10) || null,
                user_broadest_population_pk: parseInt(formData.user_broadest_population, 10) || null,
                user_population_pk: parseInt(formData.user_population, 10) || null,
                application_accessibility_pk: parseInt(formData.application_accessibility, 10) || null,
                hosting_model_pk: parseInt(formData.hosting_model, 10) || null,
                user_interface_accessibility_pk: parseInt(formData.user_interface_accessibility, 10) || null,
                data_source_pk: parseInt(formData.data_source, 10) || null,
                business_impact_pk: parseInt(formData.business_impact, 10) || null,
                sensitivity_level: formData?.risk_sensitivity?.toLowerCase(),
                client_pk: parseInt(JSON.parse(localStorage.getItem('cyber-minds'))?.user?.client?.id, 10),
                compliance_requirements: JSON.stringify(selectedCompliance), // Stringify for backend
            };

            try {
                if (mode === 'edit') {
                    const response = await updateMutation({
                        variables:
                            { id: parseInt(selectedItem?.id), input: inputs }
                    });
                    if (response.data.update_business_process.affected_rows > 0) {
                        swal('Success', 'It has been updated successfully!', 'success');
                        fetchData();
                        onClose();
                    } toast.success('Update functionality not implemented yet');
                } else {
                    const response = await createMutation({ variables: { inputs: [inputs] } });
                    if (response.data.create_business_process.affected_rows > 0) {
                        swal('Success', 'It has been added successfully!', 'success');
                        fetchData();
                        onClose();
                    }
                }
            } catch (err) {
                swal('Error', 'Something went wrong, please try again.', 'error');
                console.error('Error:', err);
            }
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <Modal.Header>
                <Modal.Title>{mode === 'edit' ? `Edit ${header}` : `Add New ${header}`}{selectedItem?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="grid grid-cols-3 gap-2">
                    <Input
                        label="Business Process Name"
                        type="text"
                        value={formData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        error={errors.name}
                        required
                        placeholder="Text..."
                    />
                    <Input
                        label="Business Owner"
                        type="text"
                        value={formData.business_owner}
                        onChange={(e) => handleChange('business_owner', e.target.value)}
                        error={errors.business_owner}
                        required
                        placeholder="Text..."
                    />
                    <Input
                        label="Application Owner"
                        type="text"
                        value={formData.application_owner}
                        onChange={(e) => handleChange('application_owner', e.target.value)}
                        error={errors.application_owner}
                        required
                        placeholder="Text..."
                    />
                    <Input
                        label="Revenue Contribution (USD)"
                        type="number"
                        value={formData.revenue_contribution}
                        onChange={(e) => handleChange('revenue_contribution', e.target.value)}
                        placeholder="Enter value"
                    />
                    <Input
                        label="Max Sensitive Records"
                        type="number"
                        value={formData.max_sensitive_records}
                        onChange={(e) => handleChange('max_sensitive_records', e.target.value)}
                        placeholder="Enter value"
                    />

                    <div className="form-group">
                        <label>Compliance Requirements</label>
                        <MultiSelect
                            options={complianceRequirementsOptions}
                            value={selectedCompliance}
                            onChange={handleChangeTest}
                            disabled={false} // Set to true to disable the dropdown
                        />
                    </div>
                    <Dropdown
                        label="Business Criticality (RPO)"
                        value={formData.business_criticality_rpo}
                        onChange={(e) => handleChange('business_criticality_rpo', e.target.value)}
                        options={criticalityData?.business_criticality_rpo}
                        error={errors.business_criticality_rpo}
                    />
                    <Dropdown
                        label="Data Classification Level"
                        value={formData.data_classification_level}
                        onChange={(e) => handleChange('data_classification_level', e.target.value)}
                        options={classificationData?.data_classification_level}
                        error={errors.data_classification_level}
                    />
                    <Dropdown
                        label="Broadest User Population"
                        value={formData.user_broadest_population}
                        onChange={(e) => handleChange('user_broadest_population', e.target.value)}
                        options={broadcastData?.user_broadest_population}
                        error={errors.user_broadest_population}
                    />
                    <Dropdown
                        label="User Population"
                        value={formData.user_population}
                        onChange={(e) => handleChange('user_population', e.target.value)}
                        options={userPopulationData?.user_population}
                        error={errors.user_population}
                    />
                    <Dropdown
                        label="Application Accessibility"
                        value={formData.application_accessibility}
                        onChange={(e) => handleChange('application_accessibility', e.target.value)}
                        options={accessibilityData?.application_accessibility}
                        error={errors.application_accessibility}
                    />
                    <Dropdown
                        label="Hosting Model"
                        value={formData.hosting_model}
                        onChange={(e) => handleChange('hosting_model', e.target.value)}
                        options={hostingData?.hosting_model}
                        error={errors.hosting_model}
                    />
                    <Dropdown
                        label="User Interface Accessibility"
                        value={formData.user_interface_accessibility}
                        onChange={(e) => handleChange('user_interface_accessibility', e.target.value)}
                        options={interfaceData?.user_interface_accessibility}
                        error={errors.user_interface_accessibility}
                    />
                    <Dropdown
                        label="Data Source"
                        value={formData.data_source}
                        onChange={(e) => handleChange('data_source', e.target.value)}
                        options={dataSourceData?.data_source}
                        error={errors.data_source}
                    />
                    <Dropdown
                        label="Business Impact"
                        value={formData.business_impact}
                        onChange={(e) => handleChange('business_impact', e.target.value)}
                        options={impactData?.business_impact_config}
                        error={errors.business_impact}
                    />
                    <Input
                        label="Risk Sensitivity"
                        type="text"
                        value={formData.risk_sensitivity}
                        onChange={(e) => handleChange('risk_sensitivity', e.target.value)}
                        placeholder="Text..."
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-cancel" onClick={onClose}>
                    Cancel
                </button>
                <button className="btn-add-new" onClick={handleSubmit}>
                    {mode === 'edit' ? 'Save Changes' : 'Add'}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalHandler;