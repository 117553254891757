import React, { useState } from "react";
import GaugeChart from "react-gauge-chart";

const RiskScore = ({ data }) => {
  const [sensitivityFilter] = useState("High"); // Default filter, could be made dynamic if needed

  // Define severity levels with their corresponding values and ranges
  const severityLevels = {
    veryLow: { value: 0.1, range: 20 },
    low: { value: 0.3, range: 40 },
    moderate: { value: 0.5, range: 60 },
    high: { value: 0.7, range: 80 },
    critical: { value: 0.9, range: 100 },
  };

  // Get severity data from props
  const severity = data?.severity?.toLowerCase() || "N/A";
  const severityConfig = severityLevels[severity] || { value: 0, range: 0 };

  // Gauge configuration
  const gaugeConfig = {
    nrOfLevels: 5,
    arcsLength: [0.2, 0.2, 0.2, 0.2, 0.2],
    colors: [
      "var(--color-severity-very-low)",
      "var(--color-severity-low)",
      "var(--color-severity-moderate)",
      "var(--color-severity-high)",
      "var(--color-severity-critical)",
    ],
    arcPadding: 0.02,
    needleColor: "var(--color-cronus-primary)",
    needleBaseColor: "var(--color-cronus-primary)",
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <GaugeChart
        id="gauge-chart-risk"
        {...gaugeConfig}
        percent={severityConfig.value}
        hideText={true}
      />
      <div className="mt-2 text-center">
        <span className="text-lg font-semibold">
          {severity.toUpperCase()}
        </span>
        {/* {data?.value && (
          <span className="ml-2 text-sm">
            ({data?.value})
          </span>
        )} */}
      </div>
    </div>
  );
};

export default RiskScore;