export function getBackgroundColorWithSensetivity(severity, sensFilter) {
	switch (severity) {
		case "low":
			return sensFilter === "High"
				? "green"
				: sensFilter === "Medium"
					? "green"
					: sensFilter === "Low"
						? "green"
						: "gray";
		case "very_low":
			return sensFilter === "High"
				? "#90ee90"
				: sensFilter === "Medium"
					? "#90ee90"
					: sensFilter === "Low"
						? "#90ee90"
						: "gray";
		case "moderate":
			return sensFilter === "High"
				? "yellow"
				: sensFilter === "Medium"
					? "yellow"
					: sensFilter === "Low"
						? "yellow"
						: "gray";
		case "high":
			return sensFilter === "High"
				? "orange"
				: sensFilter === "Medium"
					? "orange"
					: sensFilter === "Low"
						? "orange"
						: "gray";
		case "critical":
			return sensFilter === "High"
				? "maroon"
				: sensFilter === "Medium"
					? "maroon"
					: sensFilter === "Low"
						? "maroon"
						: "gray";
		default:
			return sensFilter === "extra high" ? "maroon" : "gray";
	}
}
export function getColorKey(applicationRiskProfile) {
	if (applicationRiskProfile > 0 && applicationRiskProfile <= 2) {
		return "very_low";
	} else if (applicationRiskProfile > 2.1 && applicationRiskProfile <= 5) {
		return "low";
	} else if (applicationRiskProfile > 5.1 && applicationRiskProfile <= 10) {
		return "moderate";
	} else if (applicationRiskProfile > 10.1 && applicationRiskProfile <= 15) {
		return "high";
	} else if (applicationRiskProfile > 15.1) {
		return "critical";
	} else {
		return "unknown";
	}
}
export function getKeyInfo(applicationRiskProfile) {
	const colorKey = getColorKey(applicationRiskProfile);

	const colorInfo = [
		{ key: "very_low", count: 0 },
		{ key: "low", count: 0 },
		{ key: "moderate", count: 0 },
		{ key: "high", count: 0 },
		{ key: "critical", count: 0 },
		{ key: "unknown", count: 0 },
	];

	// Increment the count of the corresponding key
	const index = colorInfo.findIndex((item) => item.key === colorKey);
	if (index !== -1) {
		colorInfo[index].count += 1;
	}

	// Filter out objects with count === 0
	const filteredColorInfo = colorInfo.filter((item) => item.count > 0);

	// Extract keys and counts as separate arrays
	const keysArray = filteredColorInfo.map((item) => item.key);
	const countsArray = filteredColorInfo.map((item) => item.count);

	return { keys: keysArray, counts: countsArray };
}

export function getBackgroundColor(severity) {
	// Define a mapping of severities to colors
	const severityColors = {
		low: "#008000", // green
		very_low: "#90ee90", // light green
		moderate: "#F7DD9E", // yellow
		high: "#FFA500", // orange
		critical: "#800000", // maroon
	};

	// Normalize the input severity (case-insensitive and 'medium' treated as 'moderate')
	const normalizedSeverity = severity?.toLowerCase() === "medium" ? "moderate" : severity?.toLowerCase();

	// Check if the severity exists in the mapping; if not, default to 'gray'
	return severityColors[normalizedSeverity] || "gray";
}

export function getApplicationRiskProfilColor(applicationRiskProfile) {
	let label = "";
	let color = "";

	if (applicationRiskProfile > 0 && applicationRiskProfile <= 2) {
		label = "low";
		color = "var(--color-severity-very-low)"; // light green
	} else if (applicationRiskProfile > 2.1 && applicationRiskProfile <= 5) {
		label = "low";
		color = "var(--color-severity-low)"; // green
	} else if (applicationRiskProfile > 5.1 && applicationRiskProfile <= 10) {
		label = "medium";
		color = "var(--color-severity-moderate)"; // yellow
	} else if (applicationRiskProfile > 10.1 && applicationRiskProfile <= 15) {
		label = "high";
		color = "var(--color-severity-high)"; // orange
	} else if (applicationRiskProfile > 15.1) {
		label = "critical";
		color = "var(--color-severity-critical)"; // maroon
	} else {
		label = "unknown";
		color = "gray";
	}

	return { label, color };
}
export const countLabelsAndColors = (data) => {
	const countMap = {};
	// Loop through the data and count occurrences
	data?.forEach((item) => {
		const key = `${item.label}-${item.color}`;
		countMap[key] = (countMap[key] || 0) + 1;
	});

	// Create an array of distinct records with counts
	const distinctRecords = Object.keys(countMap).map((key) => {
		const [label, color] = key.split("-");
		return { label, color, count: countMap[key] };
	});

	return distinctRecords;
};
