import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import swal from "sweetalert";
import { ADD_RESPONSE_DISTRIBUTION, UPDATE_RESPONSE_DISTRIBUTION } from "../../../graphql/superAdmin/mutation/mutation";
import toast from "react-hot-toast";

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: "red" }}>*</span>}
		</Form.Label>
		<select className="form-control" value={value} onChange={onChange}>
			<option value="">Select {label.toLowerCase()}...</option>
			{options?.map((item) => (
				<option key={item} value={item}>
					{item}
				</option>
			))}
		</select>
		{error && <span style={{ color: "red" }}>{error}</span>}
	</Form.Group>
);

const ModalHandler = ({
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	fetchData,
}) => {
	const [formData, setFormData] = useState({
		detection_and_escalation: "",
		ex_post_response: "",
		lost_business_cost: "",
		notification: "",
		attack_type: "",
	});

	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [validRequest, setValidRequest] = useState(true);

	// GraphQL Mutations
	const [addIncidentData] = useMutation(ADD_RESPONSE_DISTRIBUTION);
	const [updateIncidentData] = useMutation(UPDATE_RESPONSE_DISTRIBUTION);

	// Options for attack_type dropdown
	const attackTypeOptions = ["incident", "breach"];

	useEffect(() => {
		// Calculate the sum of the specified values
		const sum =
			parseFloat(formData.detection_and_escalation) +
			parseFloat(formData.ex_post_response) +
			parseFloat(formData.lost_business_cost) +
			parseFloat(formData.notification);

		// Check if the sum is 100
		setValidRequest(sum === 100);
	}, [formData]);

	const validations = {
		detection_and_escalation: (value) => {
			if (!value) {
				return "This field is required.";
			} else if (
				!/^[1-9]\d*(\.\d{0,2})?$/.test(value) ||
				value < 1 ||
				value > 100
			) {
				return "Enter a valid percentage between 1 and 100";
			} else {
				return "";
			}
		},
		ex_post_response: (value) => {
			if (!value) {
				return "This field is required.";
			} else if (
				!/^[1-9]\d*(\.\d{0,2})?$/.test(value) ||
				value < 1 ||
				value > 100
			) {
				return "Enter a valid percentage between 1 and 100";
			} else {
				return "";
			}
		},
		attack_type: (value) => {
			if (!value) {
				return "This field is required.";
			} else {
				return "";
			}
		},
		lost_business_cost: (value) => {
			if (!value) {
				return "This field is required.";
			} else if (
				!/^[1-9]\d*(\.\d{0,2})?$/.test(value) ||
				value < 1 ||
				value > 100
			) {
				return "Enter a valid percentage between 1 and 100";
			} else {
				return "";
			}
		},
		notification: (value) => {
			if (!value) {
				return "This field is required.";
			} else if (
				!/^[1-9]\d*(\.\d{0,2})?$/.test(value) ||
				value < 1 ||
				value > 100
			) {
				return "Enter a valid percentage between 1 and 100";
			} else {
				return "";
			}
		},
	};

	const handleChange = (key, value) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
		if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
	};

	const resetForm = () => {
		setFormData({
			detection_and_escalation: "",
			ex_post_response: "",
			lost_business_cost: "",
			notification: "",
			attack_type: "",
		});
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			setFormData(selectedItem);
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const handleSubmit = () => {
		if (isSubmitting) return; // Prevent re-submission

		const newErrors = {
			detection_and_escalation: validations.detection_and_escalation(
				formData.detection_and_escalation
			),
			ex_post_response: validations.ex_post_response(formData.ex_post_response),
			lost_business_cost: validations.lost_business_cost(formData.lost_business_cost),
			notification: validations.notification(formData.notification),
			attack_type: validations.attack_type(formData.attack_type),
		};

		setErrors(newErrors);

		if (Object.values(newErrors).every((error) => !error) && validRequest) {
			setIsSubmitting(true);

			const inputs = {
				created_at: new Date().toISOString(),
				detection_and_escalation: parseFloat(formData.detection_and_escalation),
				ex_post_response: parseFloat(formData.ex_post_response),
				lost_business_cost: parseFloat(formData.lost_business_cost),
				notification: parseFloat(formData.notification),
				attack_type: formData.attack_type.toLowerCase(),
			};

			if (mode === "add") {
				addIncidentData({ variables: { inputs } })
					.then(() => {
						setIsSubmitting(false);
						onClose();
						toast.success("Success!", "Record has been added successfully!", "success");
						fetchData();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage =
							err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
						swal("Error", errorMessage, "error");
						console.error("GraphQL Error:", err);
					});
			} else {
				updateIncidentData({ variables: { id: parseInt(selectedItemId), input: inputs } })
					.then(() => {
						setIsSubmitting(false);
						onClose();
						toast.success("Success!", "Record has been updated successfully!", "success");
						fetchData();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage =
							err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
						swal("Error", errorMessage, "error");
						console.error("GraphQL Error:", err);
					});
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={() => { onClose(); resetForm(); }} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update ${header}` : `Add New ${header}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="flex justify-center items-center text-xl text-red-500">
					{validRequest ? "" : "*Total sum of entries should be 100."}
				</p>
				<div className="modal-two-columns">
					<Form>
						<Form.Group>
							<Form.Label>Detection and Escalation</Form.Label>
							<Form.Control
								type="number"
								placeholder="Number..."
								step="0.01"
								value={formData.detection_and_escalation}
								onChange={(e) => handleChange("detection_and_escalation", e.target.value)}
							/>
							{errors.detection_and_escalation && (
								<Form.Control.Feedback type="invalid">
									{errors.detection_and_escalation}
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Ex Post Response</Form.Label>
							<Form.Control
								type="number"
								placeholder="Number..."
								step="0.01"
								value={formData.ex_post_response}
								onChange={(e) => handleChange("ex_post_response", e.target.value)}
							/>
							{errors.ex_post_response && (
								<Form.Control.Feedback type="invalid">
									{errors.ex_post_response}
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Lost Business Cost</Form.Label>
							<Form.Control
								type="number"
								placeholder="Number..."
								step="0.01"
								value={formData.lost_business_cost}
								onChange={(e) => handleChange("lost_business_cost", e.target.value)}
							/>
							{errors.lost_business_cost && (
								<Form.Control.Feedback type="invalid">
									{errors.lost_business_cost}
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>Notification</Form.Label>
							<Form.Control
								type="number"
								placeholder="Number..."
								step="0.01"
								value={formData.notification}
								onChange={(e) => handleChange("notification", e.target.value)}
							/>
							{errors.notification && (
								<Form.Control.Feedback type="invalid">
									{errors.notification}
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<Dropdown
							label="Attack Type"
							value={formData.attack_type}
							onChange={(e) => handleChange("attack_type", e.target.value)}
							options={attackTypeOptions}
							error={errors.attack_type}
							required
						/>
					</Form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn-cancel" onClick={() => { onClose(); resetForm(); }}>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;