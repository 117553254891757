import { combineReducers } from "redux";
import { tokenReducer } from "./tokenReducer";
import { userDataReducer } from "./userReducer";
import { userStatusReducer } from "./userStatusReducer";
import { assetRiskReducer } from "./assetRiskReducer";
import { cpeReducer } from "./cpeReducer";
import { processReducer } from "./processReducer";
import { nameReducer } from "./nameReducer";
import { clientIdReducer } from "./clientIdReducer";
import { processIDsuper } from "./processIDsuper";
import { vendorReducer } from "./vendorReducer";
import { productsReducer } from "./productsReducer";
import { pageNameReducer } from "./pageNameReducer";
import { businessProcessDataReducer } from "./businessProcessData";
import { domainReducer } from "./domainReducer";
import { controlDomainReducer } from "./controlDomainReducer";
// import themeReducer from "./themeReducer";
import { LOGOUT } from "../actions";
import counterReducer from "./counterSlice";
import domainsReducer from "./domainsSlice";
import scopesReducer from "./scopesSlice";
import applicabilityReducer from "./applicabilitySlice";
import industryStandardReducer from "./standardSlice";
import usecaseReducer from "./usecaseSlice";
import userName from "./userNameSlice";
import impactReducer from "./impactSlice";
import typesReducer from "./typesSlice";
import categoryReducer from "./categorySlice";
import threatVectorsReducer from "./threatVectorsSlice";
import userNameSlice from "./userNameSlice";
import multiApplicableControlsReducer from "./multiApplicableControlsSlice";

const appReducer = combineReducers({
	controls: combineReducers({
		domains: domainsReducer,
		impact: impactReducer,
		types: typesReducer,
		scopes: scopesReducer,
		category: categoryReducer,
		applicability: applicabilityReducer,
		usecase: usecaseReducer,
		industryStandard: industryStandardReducer,
	}),

	controlsProfile: combineReducers({
		multiApplicable: multiApplicableControlsReducer,
	}),
	threatVectors: threatVectorsReducer,
	token: tokenReducer,
	user: userDataReducer,
	user_status: userStatusReducer,
	assetRisk: assetRiskReducer,
	id: processReducer,
	name: nameReducer,
	cid: clientIdReducer,
	pid: processIDsuper,
	vendor: vendorReducer,
	cpe: cpeReducer,
	product: productsReducer,
	pageName: pageNameReducer,
	businessProcessData: businessProcessDataReducer,
	domain: domainReducer,
	controlDomain: controlDomainReducer,
	// theme: themeReducer,
	counter: counterReducer,
	userName: userNameSlice,
});

const rootReducer = (state, action) => {
	if (action.type === LOGOUT) {
		// Reset the entire Redux state
		localStorage.removeItem("cyber-minds");
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
