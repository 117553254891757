import React, { useEffect, useState } from 'react';
import CustomTable from '../../../shared/CustomTable';
import http from '../../../resources/http';
import { CLIENTS } from '../../../routes/accounts';
import {
  GET_BUSINESS_PROCESS_BY_ID,
  GET_BUSINESS_IMPACT_BY_SUPERUSER,
} from '../../../api/businessProcess';
import {
  BUSINESS_IMPACT_LIST,
  BUSINESS_IMPACT_BY_PK,
  UPLOAD_BIA_EXCEL_BY_SUPERUSER,
} from '../../../api/excelUpload';
import { downloadExcelData } from '../../../utils/ExportExcelUtility';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import SearchHandler from '../../../shared/SearchHandler';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import AddIcon from '@mui/icons-material/Add';
import swal from 'sweetalert';
import ModalFileUpload from '../../../shared/ModalFileUpload.js';
import { toast } from 'react-hot-toast';
import { useQuery, useMutation } from '@apollo/client';
import { DELETE_CLIENT_BIA, GET_BIA_SUPER_ADMIN, GET_BUSINESS_IMPACT_ANALYSIS_SUPER_ADMIN, GET_BUSINESS_PROCESSES_BY_CLIENT_ID, GET_CLIENT_LIST } from '../../../graphql/superAdmin/query/query.js';
import { EXPORT_BIA, IMPORT_BIA } from '../../../graphql/superAdmin/mutation/files/mutation.js';
import useExportAndDownload from '../../../hooks/useExportAndDownload.js';
import { GET_CLIENT_BIA } from '../../../graphql/client/query.js';
import ModalHandler from '../../../Client/Configuration/ModalHandlercopy.js';

const BusinessImpactAnalysisSA = () => {
  // for file upload
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const UploadURL = UPLOAD_BIA_EXCEL_BY_SUPERUSER;
  const url_create = BUSINESS_IMPACT_LIST;
  const url_get = BUSINESS_IMPACT_LIST;
  const url_by_pk = BUSINESS_IMPACT_BY_PK;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);
  const [id, setId] = useState();
  const [datas, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  // search
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = [
    'regulations',
    'cpe',
    'version',
    'product',
    'vendors',
    'asset_name',
    'business_assets',
    'vendors',
    'asset_name',
    'hierarchy',
    'asset_categorization',
    'asset_type',
    'asset_risk',
  ];
  const debounceDelay = 400;

  const [error, setError] = useState(null);

  const [isNullData, setIsNullData] = useState(false);
  const [isBPNullDataForClient, setIsBPNullDataForClient] = useState(false);

  const [clientsOptions, setClientsOptions] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [SelectedClientsOptions, setSelectedClientsOptions] = useState(null);

  const [businessProcessOptions, setBusinessProcessOptions] = useState([]);
  const [selectedBusinessProcessId, setSelectedBusinessProcessId] =
    useState(null);
  const [selectedBusinessProcessOptions, setSelectedBusinessProcessOptions] =
    useState([]);

  const [exportBIA] = useMutation(EXPORT_BIA); // Specific mutation
  const { exportAndDownloadFile } = useExportAndDownload();
  const [fileExtension, setFileExtension] = useState("xlsx");

  const handleExport = () => {
    exportAndDownloadFile(exportBIA, fileExtension, "Business Impact Analysis"); // Pass the mutation
  };
  const [client, setClient] = useState(null)
  const handleAddClick = () => {
    if (true) {
      setSelectedItem(null);
      setMode('add');
      setIsModalOpen(true);
    } else {
      toast('Please select the client and business process to add a data');
    }
  };
  const handleFileUploadClick = () => {
    if (true) {
      setIsModalUploadOpen(true)
    }
    else {
      toast('Please select the client and business process to upload a file');
    }
  };
  const handleClientSelection = (event) => {
    setFetchLoading(true);
    const selectedClient = event.target.value;
    const SelectedClientsOptions = clientsOptions.find(
      (option) => option.id === parseInt(selectedClient)
    );
    setSelectedClientId(
      SelectedClientsOptions ? SelectedClientsOptions.id : null
    );
    setSelectedClientsOptions(SelectedClientsOptions);
    setBusinessProcessOptions([]);
    setData([]);
    setSelectedBusinessProcessId(null);
    setFetchLoading(false);
  };

  const handleBusinessProcessSelection = (event) => {
    setFetchLoading(true);
    setData([]);
    const selectedBusinessProcess = event.target.value;
    const selectedBusinessProcessOptions = businessProcessOptions.find(
      (option) => option.id === parseInt(selectedBusinessProcess)
    );
    setSelectedBusinessProcessId(
      selectedBusinessProcessOptions ? selectedBusinessProcessOptions.id : null
    );
    setSelectedBusinessProcessOptions(selectedBusinessProcessOptions);
    setFetchLoading(false);
  };

  async function fetchClients() {
    try {
      const listOfClients = await http.get(CLIENTS);
      console.log('first response ', listOfClients.data);
      setClientsOptions(listOfClients.data);
      setFetchLoading(false);
    } catch (error) {
      setError('error occurred while fetching clients ');
      setFetchLoading(false);
    }
  }

  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { loading, data, refetch } = useQuery(GET_BUSINESS_IMPACT_ANALYSIS_SUPER_ADMIN, {
    variables: { client_id: selectedClientId, business_process_id: selectedBusinessProcessId },
    // skip: !selectedClientId || !selectedBusinessProcessId, // Skip the query until both client and business process are selected
  });

  const { data: businessProcess } = useQuery(GET_BUSINESS_PROCESSES_BY_CLIENT_ID, {
    variables: { client_id: parseInt(selectedClientId) },
    skip: !selectedClientId, // Skip the query until a client is selected
  });

  const { data: clientList } = useQuery(GET_CLIENT_LIST, {
    variables: { limit: pageSize, offset: page * pageSize },
  });

  useEffect(() => {
    if (selectedClientId && selectedBusinessProcessId) {
      refetch({ client_id: selectedClientId, business_process_id: selectedBusinessProcessId });
    }
  }, [selectedClientId, selectedBusinessProcessId, refetch]);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };

  const totalItems = data?.client_asset_aggregate?.count || 0;
  console.log(data?.super_admin_client_business_impact_analysis, "data")
  const filteredData = SearchHandler(
    data?.super_admin_client_business_impact_analysis ?? [],
    searchValue,
    fieldsToFilterBy,
    debounceDelay
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };

  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };

  const [importBIA] = useMutation(IMPORT_BIA);
  const [deleteClientBIA] = useMutation(DELETE_CLIENT_BIA);

  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await deleteClientBIA({
        variables: { id: parseInt(id) },
      });

      if (data?.delete_client_asset?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        toast.success('It has been deleted successfully');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };

  return (
    <div className="main-container ">
      <div className="table-title ">
        <span className="">Business Impact</span>
        <div className="w-54 flex space-x-2">
          <div>
            <select
              className="text-gray-600 bg-gray-50 border w-56 border-gray-300  rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={selectedClientId || ''}
              onChange={(event) => setSelectedClientId(event.target.value)}
            >
              <option value="">Select Client</option>
              {clientList?.client?.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>
          {selectedClientId ? (
            <select
              className="text-gray-600 bg-gray-50 border w-56 border-gray-300  rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={selectedBusinessProcessId || ''}
              onChange={(event) => setSelectedBusinessProcessId(event.target.value)}
            >
              <option value="">Select Business Process</option>
              {businessProcess?.business_processes_by_client?.map((businessProcess) => (
                <option key={businessProcess.id} value={businessProcess.id}>
                  {businessProcess.name}
                </option>
              ))}
            </select>
          ) : null}
        </div>

        <div className="flex justify-between items-center space-x-4">
          <SearchInput
            searchValue={searchValue}
            setSearchValue={handleInputChange}
          />
          <button
            onClick={() => handleFileUploadClick()}
            className="btn_file_download"
          >
            <img
              alt="upload-avatar"
              className="w-7 h-7"
              src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
            />
          </button>
          <button className="btn_file_download">
            <svg
              onClick={handleExport}
              xmlns="http://www.w3.org/2000/svg"
              height={28}
              width={28}
              fill-rule="evenodd"
              fill="white"
              clip-rule="evenodd"
              image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision"
              text-rendering="geometricPrecision"
              viewBox="0 0 17639 17639"
            >
              <path
                fill="#1e7145"
                d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
              />
              <path
                fill="#1e7145"
                d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
              />
            </svg>
          </button>
          <button onClick={() => handleAddClick()} className="btn-add-new">
            <AddIcon />
          </button>
        </div>
      </div>
      {
        selectedBusinessProcessId && filteredData.length === 0 ? (
          <div className="alert-no-data">
            There is no data for selected business process
          </div>
        ) : client && !selectedBusinessProcessId ? <div className="alert-no-data">
          Please select a business process
        </div> : filteredData.length === 0 ? <div className="alert-no-data">
          Please select a client and business process
        </div> : <CustomTable
          filteredData={filteredData}
          onEditClick={handleEdit}
          onDeleteClick={handleDelete}
          page={page}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          passedExcludeColumns={['__typename']}
        />
      }


      <ModalHandler
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode={mode}
        selectedItemId={id}
        selectedItem={selectedItem}
        selectedClientId={selectedClientId}
        selectedBusinessProcessId={selectedBusinessProcessId}
        fetchData={refetch}
      />

      {/* for uploading new file */}
      <ModalFileUpload
        importMutation={importBIA}
        UploadURL={UploadURL}
        fetchData={refetch}
        isOpen={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
        selectedClientId={selectedClientId}
        selectedBusinessProcessId={selectedBusinessProcessId}
      />
    </div>
  );
};

export default BusinessImpactAnalysisSA;