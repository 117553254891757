import React from "react";
import { baseURL } from "../resources/apiClient";
import { useApi } from "../hooks/useApis";
import { toLowercase } from "../utils/helpers";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import Chart from "react-apexcharts";
import LoadingSpinner from "../utils/LoadingSpinner";

const TopThreats = ({ data }) => {
  // Convert the Set back to an array
  // const uniqueScopes = Array.from(allScopes);
  // Extract unique values for x, y, and risk_profile
  // Extract unique x values
  const uniqueX = [...new Set(data?.map((item) => item?.name))];
  console.log(data, "names")
  // const nameCounts = data?.reduce((acc, item) => {
  //   acc[item.name] = (acc[item.name] || 0) + 1;
  //   return acc;
  // }, {});

  // const countList = Object.values(nameCounts);

  // console.log(countList);
  return (
    <div className="p-4">
      <Chart
        type="pie"
        width={500}
        height={350}
        series={[1, 1, 1]}
        options={{
          labels: uniqueX,
          colors: [
            "var(--color-chart-one)",
            "var(--color-chart-two)",
            "var(--color-chart-three)",
            "var(--color-chart-four)",
            "var(--color-chart-five)",
          ],
          stroke: {
            width: 0,
          },
          dataLabels: {
            style: {
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
            },
            enabled: false,
            formatter: function (val, opts) {
              /* Format labels here */
              return opts.w.config.series[opts.seriesIndex];
            },
          },
          //start
          responsive: [
            {
              breakpoint: 535,
              options: {
                chart: {
                  width: 320,
                },
                legend: {
                  offsetY: -13,
                  offsetX: 0,
                },
              },
            },
          ],
          //finish
          legend: {
            fontSize: "14px",
            fontWeight: "bold",
            itemMargin: "left",
            labels: {
              colors: "black",
            },
          },
        }}
      />
    </div>
  );
};

export default TopThreats;
