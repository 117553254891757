import { gql } from '@apollo/client';

export const DELETE_USER_POPULATION = gql`
  mutation DELETE_USER_POPULATION($id: Int!) {
    delete_user_population(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_POPULATION = gql`
  mutation UPDATE_USER_POPULATION(
    $id: Int!
    $input: user_populationInputType!
  ) {
    update_user_population(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_USER_POPULATION = gql`
  mutation ADD_USER_POPULATION($inputs: [user_populationInputType]!) {
    create_user_population(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_USER_INTERFACE = gql`
  mutation DELETE_USER_INTERFACE($id: Int!) {
    delete_user_interface_accessibility(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_INTERFACE = gql`
  mutation UPDATE_USER_INTERFACE(
    $id: Int!
    $input: user_interface_accessibilityInputType!
  ) {
    update_user_interface_accessibility(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;
export const ADD_USER_INTERFACE = gql`
  mutation ADD_USER_INTERFACE(
    $inputs: [user_interface_accessibilityInputType]!
  ) {
    create_user_interface_accessibility(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_DATA_SOURCE = gql`
  mutation DELETE_DATA_SOURCE($id: Int!) {
    delete_data_source(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_DATA_SOURCE = gql`
  mutation UPDATE_DATA_SOURCE($id: Int!, $input: data_sourceInputType!) {
    update_data_source(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD__DATA_SOURCE = gql`
  mutation ADD_DATA_SOURCE($inputs: [data_sourceInputType]!) {
    create_data_source(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_HOSTING_MODEL = gql`
  mutation DELETE_DATA_SOURCE($id: Int!) {
    delete_hosting_model(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_HOSTING_MODEL = gql`
  mutation UPDATE_HOSTING_MODEL($id: Int!, $input: hosting_modelInputType!) {
    update_hosting_model(where: { id: { exact: $id } }, input: $input) {
      affected_rows
    }
  }
`;
export const ADD_HOSTING_MODEL = gql`
  mutation ADD_HOSTING_MODEL($inputs: [hosting_modelInputType]!) {
    create_hosting_model(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_DATA_CALSSIFICATION = gql`
  mutation DELETE_DATA_CALSSIFICATION($id: Int!) {
    delete_data_classification_level(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_DATA_CALSSIFICATION = gql`
  mutation UPDATE_DATA_CALSSIFICATION(
    $id: Int!
    $input: data_classification_levelInputType!
  ) {
    update_data_classification_level(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;
export const ADD_DATA_CALSSIFICATION = gql`
  mutation ADD_DATA_CALSSIFICATION(
    $inputs: [data_classification_levelInputType]!
  ) {
    create_data_classification_level(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_BUSINESS_IMPACT = gql`
  mutation DELETE_BUSINESS_IMPACT($id: Int!) {
    delete_business_impact_config(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BUSINESS_IMPACT = gql`
  mutation UPDATE_BUSINESS_IMPACT(
    $id: Int!
    $input: business_impact_configInputType!
  ) {
    update_business_impact_config(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;
export const ADD_BUSINESS_IMPACT = gql`
  mutation ADD_BUSINESS_IMPACT($inputs: [business_impact_configInputType]!) {
    create_business_impact_config(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_BUSINESS_CRITICALITY = gql`
  mutation DELETE_BUSINESS_CRITICALITY($id: Int!) {
    delete_business_criticality_rpo(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BUSINESS_CRITICALITY = gql`
  mutation UPDATE_BUSINESS_IMPACT(
    $id: Int!
    $input: business_criticality_rpoInputType!
  ) {
    update_business_criticality_rpo(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;
export const ADD_BUSINESS_CRITICALITY = gql`
  mutation ADD_BUSINESS_CRITICALITY(
    $inputs: [business_criticality_rpoInputType]!
  ) {
    create_business_criticality_rpo(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_BROADCAST_POPULATION = gql`
  mutation DELETE_BROADCAST_POPULATION($id: Int!) {
    delete_user_broadest_population(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BROADCAST_POPULATION = gql`
  mutation UPDATE_BROADCAST_POPULATION(
    $id: Int!
    $input: user_broadest_populationInputType!
  ) {
    update_user_broadest_population(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;
export const ADD_BROADCAST_POPULATION = gql`
  mutation ADD_BROADCAST_POPULATION(
    $inputs: [user_broadest_populationInputType]!
  ) {
    create_user_broadest_population(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const DELETE_APPLICATION_ACCESSIBILITY= gql`
  mutation DELETE_APPLICATION_ACCESSIBILITY($id: Int!) {
    delete_application_accessibility(where: { id: { exact: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_APPLICATION_ACCESSIBILITY = gql`
  mutation UPDATE_APPLICATION_ACCESSIBILITY(
    $id: Int!
    $input: application_accessibilityInputType!
  ) {
    update_application_accessibility(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;
export const ADD_APPLICATION_ACCESSIBILITY = gql`
  mutation ADD_APPLICATION_ACCESSIBILITY(
    $inputs: [application_accessibilityInputType]!
  ) {
    create_application_accessibility(inputs: $inputs) {
      affected_rows
    }
  }
`;
export const ADD_BUSINESS_PROCESS = gql`
  mutation ADD_BUSINESS_PROCESS(
    $inputs:  [business_processInputType]!
  ) {
    create_business_process(inputs: $inputs) {
      affected_rows
    }
  }
`;

export const UPDATE_BUSINESS_PROCESS = gql`
  mutation UPDATE_BUSINESS_PROCESS(
    $id: Int!
    $input: business_processInputType!
  ) {
    update_business_process(
      where: { id: { exact: $id } }
      input: $input
    ) {
      affected_rows
    }
  }
`;