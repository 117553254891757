import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	cpe,
	cpeValue,
	products,
	setProduct,
	vendors,
} from "../../../authentication/store/actions";
import { baseURL } from "../../../resources/apiClient";
import http from "../../../resources/http";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import CompanyReports from "./CompanyReports";
import CompanyGeneratedReport from "./CompanyGeneratedReport";
import Dist from "./MoreDetails/Dist";
// import { useHandleTheme } from "../../../hooks/useHandleTheme";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FiMoreVertical } from "react-icons/fi";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { GET_CLIENT_BIA } from "../../../graphql/client/query";
import { gql, useQuery } from "@apollo/client";

const Navs = styled(Link)`
	text-decoration: none;
	a {
		color: #ce9f2c;
	}
	a:hover {
		color: #ffca00;
	}
	color: #ce9f2c;
	font-weight: bold;
	font-family: Verdana, sans-serif;
`;
const GET_COMPANY_DETAILS = gql`
  query GetCompanyDetails($domain: String!) {
    get_company_details(domain: $domain) {
      name
      domain
      grade
      grade_url
      score
      industry
      size
      uuid
      last30day_score_change
      is_custom
      is_entity
      is_un_published
      created_at
      disputed
      description
    }
  }
`;
const GET_COMPANY_SCORE_FACTOR = gql`
  query GetCompanyDetails($domain: String!) {
    get_company_factors(domain: $domain) {
		total
		entries{
		name
		score
		grade
		grade_url
		issue_summary
		}
    }
  }
`;
const CompanyProfile = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const { error, data } = useQuery(GET_COMPANY_DETAILS, {
		variables: { domain: "okta.com" },
	});
	const { data: factors } = useQuery(GET_COMPANY_SCORE_FACTOR, {
		variables: { domain: "okta.com" },
	});
	console.log(factors, "detailData")
	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const [showReports, setShowReports] = useState(false);
	const toggleReports = () => setShowReports(!showReports);
	// const [data, setData] = useState([]);
	const [datas, setDatas] = useState([]);
	const [octa, setOcta] = useState([]);
	const dispatch = useDispatch();
	const processId = useSelector((state) => state.pid);
	const vendor = useSelector((state) => state.vendor);
	let type = JSON.parse(localStorage.getItem("cyber-minds"));
	let clientID = type?.user?.client;
	const domain = useSelector((state) => state.domain);
	const [vendorProfile, setVendorProfile] = useState([]);
	const [report, setReport] = useState([]);
	const [score, setScore] = useState();
	const [loading, setLoading] = useState(false);
	const [showHideMe, setSetShowHideMe] = useState(false);
	const handleModalShowHideMe = () => setSetShowHideMe(!showHideMe);
	const [showProposal, setShowProposal] = useState(false);
	const toggleProposal = () => setShowProposal(!showProposal);
	const [suggestion, setSuggestion] = useState([]);
	const [selectedScore, setSelectedScore] = useState(90);
	// Declare an empty array 'options' to store the options that will be displayed in the dropdown
	const options = [];

	// Declare a variable 'start' which will be used to determine the starting point of the options in the dropdown
	let start = score;

	// Check if the score prop is less than 50, if so, set the start variable to 50
	if (score < 50) {
		start = 50;
	} else {
		// If the score prop is greater than or equal to 50, calculate the next range of the score
		start = Math.floor(score / 10) * 10 + 10;

		// If the score is 73, then the code will execute as follows:
		// The start variable will be set to (Math.floor(73 / 10) * 10) + 10 which is equal to 80.
		// Then the for loop will iterate from start which is 80, to 90 with a gap of 10 between each number.
		// The options array will contain the following options: 80, 90
		// So the dropdown will have 2 options: 80 and 90
		// So, if the score is 73 then the values in the dropdown will be 80 and 90
	}

	// Iterate from 'start' to 90, with an increment of 10 in each iteration
	for (let i = start; i <= 90; i += 10) {
		// Push the current iteration value to the options array
		options.push(i);
	}

	// useEffect(() => {
	// 	http
	// 		.get(
	// 			`${baseURL}/excel-upload/assets?clientId=${clientID}&processId=${processId}`
	// 		)
	// 		.then((response) => {
	// 			setDatas(response.data);
	// 		})
	// 		.then(
	// 			(response) => {},
	// 			(err) => {
	// 				console.log("No Data To Show");
	// 			}
	// 		)
	// 		.catch((err) => {
	// 			return false;
	// 		});
	// 	http
	// 		.get(
	// 			`${baseURL}/business_process/security-score-card-detail-report/okta.com/90`
	// 			// `${baseURL}/business_process/security-score-card-detail-report/${domain}/${selectedScore}`
	// 		)
	// 		.then((response) => {
	// 			setSuggestion(response.data);
	// 		})
	// 		.then(
	// 			(response) => {},
	// 			(err) => {
	// 				console.log("No Data To Show");
	// 			}
	// 		)
	// 		.catch((err) => {
	// 			return false;
	// 		});

	// 	http
	// 		// .get(`${baseURL}/business_process/security-score-card-results/${domain}`)
	// 		.get(`${baseURL}/business_process/security-score-card-results/okta.com`)
	// 		.then((response) => {
	// 			setVendorProfile(response.data);
	// 			setScore(response?.data?.score_record?.score);
	// 			setLoading(true);
	// 		})
	// 		.then(
	// 			(response) => {},
	// 			(err) => {
	// 				console.log("No Data To Show");
	// 			}
	// 		)
	// 		.catch((err) => {
	// 			return false;
	// 		});
	// 	http
	// 		.get(
	// 			`${baseURL}/business_process/security-score-card-plan-by-target/${`okta.com`}/${90}`
	// 			// `${baseURL}/business_process/security-score-card-plan-by-target/${domain}/${selectedScore}`
	// 		)
	// 		.then((response) => {
	// 			setReport(response.data);
	// 		})
	// 		.then(
	// 			(response) => {},
	// 			(err) => {
	// 				console.log(err);
	// 			}
	// 		)
	// 		.catch((err) => {
	// 			return false;
	// 		});
	// }, []);
	const {
		data: biaClient,
		loading: biaLoading,
		refetch: fetchData,
	} = useQuery(GET_CLIENT_BIA, {
		variables: { limit: 10 },
	});
	console.log(biaClient, "bia client");
	const DisplayData = biaClient?.client_asset
		?.filter((vendors) => vendors?.vendor === vendor)
		.map((risk) => {
			return (
				<ul className="space-y-2">
					<li
						onClick={() => {
							dispatch(cpeValue(risk?.cpe));
							dispatch(vendors(risk?.name));
						}}
						key={risk.cpe}
						className=" p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300"
					>
						<Link
							className="text-gray-500 dark:text-gray-400 font-semibold "
							to="/vendor_details"
						>
							{risk.name}
						</Link>
					</li>
				</ul>
			);
		});
	const DisplayDatas = factors?.get_company_factors?.entries?.map((items) => {
		return (
			<>
				<div className="p-2 w-full flex flex-wrap shadow rounded-xl dark:border-gray-700 transition-all duration-300 hover:shadow-xl">
					<div className="">
						<div className="p-4 flex space-x-4 items-center">
							<img className="w-10 h-10" src={items.grade_url} />
							<div className="text-xl font-semibold text-gray-500 dark:text-gray-400">
								{items.score}
							</div>
							<div className="text-lg  text-gray-500 dark:text-gray-400">
								{items.name}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	});
	return (
		<div>
			<div className="">
				<Navs to="/c/analysis/business-impact-analysis">
					<div className="flex space-x-2">
						<div>
							<ArrowBackIcon />
						</div>{" "}
						<div>Back</div>
					</div>
				</Navs>
			</div>
			{true ? (
				<div className="p-6 space-y-6">
					{/* Top Section */}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
						<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 flex items-center justify-center space-x-6 transition-all duration-300 hover:shadow-xl">
							<div className="w-24 h-24">
								<img
									alt="img"
									className="h-28 w-28 fill-blue-500"
									src={data?.get_company_details?.grade_url}
								/>
							</div>
							<div className="flex flex-col items-start">
								<h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
									{vendor}'s Score
								</h2>
								<p className=" text-gray-500 dark:text-gray-400">
									Grade:{" "}
									<span className="text-xl font-semibold">
										{data?.get_company_details?.score}
									</span>
								</p>
							</div>
						</div>

						{/*Products Section with Dropdown for Manage */}
						<div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
							<h2 className="text-xl font-bold text-gray-800 dark:text-gray-100 mb-4">
								{vendor}'s Products
							</h2>

							<div className="max-h-48 overflow-y-auto scrollbar-hide">
								{DisplayData}
							</div>

							{/* Dropdown Icon for Manage */}
							<div className="absolute top-4 right-4">
								<button
									onClick={toggleDropdown}
									className="text-gray-500 hover:text-gray-800 dark:hover:text-gray-300"
								>
									<FiMoreVertical size={24} />
								</button>

								{/* Dropdown Menu */}
								{dropdownOpen && (
									<div
										onMouseLeave={toggleDropdown}
										className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-lg shadow-lg py-2"
									>
										<button
											disabled
											className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-600"
										>
											Contact Company
										</button>
										<button
											onClick={() => toggleReports()}
											className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-600"
										>
											Generate Report
										</button>
										<button
											onClick={() => toggleProposal()}
											className="block w-full text-left px-4 py-2 text-gray-700 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-600"
										>
											Propose Plan
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						{DisplayDatas}
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						<div className="p-6 flex items-center ">
							<span className="">Powered by</span>
							<img
								className="h-14 w-20"
								alt="scorebord-logo"
								src="https://mms.businesswire.com/media/20221024005280/en/1610776/23/SSC_Logo_%281%29_%281%29_%281%29.jpg"
							/>
						</div>
					</div>
				</div>
			) : null}
			<Modal show={showHideMe} size="xl">
				<Modal.Header onClick={() => handleModalShowHideMe()}>
					<Modal.Title>{vendor}'s Reports</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<CompanyReports selectedScores={selectedScore} />
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn-cancel"
						onClick={() => handleModalShowHideMe()}
					>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showReports} size="xl">
				<Modal.Header onClick={() => toggleReports()}>
					<Modal.Title>{vendor}'s Reports</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<CompanyGeneratedReport vendorProfile={vendorProfile} />
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn-cancel" onClick={() => toggleReports()}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showProposal} size="md">
				<Modal.Header onClick={() => toggleProposal()}>
					<Modal.Title>Propose Score Plan</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="flex flex-col justify-center items-center space-y-3">
						<p className="color_octa text-xl">
							Propose a plan to improve {vendor}'s score
						</p>

						<div className="flex space-x-10 bg ">
							<div className=" h-full w-full text-white text-xl   p-2 flex justify-between items-center space-x-2">
								<div className="flex space-x-2 items-center">
									<img
										className="h-16 w-16 fill-blue-500"
										alt="grade-url"
										src={vendorProfile?.score_record?.grade_url}
									/>
									<p className="color_octa text-3xl">
										{vendorProfile?.score_record?.score}
									</p>
								</div>
								<p>to</p>
								<div className="flex space-x-2 items-center">
									<img
										className="h-16 w-16 fill-blue-500"
										alt="grade-url"
										src={
											selectedScore > 89 && selectedScore <= 100
												? "https://s3.amazonaws.com/ssc-static/grades/factor_a.svg"
												: selectedScore > 79 && selectedScore <= 89
													? "https://s3.amazonaws.com/ssc-static/grades/factor_b.svg"
													: selectedScore > 69 && selectedScore <= 79
														? "https://s3.amazonaws.com/ssc-static/grades/factor_c.svg"
														: selectedScore > 59 && selectedScore <= 69
															? "https://s3.amazonaws.com/ssc-static/grades/factor_d.svg"
															: "https://s3.amazonaws.com/ssc-static/grades/factor_f.svg"
										}
									/>
									<p className="color_octa text-3xl">{selectedScore}</p>
								</div>
							</div>
							<div className="flex flex-col space-y-2 justify-center items-center">
								<select
									value={selectedScore}
									onChange={(e) => {
										setSelectedScore(e.target.value);
									}}
									id="countries"
									class="bg-gray-50 border p-2.5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								>
									{options.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>
							</div>
						</div>
						<p
							onClick={() => {
								toggleProposal();
								handleModalShowHideMe();
							}}
							className="btn-confirm py-1 px-3 text-center cursor-pointer w-48"
						>
							Generate Plan
						</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn-cancel" onClick={() => toggleProposal()}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default CompanyProfile;
