import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import MultiSelect from "../../../shared/MultiSelect";
import swal from "sweetalert";
import { GET_CONTROLS } from "../../../graphql/superAdmin/query/query";
import { ADD_NIST, UPDATE_NIST } from "../../../graphql/superAdmin/mutation/mutation";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";

// Reusable Dropdown Component
const Dropdown = ({ label, value, onChange, options, error, required }) => (
	<Form.Group>
		<Form.Label>
			{label} {required && <span style={{ color: "red" }}>*</span>}
		</Form.Label>
		<select className="form-control" value={value} onChange={onChange}>
			<option value="">Select {label.toLowerCase()}...</option>
			{options?.map((item) => (
				<option key={item.id} value={item.id}>
					{item.name || item.label}
				</option>
			))}
		</select>
		{error && <span style={{ color: "red" }}>{error}</span>}
	</Form.Group>
);

// Reusable MultiSelect Component
const MultiSelectCheckbox = ({ label, value, onChange, options, error, required }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleCheckboxChange = (optionId) => {
		const updatedValue = value.includes(optionId)
			? value.filter((id) => id !== optionId) // Uncheck
			: [...value, optionId]; // Check
		onChange(updatedValue);
	};

	return (
		<Form.Group>
			<Form.Label>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<div className="multi-select-dropdown">
				<div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
					{value.length > 0
						? `${value.length} selected`
						: "Select related controls..."}
					<span className="arrow">{isOpen ? "▲" : "▼"}</span>
				</div>
				{isOpen && (
					<div className="dropdown-options">
						{options?.map((item) => (
							<div key={item.id} className="dropdown-option">
								<label>
									<input
										type="checkbox"
										checked={value.includes(item.name)}
										onChange={() => handleCheckboxChange(item.name)}
									/>
									{item.name || item.label}
								</label>
							</div>
						))}
					</div>
				)}
			</div>
			{error && <span style={{ color: "red" }}>{error}</span>}
		</Form.Group>
	);
};

const 
ModalHandler = ({
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	fetchData,
}) => {
	const [formData, setFormData] = useState({
		control_identifier: "",
		control_name_pk: "",
		discussion: "",
		related_controls: [],
	});

	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Fetch controls using GraphQL query
	const { data: controlsData, loading: controlsLoading } = useQuery(GET_CONTROLS, {
		variables: { limit: 100, offset: 0 },
	});
	const parsedControls = selectedItem?.related_controls ? JSON.parse(selectedItem?.related_controls) : [];
	const [selectedControls, setSelectedControls] = useState(parsedControls);

	const options = controlsData?.control?.map((items) => items?.name);

	const handleChangeTest = (selectedNames) => {
		setSelectedControls(selectedNames);
		console.log("Selected Names:", selectedNames);
	};

	// GraphQL Mutations
	const [addControlData] = useMutation(ADD_NIST);
	const [updateControlData] = useMutation(UPDATE_NIST);

	const handleChange = (key, value) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
		if (errors[key]) setErrors((prev) => ({ ...prev, [key]: "" }));
	};

	const handleSubmit = () => {
		if (isSubmitting) return; // Prevent re-submission

		const newErrors = {
			control_identifier: !formData.control_identifier ? "This field is required." : "",
			control_name_pk: !formData.control_name_pk ? "This field is required." : "",
			// related_controls: formData.related_controls.length === 0 ? "This field is required." : "",
		};

		setErrors(newErrors);

		if (Object.values(newErrors).every((error) => !error)) {
			setIsSubmitting(true); // Disable further submissions

			const inputs = {
				created_at: new Date().toISOString(),
				control_identifier: formData.control_identifier,
				control_name_pk: formData.control_name_pk,
				discussion: formData.discussion,
				related_controls: JSON.stringify(selectedControls),
			};

			if (mode === "add") {
				addControlData({ variables: { inputs } })
					.then(() => {
						setIsSubmitting(false); // Re-enable submissions
						onClose();
						swal("Success!", "Control data has been added successfully!", "success");
						fetchData();
						// window.location.reload();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage =
							err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
						swal("Error", errorMessage, "error");
						console.error("GraphQL Error:", err);
					});
			} else {
				updateControlData({ variables: { id: parseInt(selectedItemId), input: inputs } })
					.then(() => {
						setIsSubmitting(false);
						onClose();
						swal("Success!", "Control data has been updated successfully!", "success");
						fetchData();
					})
					.catch((err) => {
						setIsSubmitting(false);
						const errorMessage =
							err?.graphQLErrors?.[0]?.message || "Something went wrong, please try again.";
						swal("Error", errorMessage, "error");
						console.error("GraphQL Error:", err);
					});
			}
		}
	};

	useEffect(() => {
		if (selectedItem) {
			setFormData({
				control_identifier: selectedItem.control_identifier || "",
				control_name_pk: selectedItem.control_name_pk || "",
				discussion: selectedItem.discussion || "",
				related_controls: selectedItem.related_controls || [],
			});
		} else {
			setFormData({
				control_identifier: "",
				control_name_pk: "",
				discussion: "",
				related_controls: [],
			});
		}
	}, [selectedItem]);


	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update ${header}` : `Add New ${header}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-two-columns">
					<Form>
						<Dropdown
							label="Control Name"
							value={formData.control_name_pk}
							onChange={(e) => handleChange("control_name_pk", e.target.value)}
							options={controlsData?.control}
							error={errors.control_name_pk}
							required
						/>
						<Form.Group>
							<Form.Label>Control Identifier</Form.Label>
							<input
								className="form-control"
								type="text"
								placeholder="Enter control identifier..."
								value={formData.control_identifier}
								onChange={(e) => handleChange("control_identifier", e.target.value)}
							/>
							{errors.control_identifier && <span style={{ color: "red" }}>{errors.control_identifier}</span>}
						</Form.Group>
						<MultiSelect
							options={options}
							value={selectedControls}
							onChange={handleChangeTest}
							disabled={false} // Set to true to disable the dropdown
						/>
						<Form.Group>
							<Form.Label>Discussion</Form.Label>
							<textarea
								className="form-control"
								placeholder="Enter discussion..."
								value={formData.discussion}
								onChange={(e) => handleChange("discussion", e.target.value)}
							/>
							{errors.discussion && <span style={{ color: "red" }}>{errors.discussion}</span>}
						</Form.Group>
					</Form>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn-cancel" onClick={onClose}>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;